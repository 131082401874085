// Global footer styling

.footer-text {
  @extend %small-text;
}

.social-media-icn {
  border-radius: ($radius-s);
  height: $spacing-l;
  margin-right: $spacing-xs;
  width: $spacing-l;
}

.social-media-icn svg {
  height: $spacing-ml + $spacing-xs;
  width: $spacing-ml + $spacing-xs;
}

// Top footer section styling

.top-footer-nav-heading {
  font-weight: $font-weight-bold;
  margin-bottom: $spacing-ml;
}

.top-footer-nav-item {
  margin-bottom: $spacing-m - $spacing-xs;


  &:last-child {
    margin-bottom: -$spacing-xs;
  }
}

.top-footer-nav-link {
  @include link-variant($underline-color: transparent, $visited: $digital-blue, $font-weight: 600);
}

.social-media-group {
  margin: 0;
  margin-top: $spacing-xl;
  padding: 0;

  .social-icons-holder {
    flex-wrap: wrap;
  }
}


.social-media-link,
.social-media-group .top-footer-nav-link {
  background-image: none;
  line-height: 1;
  margin-right: $spacing-s;
  padding-bottom: 0;

  &:link, &:visited {
    text-decoration: none;
  }
}

.social-media-link:hover {
  background-image: none;
}

.social-media-links-header {
  color: $digital-black;
  font-size: 0.875rem;
  margin: 0;
  margin-bottom: $spacing-m;
}

.top-footer-row, .bottom-footer-row {
  padding-bottom: $spacing-xl;
  padding-top: $spacing-xl;

  @include media-breakpoint-up(sm) {
    padding-left: $spacing-ml;
    padding-right: $spacing-ml;
  }

  @include media-breakpoint-up(lg) {
    padding: $spacing-xxl 0
  }
}

.top-footer-column {
  margin-bottom: $spacing-xl;

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }
}

// Bottom footer section styling

.bottom-footer-nav-item {
  margin-bottom: $spacing-m - $spacing-xs;

  &:last-child {
    margin-bottom: -$spacing-xs;
  }

  a {
    background-image: none;
  }
}

.bottom-footer {
  hr {
    border-color: $gray-60;
  }

  p a,
  &-nav-link,
  &-external-link {
    @include link-variant($text-color: $gray-05, $underline-color:  $white, $hover-color: $white, $hover-underline-color: $white, $visited: $gray-05, $font-weight: 600);

    &:focus {
      background-color: transparent;
      background-image: linear-gradient($white, $white);
      color: $white;
    }
  }

  .bottom-navigation {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    .bottom-nav-link {
      color: $white;
      padding: 0 $spacing-s;

      @include media-breakpoint-up(md) {
        padding: 0 $spacing-m;
      }
    }

    li:not(:last-child) {
      /* stylelint-disable-next-line */
      .bottom-nav-link {
        border-right: 1px solid $white;
      }
    }
  }

  .bottom-nav-container {
    @include media-breakpoint-up(md) {
      align-items: center;
      display: flex;

      .bottom-navigation {
        margin-bottom: 0;
      }
    }

    /* stylelint-disable-next-line */
    .logo svg {
      height: 40px;
      width: 120px;
    }
  }
}
