//
// Link Lists
//

.link-lists {
  @include list-unstyled;

  li {
    margin-bottom: $spacing-m;

    &::before {
      content: none;
    }
  }

  a,
  .btn-link,
  a:not([href])[data-toggle='modal'] {
    @include reset-link-text;

    &:visited {
      color: $link-color;
    }

    &:hover {
      background-image: linear-gradient($cigna-blue-dark, $cigna-blue-dark);
      background-repeat: repeat-x;
      background-size: 1px 1px;
      color: $cigna-blue-dark;
      transition: color 0.2s ease-in;
    }
  }

  a,
  a:not([href])[data-toggle='modal'] {
    background-position: 0% 88%;
  }

  .btn-link {
    background-position: 0% 100%;
    font-family: inherit;
    font-weight: $link-font-weight;
    line-height: inherit;
    padding-bottom: 0;
  }
}
