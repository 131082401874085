// =============================================================================
// Accordions
// =============================================================================

@mixin accordion-title-type($background, $b-radius, $font-color, $margin-b, $padding) {
  background: $background;
  border-radius: $b-radius;
  color: $font-color;
  cursor: pointer;
  flex: 0 0 100%;
  margin-bottom: $margin-b;
  max-width: 100%;
  padding: $padding;
}
