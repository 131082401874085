@charset "UTF-8";
/* stylelint-disable */
/* stylelint-enable */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #0170bc;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --digital-black: #333;
  --gray-60: #505050;
  --gray-40: #939496;
  --gray-15: #e4e4e4;
  --gray-05: #e4e4e4;
  --gray-01: #f8f8f8;
  --white: #fff;
  --digital-blue: #0170bc;
  --digital-blue-10: #e7f1f8;
  --cigna-blue-dark: #004986;
  --cigna-blue-light: #00a9e0;
  --digital-green: #118503;
  --digital-green-10: #e7f2e5;
  --cigna-orange-logo: #f68621;
  --cigna-orange-dark: #e35205;
  --cigna-orange-light-20: #fcf0d6;
  --digital-red: #c00;
  --digital-red-10: #ffe3e2;
  --digital-purple: #800080;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #e4e4e4;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #e4e4e4;
  }
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1.5rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #0170bc;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #004986;
  text-decoration: none;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "HCo Gotham SSm", "Verdana", "Geneva", sans-serif;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 2px solid #e4e4e4;
}

small,
.small {
  font-size: 0.875rem;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875rem;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

.container {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 544px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 724px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 964px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1144px;
  }
}

.container-fluid, .container-xl, .container-lg, .container-md, .container-sm {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 544px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 724px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 964px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1144px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
  margin-left: -16px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #333;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e4e4e4;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e4e4e4;
}
.table tbody + tbody {
  border-top: 2px solid #e4e4e4;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #e4e4e4;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #e4e4e4;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #333;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8d7ec;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7bb5dc;
}

.table-hover .table-primary:hover {
  background-color: #a4cce7;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #a4cce7;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-digital-black,
.table-digital-black > th,
.table-digital-black > td {
  background-color: #c6c6c6;
}
.table-digital-black th,
.table-digital-black td,
.table-digital-black thead th,
.table-digital-black tbody + tbody {
  border-color: #959595;
}

.table-hover .table-digital-black:hover {
  background-color: #b9b9b9;
}
.table-hover .table-digital-black:hover > td,
.table-hover .table-digital-black:hover > th {
  background-color: #b9b9b9;
}

.table-gray-60,
.table-gray-60 > th,
.table-gray-60 > td {
  background-color: #cecece;
}
.table-gray-60 th,
.table-gray-60 td,
.table-gray-60 thead th,
.table-gray-60 tbody + tbody {
  border-color: #a4a4a4;
}

.table-hover .table-gray-60:hover {
  background-color: #c1c1c1;
}
.table-hover .table-gray-60:hover > td,
.table-hover .table-gray-60:hover > th {
  background-color: #c1c1c1;
}

.table-gray-40,
.table-gray-40 > th,
.table-gray-40 > td {
  background-color: #e1e1e2;
}
.table-gray-40 th,
.table-gray-40 td,
.table-gray-40 thead th,
.table-gray-40 tbody + tbody {
  border-color: #c7c7c8;
}

.table-hover .table-gray-40:hover {
  background-color: #d4d4d5;
}
.table-hover .table-gray-40:hover > td,
.table-hover .table-gray-40:hover > th {
  background-color: #d4d4d5;
}

.table-gray-15,
.table-gray-15 > th,
.table-gray-15 > td {
  background-color: #f7f7f7;
}
.table-gray-15 th,
.table-gray-15 td,
.table-gray-15 thead th,
.table-gray-15 tbody + tbody {
  border-color: #f1f1f1;
}

.table-hover .table-gray-15:hover {
  background-color: #eaeaea;
}
.table-hover .table-gray-15:hover > td,
.table-hover .table-gray-15:hover > th {
  background-color: #eaeaea;
}

.table-gray-05,
.table-gray-05 > th,
.table-gray-05 > td {
  background-color: #f7f7f7;
}
.table-gray-05 th,
.table-gray-05 td,
.table-gray-05 thead th,
.table-gray-05 tbody + tbody {
  border-color: #f1f1f1;
}

.table-hover .table-gray-05:hover {
  background-color: #eaeaea;
}
.table-hover .table-gray-05:hover > td,
.table-hover .table-gray-05:hover > th {
  background-color: #eaeaea;
}

.table-gray-01,
.table-gray-01 > th,
.table-gray-01 > td {
  background-color: #fdfdfd;
}
.table-gray-01 th,
.table-gray-01 td,
.table-gray-01 thead th,
.table-gray-01 tbody + tbody {
  border-color: #fbfbfb;
}

.table-hover .table-gray-01:hover {
  background-color: #f0f0f0;
}
.table-hover .table-gray-01:hover > td,
.table-hover .table-gray-01:hover > th {
  background-color: #f0f0f0;
}

.table-white,
.table-white > th,
.table-white > td {
  background-color: white;
}
.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody + tbody {
  border-color: white;
}

.table-hover .table-white:hover {
  background-color: #f2f2f2;
}
.table-hover .table-white:hover > td,
.table-hover .table-white:hover > th {
  background-color: #f2f2f2;
}

.table-digital-blue,
.table-digital-blue > th,
.table-digital-blue > td {
  background-color: #b8d7ec;
}
.table-digital-blue th,
.table-digital-blue td,
.table-digital-blue thead th,
.table-digital-blue tbody + tbody {
  border-color: #7bb5dc;
}

.table-hover .table-digital-blue:hover {
  background-color: #a4cce7;
}
.table-hover .table-digital-blue:hover > td,
.table-hover .table-digital-blue:hover > th {
  background-color: #a4cce7;
}

.table-digital-blue-10,
.table-digital-blue-10 > th,
.table-digital-blue-10 > td {
  background-color: #f8fbfd;
}
.table-digital-blue-10 th,
.table-digital-blue-10 td,
.table-digital-blue-10 thead th,
.table-digital-blue-10 tbody + tbody {
  border-color: #f3f8fb;
}

.table-hover .table-digital-blue-10:hover {
  background-color: #e4f0f7;
}
.table-hover .table-digital-blue-10:hover > td,
.table-hover .table-digital-blue-10:hover > th {
  background-color: #e4f0f7;
}

.table-cigna-blue-dark,
.table-cigna-blue-dark > th,
.table-cigna-blue-dark > td {
  background-color: #b8ccdd;
}
.table-cigna-blue-dark th,
.table-cigna-blue-dark td,
.table-cigna-blue-dark thead th,
.table-cigna-blue-dark tbody + tbody {
  border-color: #7aa0c0;
}

.table-hover .table-cigna-blue-dark:hover {
  background-color: #a7c0d5;
}
.table-hover .table-cigna-blue-dark:hover > td,
.table-hover .table-cigna-blue-dark:hover > th {
  background-color: #a7c0d5;
}

.table-cigna-blue-light,
.table-cigna-blue-light > th,
.table-cigna-blue-light > td {
  background-color: #b8e7f6;
}
.table-cigna-blue-light th,
.table-cigna-blue-light td,
.table-cigna-blue-light thead th,
.table-cigna-blue-light tbody + tbody {
  border-color: #7ad2ef;
}

.table-hover .table-cigna-blue-light:hover {
  background-color: #a1dff3;
}
.table-hover .table-cigna-blue-light:hover > td,
.table-hover .table-cigna-blue-light:hover > th {
  background-color: #a1dff3;
}

.table-digital-green,
.table-digital-green > th,
.table-digital-green > td {
  background-color: #bcddb8;
}
.table-digital-green th,
.table-digital-green td,
.table-digital-green thead th,
.table-digital-green tbody + tbody {
  border-color: #83c07c;
}

.table-hover .table-digital-green:hover {
  background-color: #acd5a7;
}
.table-hover .table-digital-green:hover > td,
.table-hover .table-digital-green:hover > th {
  background-color: #acd5a7;
}

.table-digital-green-10,
.table-digital-green-10 > th,
.table-digital-green-10 > td {
  background-color: #f8fbf8;
}
.table-digital-green-10 th,
.table-digital-green-10 td,
.table-digital-green-10 thead th,
.table-digital-green-10 tbody + tbody {
  border-color: #f3f8f1;
}

.table-hover .table-digital-green-10:hover {
  background-color: #e8f2e8;
}
.table-hover .table-digital-green-10:hover > td,
.table-hover .table-digital-green-10:hover > th {
  background-color: #e8f2e8;
}

.table-cigna-orange-logo,
.table-cigna-orange-logo > th,
.table-cigna-orange-logo > td {
  background-color: #fcddc1;
}
.table-cigna-orange-logo th,
.table-cigna-orange-logo td,
.table-cigna-orange-logo thead th,
.table-cigna-orange-logo tbody + tbody {
  border-color: #fac08c;
}

.table-hover .table-cigna-orange-logo:hover {
  background-color: #fbd0a9;
}
.table-hover .table-cigna-orange-logo:hover > td,
.table-hover .table-cigna-orange-logo:hover > th {
  background-color: #fbd0a9;
}

.table-cigna-orange-dark,
.table-cigna-orange-dark > th,
.table-cigna-orange-dark > td {
  background-color: #f7cfb9;
}
.table-cigna-orange-dark th,
.table-cigna-orange-dark td,
.table-cigna-orange-dark thead th,
.table-cigna-orange-dark tbody + tbody {
  border-color: #f0a57d;
}

.table-hover .table-cigna-orange-dark:hover {
  background-color: #f4bfa2;
}
.table-hover .table-cigna-orange-dark:hover > td,
.table-hover .table-cigna-orange-dark:hover > th {
  background-color: #f4bfa2;
}

.table-cigna-orange-light-20,
.table-cigna-orange-light-20 > th,
.table-cigna-orange-light-20 > td {
  background-color: #fefbf4;
}
.table-cigna-orange-light-20 th,
.table-cigna-orange-light-20 td,
.table-cigna-orange-light-20 thead th,
.table-cigna-orange-light-20 tbody + tbody {
  border-color: #fdf7ea;
}

.table-hover .table-cigna-orange-light-20:hover {
  background-color: #fcf3dd;
}
.table-hover .table-cigna-orange-light-20:hover > td,
.table-hover .table-cigna-orange-light-20:hover > th {
  background-color: #fcf3dd;
}

.table-digital-red,
.table-digital-red > th,
.table-digital-red > td {
  background-color: #f1b8b8;
}
.table-digital-red th,
.table-digital-red td,
.table-digital-red thead th,
.table-digital-red tbody + tbody {
  border-color: #e47a7a;
}

.table-hover .table-digital-red:hover {
  background-color: #eda3a3;
}
.table-hover .table-digital-red:hover > td,
.table-hover .table-digital-red:hover > th {
  background-color: #eda3a3;
}

.table-digital-red-10,
.table-digital-red-10 > th,
.table-digital-red-10 > td {
  background-color: #fff7f7;
}
.table-digital-red-10 th,
.table-digital-red-10 td,
.table-digital-red-10 thead th,
.table-digital-red-10 tbody + tbody {
  border-color: #fff0f0;
}

.table-hover .table-digital-red-10:hover {
  background-color: #ffdede;
}
.table-hover .table-digital-red-10:hover > td,
.table-hover .table-digital-red-10:hover > th {
  background-color: #ffdede;
}

.table-digital-purple,
.table-digital-purple > th,
.table-digital-purple > td {
  background-color: #dbb8db;
}
.table-digital-purple th,
.table-digital-purple td,
.table-digital-purple thead th,
.table-digital-purple tbody + tbody {
  border-color: #bd7abd;
}

.table-hover .table-digital-purple:hover {
  background-color: #d2a7d2;
}
.table-hover .table-digital-purple:hover > td,
.table-hover .table-digital-purple:hover > th {
  background-color: #d2a7d2;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #e4e4e4;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #3fb0fe;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(1, 112, 188, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #333;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #333;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(1, 112, 188, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #0170bc;
  border-color: #0170bc;
}
.btn-primary:hover {
  color: #fff;
  background-color: #015996;
  border-color: #015289;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #015996;
  border-color: #015289;
  box-shadow: 0 0 0 0.2rem rgba(39, 133, 198, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #0170bc;
  border-color: #0170bc;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #015289;
  border-color: #014a7d;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(39, 133, 198, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-digital-black {
  color: #fff;
  background-color: #333;
  border-color: #333;
}
.btn-digital-black:hover {
  color: #fff;
  background-color: #202020;
  border-color: #1a1a1a;
}
.btn-digital-black:focus, .btn-digital-black.focus {
  color: #fff;
  background-color: #202020;
  border-color: #1a1a1a;
  box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5);
}
.btn-digital-black.disabled, .btn-digital-black:disabled {
  color: #fff;
  background-color: #333;
  border-color: #333;
}
.btn-digital-black:not(:disabled):not(.disabled):active, .btn-digital-black:not(:disabled):not(.disabled).active, .show > .btn-digital-black.dropdown-toggle {
  color: #fff;
  background-color: #1a1a1a;
  border-color: #131313;
}
.btn-digital-black:not(:disabled):not(.disabled):active:focus, .btn-digital-black:not(:disabled):not(.disabled).active:focus, .show > .btn-digital-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5);
}

.btn-gray-60 {
  color: #fff;
  background-color: #505050;
  border-color: #505050;
}
.btn-gray-60:hover {
  color: #fff;
  background-color: #3d3d3d;
  border-color: #373737;
}
.btn-gray-60:focus, .btn-gray-60.focus {
  color: #fff;
  background-color: #3d3d3d;
  border-color: #373737;
  box-shadow: 0 0 0 0.2rem rgba(106, 106, 106, 0.5);
}
.btn-gray-60.disabled, .btn-gray-60:disabled {
  color: #fff;
  background-color: #505050;
  border-color: #505050;
}
.btn-gray-60:not(:disabled):not(.disabled):active, .btn-gray-60:not(:disabled):not(.disabled).active, .show > .btn-gray-60.dropdown-toggle {
  color: #fff;
  background-color: #373737;
  border-color: #303030;
}
.btn-gray-60:not(:disabled):not(.disabled):active:focus, .btn-gray-60:not(:disabled):not(.disabled).active:focus, .show > .btn-gray-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(106, 106, 106, 0.5);
}

.btn-gray-40 {
  color: #fff;
  background-color: #939496;
  border-color: #939496;
}
.btn-gray-40:hover {
  color: #fff;
  background-color: #808183;
  border-color: #797a7d;
}
.btn-gray-40:focus, .btn-gray-40.focus {
  color: #fff;
  background-color: #808183;
  border-color: #797a7d;
  box-shadow: 0 0 0 0.2rem rgba(163, 164, 166, 0.5);
}
.btn-gray-40.disabled, .btn-gray-40:disabled {
  color: #fff;
  background-color: #939496;
  border-color: #939496;
}
.btn-gray-40:not(:disabled):not(.disabled):active, .btn-gray-40:not(:disabled):not(.disabled).active, .show > .btn-gray-40.dropdown-toggle {
  color: #fff;
  background-color: #797a7d;
  border-color: #737476;
}
.btn-gray-40:not(:disabled):not(.disabled):active:focus, .btn-gray-40:not(:disabled):not(.disabled).active:focus, .show > .btn-gray-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(163, 164, 166, 0.5);
}

.btn-gray-15 {
  color: #212529;
  background-color: #e4e4e4;
  border-color: #e4e4e4;
}
.btn-gray-15:hover {
  color: #212529;
  background-color: #d1d1d1;
  border-color: #cbcbcb;
}
.btn-gray-15:focus, .btn-gray-15.focus {
  color: #212529;
  background-color: #d1d1d1;
  border-color: #cbcbcb;
  box-shadow: 0 0 0 0.2rem rgba(199, 199, 200, 0.5);
}
.btn-gray-15.disabled, .btn-gray-15:disabled {
  color: #212529;
  background-color: #e4e4e4;
  border-color: #e4e4e4;
}
.btn-gray-15:not(:disabled):not(.disabled):active, .btn-gray-15:not(:disabled):not(.disabled).active, .show > .btn-gray-15.dropdown-toggle {
  color: #212529;
  background-color: #cbcbcb;
  border-color: #c4c4c4;
}
.btn-gray-15:not(:disabled):not(.disabled):active:focus, .btn-gray-15:not(:disabled):not(.disabled).active:focus, .show > .btn-gray-15.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(199, 199, 200, 0.5);
}

.btn-gray-05 {
  color: #212529;
  background-color: #e4e4e4;
  border-color: #e4e4e4;
}
.btn-gray-05:hover {
  color: #212529;
  background-color: #d1d1d1;
  border-color: #cbcbcb;
}
.btn-gray-05:focus, .btn-gray-05.focus {
  color: #212529;
  background-color: #d1d1d1;
  border-color: #cbcbcb;
  box-shadow: 0 0 0 0.2rem rgba(199, 199, 200, 0.5);
}
.btn-gray-05.disabled, .btn-gray-05:disabled {
  color: #212529;
  background-color: #e4e4e4;
  border-color: #e4e4e4;
}
.btn-gray-05:not(:disabled):not(.disabled):active, .btn-gray-05:not(:disabled):not(.disabled).active, .show > .btn-gray-05.dropdown-toggle {
  color: #212529;
  background-color: #cbcbcb;
  border-color: #c4c4c4;
}
.btn-gray-05:not(:disabled):not(.disabled):active:focus, .btn-gray-05:not(:disabled):not(.disabled).active:focus, .show > .btn-gray-05.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(199, 199, 200, 0.5);
}

.btn-gray-01 {
  color: #212529;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
}
.btn-gray-01:hover {
  color: #212529;
  background-color: #e5e5e5;
  border-color: #dfdfdf;
}
.btn-gray-01:focus, .btn-gray-01.focus {
  color: #212529;
  background-color: #e5e5e5;
  border-color: #dfdfdf;
  box-shadow: 0 0 0 0.2rem rgba(216, 216, 217, 0.5);
}
.btn-gray-01.disabled, .btn-gray-01:disabled {
  color: #212529;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
}
.btn-gray-01:not(:disabled):not(.disabled):active, .btn-gray-01:not(:disabled):not(.disabled).active, .show > .btn-gray-01.dropdown-toggle {
  color: #212529;
  background-color: #dfdfdf;
  border-color: #d8d8d8;
}
.btn-gray-01:not(:disabled):not(.disabled):active:focus, .btn-gray-01:not(:disabled):not(.disabled).active:focus, .show > .btn-gray-01.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 216, 217, 0.5);
}

.btn-white {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-white:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.btn-white:focus, .btn-white.focus {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}
.btn-white.disabled, .btn-white:disabled {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active, .show > .btn-white.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus, .show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}

.btn-digital-blue {
  color: #fff;
  background-color: #0170bc;
  border-color: #0170bc;
}
.btn-digital-blue:hover {
  color: #fff;
  background-color: #015996;
  border-color: #015289;
}
.btn-digital-blue:focus, .btn-digital-blue.focus {
  color: #fff;
  background-color: #015996;
  border-color: #015289;
  box-shadow: 0 0 0 0.2rem rgba(39, 133, 198, 0.5);
}
.btn-digital-blue.disabled, .btn-digital-blue:disabled {
  color: #fff;
  background-color: #0170bc;
  border-color: #0170bc;
}
.btn-digital-blue:not(:disabled):not(.disabled):active, .btn-digital-blue:not(:disabled):not(.disabled).active, .show > .btn-digital-blue.dropdown-toggle {
  color: #fff;
  background-color: #015289;
  border-color: #014a7d;
}
.btn-digital-blue:not(:disabled):not(.disabled):active:focus, .btn-digital-blue:not(:disabled):not(.disabled).active:focus, .show > .btn-digital-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(39, 133, 198, 0.5);
}

.btn-digital-blue-10 {
  color: #212529;
  background-color: #e7f1f8;
  border-color: #e7f1f8;
}
.btn-digital-blue-10:hover {
  color: #212529;
  background-color: #c9e0ef;
  border-color: #c0daec;
}
.btn-digital-blue-10:focus, .btn-digital-blue-10.focus {
  color: #212529;
  background-color: #c9e0ef;
  border-color: #c0daec;
  box-shadow: 0 0 0 0.2rem rgba(201, 210, 217, 0.5);
}
.btn-digital-blue-10.disabled, .btn-digital-blue-10:disabled {
  color: #212529;
  background-color: #e7f1f8;
  border-color: #e7f1f8;
}
.btn-digital-blue-10:not(:disabled):not(.disabled):active, .btn-digital-blue-10:not(:disabled):not(.disabled).active, .show > .btn-digital-blue-10.dropdown-toggle {
  color: #212529;
  background-color: #c0daec;
  border-color: #b6d4ea;
}
.btn-digital-blue-10:not(:disabled):not(.disabled):active:focus, .btn-digital-blue-10:not(:disabled):not(.disabled).active:focus, .show > .btn-digital-blue-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(201, 210, 217, 0.5);
}

.btn-cigna-blue-dark {
  color: #fff;
  background-color: #004986;
  border-color: #004986;
}
.btn-cigna-blue-dark:hover {
  color: #fff;
  background-color: #003460;
  border-color: #002d53;
}
.btn-cigna-blue-dark:focus, .btn-cigna-blue-dark.focus {
  color: #fff;
  background-color: #003460;
  border-color: #002d53;
  box-shadow: 0 0 0 0.2rem rgba(38, 100, 152, 0.5);
}
.btn-cigna-blue-dark.disabled, .btn-cigna-blue-dark:disabled {
  color: #fff;
  background-color: #004986;
  border-color: #004986;
}
.btn-cigna-blue-dark:not(:disabled):not(.disabled):active, .btn-cigna-blue-dark:not(:disabled):not(.disabled).active, .show > .btn-cigna-blue-dark.dropdown-toggle {
  color: #fff;
  background-color: #002d53;
  border-color: #002646;
}
.btn-cigna-blue-dark:not(:disabled):not(.disabled):active:focus, .btn-cigna-blue-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-cigna-blue-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 100, 152, 0.5);
}

.btn-cigna-blue-light {
  color: #fff;
  background-color: #00a9e0;
  border-color: #00a9e0;
}
.btn-cigna-blue-light:hover {
  color: #fff;
  background-color: #008cba;
  border-color: #0083ad;
}
.btn-cigna-blue-light:focus, .btn-cigna-blue-light.focus {
  color: #fff;
  background-color: #008cba;
  border-color: #0083ad;
  box-shadow: 0 0 0 0.2rem rgba(38, 182, 229, 0.5);
}
.btn-cigna-blue-light.disabled, .btn-cigna-blue-light:disabled {
  color: #fff;
  background-color: #00a9e0;
  border-color: #00a9e0;
}
.btn-cigna-blue-light:not(:disabled):not(.disabled):active, .btn-cigna-blue-light:not(:disabled):not(.disabled).active, .show > .btn-cigna-blue-light.dropdown-toggle {
  color: #fff;
  background-color: #0083ad;
  border-color: #0079a0;
}
.btn-cigna-blue-light:not(:disabled):not(.disabled):active:focus, .btn-cigna-blue-light:not(:disabled):not(.disabled).active:focus, .show > .btn-cigna-blue-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 182, 229, 0.5);
}

.btn-digital-green {
  color: #fff;
  background-color: #118503;
  border-color: #118503;
}
.btn-digital-green:hover {
  color: #fff;
  background-color: #0c6002;
  border-color: #0b5302;
}
.btn-digital-green:focus, .btn-digital-green.focus {
  color: #fff;
  background-color: #0c6002;
  border-color: #0b5302;
  box-shadow: 0 0 0 0.2rem rgba(53, 151, 41, 0.5);
}
.btn-digital-green.disabled, .btn-digital-green:disabled {
  color: #fff;
  background-color: #118503;
  border-color: #118503;
}
.btn-digital-green:not(:disabled):not(.disabled):active, .btn-digital-green:not(:disabled):not(.disabled).active, .show > .btn-digital-green.dropdown-toggle {
  color: #fff;
  background-color: #0b5302;
  border-color: #094702;
}
.btn-digital-green:not(:disabled):not(.disabled):active:focus, .btn-digital-green:not(:disabled):not(.disabled).active:focus, .show > .btn-digital-green.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(53, 151, 41, 0.5);
}

.btn-digital-green-10 {
  color: #212529;
  background-color: #e7f2e5;
  border-color: #e7f2e5;
}
.btn-digital-green-10:hover {
  color: #212529;
  background-color: #cfe5cc;
  border-color: #c8e1c3;
}
.btn-digital-green-10:focus, .btn-digital-green-10.focus {
  color: #212529;
  background-color: #cfe5cc;
  border-color: #c8e1c3;
  box-shadow: 0 0 0 0.2rem rgba(201, 211, 201, 0.5);
}
.btn-digital-green-10.disabled, .btn-digital-green-10:disabled {
  color: #212529;
  background-color: #e7f2e5;
  border-color: #e7f2e5;
}
.btn-digital-green-10:not(:disabled):not(.disabled):active, .btn-digital-green-10:not(:disabled):not(.disabled).active, .show > .btn-digital-green-10.dropdown-toggle {
  color: #212529;
  background-color: #c8e1c3;
  border-color: #c0ddbb;
}
.btn-digital-green-10:not(:disabled):not(.disabled):active:focus, .btn-digital-green-10:not(:disabled):not(.disabled).active:focus, .show > .btn-digital-green-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(201, 211, 201, 0.5);
}

.btn-cigna-orange-logo {
  color: #212529;
  background-color: #f68621;
  border-color: #f68621;
}
.btn-cigna-orange-logo:hover {
  color: #fff;
  background-color: #e77309;
  border-color: #db6d09;
}
.btn-cigna-orange-logo:focus, .btn-cigna-orange-logo.focus {
  color: #fff;
  background-color: #e77309;
  border-color: #db6d09;
  box-shadow: 0 0 0 0.2rem rgba(214, 119, 34, 0.5);
}
.btn-cigna-orange-logo.disabled, .btn-cigna-orange-logo:disabled {
  color: #212529;
  background-color: #f68621;
  border-color: #f68621;
}
.btn-cigna-orange-logo:not(:disabled):not(.disabled):active, .btn-cigna-orange-logo:not(:disabled):not(.disabled).active, .show > .btn-cigna-orange-logo.dropdown-toggle {
  color: #fff;
  background-color: #db6d09;
  border-color: #cf6708;
}
.btn-cigna-orange-logo:not(:disabled):not(.disabled):active:focus, .btn-cigna-orange-logo:not(:disabled):not(.disabled).active:focus, .show > .btn-cigna-orange-logo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(214, 119, 34, 0.5);
}

.btn-cigna-orange-dark {
  color: #fff;
  background-color: #e35205;
  border-color: #e35205;
}
.btn-cigna-orange-dark:hover {
  color: #fff;
  background-color: #be4404;
  border-color: #b14004;
}
.btn-cigna-orange-dark:focus, .btn-cigna-orange-dark.focus {
  color: #fff;
  background-color: #be4404;
  border-color: #b14004;
  box-shadow: 0 0 0 0.2rem rgba(231, 108, 43, 0.5);
}
.btn-cigna-orange-dark.disabled, .btn-cigna-orange-dark:disabled {
  color: #fff;
  background-color: #e35205;
  border-color: #e35205;
}
.btn-cigna-orange-dark:not(:disabled):not(.disabled):active, .btn-cigna-orange-dark:not(:disabled):not(.disabled).active, .show > .btn-cigna-orange-dark.dropdown-toggle {
  color: #fff;
  background-color: #b14004;
  border-color: #a53b04;
}
.btn-cigna-orange-dark:not(:disabled):not(.disabled):active:focus, .btn-cigna-orange-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-cigna-orange-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(231, 108, 43, 0.5);
}

.btn-cigna-orange-light-20 {
  color: #212529;
  background-color: #fcf0d6;
  border-color: #fcf0d6;
}
.btn-cigna-orange-light-20:hover {
  color: #212529;
  background-color: #f9e3b2;
  border-color: #f9dfa6;
}
.btn-cigna-orange-light-20:focus, .btn-cigna-orange-light-20.focus {
  color: #212529;
  background-color: #f9e3b2;
  border-color: #f9dfa6;
  box-shadow: 0 0 0 0.2rem rgba(219, 210, 188, 0.5);
}
.btn-cigna-orange-light-20.disabled, .btn-cigna-orange-light-20:disabled {
  color: #212529;
  background-color: #fcf0d6;
  border-color: #fcf0d6;
}
.btn-cigna-orange-light-20:not(:disabled):not(.disabled):active, .btn-cigna-orange-light-20:not(:disabled):not(.disabled).active, .show > .btn-cigna-orange-light-20.dropdown-toggle {
  color: #212529;
  background-color: #f9dfa6;
  border-color: #f8da9b;
}
.btn-cigna-orange-light-20:not(:disabled):not(.disabled):active:focus, .btn-cigna-orange-light-20:not(:disabled):not(.disabled).active:focus, .show > .btn-cigna-orange-light-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(219, 210, 188, 0.5);
}

.btn-digital-red {
  color: #fff;
  background-color: #c00;
  border-color: #c00;
}
.btn-digital-red:hover {
  color: #fff;
  background-color: #a60000;
  border-color: #990000;
}
.btn-digital-red:focus, .btn-digital-red.focus {
  color: #fff;
  background-color: #a60000;
  border-color: #990000;
  box-shadow: 0 0 0 0.2rem rgba(212, 38, 38, 0.5);
}
.btn-digital-red.disabled, .btn-digital-red:disabled {
  color: #fff;
  background-color: #c00;
  border-color: #c00;
}
.btn-digital-red:not(:disabled):not(.disabled):active, .btn-digital-red:not(:disabled):not(.disabled).active, .show > .btn-digital-red.dropdown-toggle {
  color: #fff;
  background-color: #990000;
  border-color: #8c0000;
}
.btn-digital-red:not(:disabled):not(.disabled):active:focus, .btn-digital-red:not(:disabled):not(.disabled).active:focus, .show > .btn-digital-red.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(212, 38, 38, 0.5);
}

.btn-digital-red-10 {
  color: #212529;
  background-color: #ffe3e2;
  border-color: #ffe3e2;
}
.btn-digital-red-10:hover {
  color: #212529;
  background-color: #ffbebc;
  border-color: #ffb2af;
}
.btn-digital-red-10:focus, .btn-digital-red-10.focus {
  color: #212529;
  background-color: #ffbebc;
  border-color: #ffb2af;
  box-shadow: 0 0 0 0.2rem rgba(222, 199, 198, 0.5);
}
.btn-digital-red-10.disabled, .btn-digital-red-10:disabled {
  color: #212529;
  background-color: #ffe3e2;
  border-color: #ffe3e2;
}
.btn-digital-red-10:not(:disabled):not(.disabled):active, .btn-digital-red-10:not(:disabled):not(.disabled).active, .show > .btn-digital-red-10.dropdown-toggle {
  color: #212529;
  background-color: #ffb2af;
  border-color: #ffa5a2;
}
.btn-digital-red-10:not(:disabled):not(.disabled):active:focus, .btn-digital-red-10:not(:disabled):not(.disabled).active:focus, .show > .btn-digital-red-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 199, 198, 0.5);
}

.btn-digital-purple {
  color: #fff;
  background-color: #800080;
  border-color: #800080;
}
.btn-digital-purple:hover {
  color: #fff;
  background-color: #5a005a;
  border-color: #4d004d;
}
.btn-digital-purple:focus, .btn-digital-purple.focus {
  color: #fff;
  background-color: #5a005a;
  border-color: #4d004d;
  box-shadow: 0 0 0 0.2rem rgba(147, 38, 147, 0.5);
}
.btn-digital-purple.disabled, .btn-digital-purple:disabled {
  color: #fff;
  background-color: #800080;
  border-color: #800080;
}
.btn-digital-purple:not(:disabled):not(.disabled):active, .btn-digital-purple:not(:disabled):not(.disabled).active, .show > .btn-digital-purple.dropdown-toggle {
  color: #fff;
  background-color: #4d004d;
  border-color: #400040;
}
.btn-digital-purple:not(:disabled):not(.disabled):active:focus, .btn-digital-purple:not(:disabled):not(.disabled).active:focus, .show > .btn-digital-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(147, 38, 147, 0.5);
}

.btn-outline-primary {
  color: #0170bc;
  border-color: #0170bc;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #0170bc;
  border-color: #0170bc;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(1, 112, 188, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #0170bc;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #0170bc;
  border-color: #0170bc;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(1, 112, 188, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-digital-black {
  color: #333;
  border-color: #333;
}
.btn-outline-digital-black:hover {
  color: #fff;
  background-color: #333;
  border-color: #333;
}
.btn-outline-digital-black:focus, .btn-outline-digital-black.focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
}
.btn-outline-digital-black.disabled, .btn-outline-digital-black:disabled {
  color: #333;
  background-color: transparent;
}
.btn-outline-digital-black:not(:disabled):not(.disabled):active, .btn-outline-digital-black:not(:disabled):not(.disabled).active, .show > .btn-outline-digital-black.dropdown-toggle {
  color: #fff;
  background-color: #333;
  border-color: #333;
}
.btn-outline-digital-black:not(:disabled):not(.disabled):active:focus, .btn-outline-digital-black:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-digital-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
}

.btn-outline-gray-60 {
  color: #505050;
  border-color: #505050;
}
.btn-outline-gray-60:hover {
  color: #fff;
  background-color: #505050;
  border-color: #505050;
}
.btn-outline-gray-60:focus, .btn-outline-gray-60.focus {
  box-shadow: 0 0 0 0.2rem rgba(80, 80, 80, 0.5);
}
.btn-outline-gray-60.disabled, .btn-outline-gray-60:disabled {
  color: #505050;
  background-color: transparent;
}
.btn-outline-gray-60:not(:disabled):not(.disabled):active, .btn-outline-gray-60:not(:disabled):not(.disabled).active, .show > .btn-outline-gray-60.dropdown-toggle {
  color: #fff;
  background-color: #505050;
  border-color: #505050;
}
.btn-outline-gray-60:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-60:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-gray-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(80, 80, 80, 0.5);
}

.btn-outline-gray-40 {
  color: #939496;
  border-color: #939496;
}
.btn-outline-gray-40:hover {
  color: #fff;
  background-color: #939496;
  border-color: #939496;
}
.btn-outline-gray-40:focus, .btn-outline-gray-40.focus {
  box-shadow: 0 0 0 0.2rem rgba(147, 148, 150, 0.5);
}
.btn-outline-gray-40.disabled, .btn-outline-gray-40:disabled {
  color: #939496;
  background-color: transparent;
}
.btn-outline-gray-40:not(:disabled):not(.disabled):active, .btn-outline-gray-40:not(:disabled):not(.disabled).active, .show > .btn-outline-gray-40.dropdown-toggle {
  color: #fff;
  background-color: #939496;
  border-color: #939496;
}
.btn-outline-gray-40:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-40:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-gray-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(147, 148, 150, 0.5);
}

.btn-outline-gray-15 {
  color: #e4e4e4;
  border-color: #e4e4e4;
}
.btn-outline-gray-15:hover {
  color: #212529;
  background-color: #e4e4e4;
  border-color: #e4e4e4;
}
.btn-outline-gray-15:focus, .btn-outline-gray-15.focus {
  box-shadow: 0 0 0 0.2rem rgba(228, 228, 228, 0.5);
}
.btn-outline-gray-15.disabled, .btn-outline-gray-15:disabled {
  color: #e4e4e4;
  background-color: transparent;
}
.btn-outline-gray-15:not(:disabled):not(.disabled):active, .btn-outline-gray-15:not(:disabled):not(.disabled).active, .show > .btn-outline-gray-15.dropdown-toggle {
  color: #212529;
  background-color: #e4e4e4;
  border-color: #e4e4e4;
}
.btn-outline-gray-15:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-15:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-gray-15.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(228, 228, 228, 0.5);
}

.btn-outline-gray-05 {
  color: #e4e4e4;
  border-color: #e4e4e4;
}
.btn-outline-gray-05:hover {
  color: #212529;
  background-color: #e4e4e4;
  border-color: #e4e4e4;
}
.btn-outline-gray-05:focus, .btn-outline-gray-05.focus {
  box-shadow: 0 0 0 0.2rem rgba(228, 228, 228, 0.5);
}
.btn-outline-gray-05.disabled, .btn-outline-gray-05:disabled {
  color: #e4e4e4;
  background-color: transparent;
}
.btn-outline-gray-05:not(:disabled):not(.disabled):active, .btn-outline-gray-05:not(:disabled):not(.disabled).active, .show > .btn-outline-gray-05.dropdown-toggle {
  color: #212529;
  background-color: #e4e4e4;
  border-color: #e4e4e4;
}
.btn-outline-gray-05:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-05:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-gray-05.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(228, 228, 228, 0.5);
}

.btn-outline-gray-01 {
  color: #f8f8f8;
  border-color: #f8f8f8;
}
.btn-outline-gray-01:hover {
  color: #212529;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
}
.btn-outline-gray-01:focus, .btn-outline-gray-01.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 248, 248, 0.5);
}
.btn-outline-gray-01.disabled, .btn-outline-gray-01:disabled {
  color: #f8f8f8;
  background-color: transparent;
}
.btn-outline-gray-01:not(:disabled):not(.disabled):active, .btn-outline-gray-01:not(:disabled):not(.disabled).active, .show > .btn-outline-gray-01.dropdown-toggle {
  color: #212529;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
}
.btn-outline-gray-01:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-01:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-gray-01.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 248, 248, 0.5);
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
}
.btn-outline-white:hover {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-outline-white:focus, .btn-outline-white.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-outline-white.disabled, .btn-outline-white:disabled {
  color: #fff;
  background-color: transparent;
}
.btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active, .show > .btn-outline-white.dropdown-toggle {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-digital-blue {
  color: #0170bc;
  border-color: #0170bc;
}
.btn-outline-digital-blue:hover {
  color: #fff;
  background-color: #0170bc;
  border-color: #0170bc;
}
.btn-outline-digital-blue:focus, .btn-outline-digital-blue.focus {
  box-shadow: 0 0 0 0.2rem rgba(1, 112, 188, 0.5);
}
.btn-outline-digital-blue.disabled, .btn-outline-digital-blue:disabled {
  color: #0170bc;
  background-color: transparent;
}
.btn-outline-digital-blue:not(:disabled):not(.disabled):active, .btn-outline-digital-blue:not(:disabled):not(.disabled).active, .show > .btn-outline-digital-blue.dropdown-toggle {
  color: #fff;
  background-color: #0170bc;
  border-color: #0170bc;
}
.btn-outline-digital-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-digital-blue:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-digital-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(1, 112, 188, 0.5);
}

.btn-outline-digital-blue-10 {
  color: #e7f1f8;
  border-color: #e7f1f8;
}
.btn-outline-digital-blue-10:hover {
  color: #212529;
  background-color: #e7f1f8;
  border-color: #e7f1f8;
}
.btn-outline-digital-blue-10:focus, .btn-outline-digital-blue-10.focus {
  box-shadow: 0 0 0 0.2rem rgba(231, 241, 248, 0.5);
}
.btn-outline-digital-blue-10.disabled, .btn-outline-digital-blue-10:disabled {
  color: #e7f1f8;
  background-color: transparent;
}
.btn-outline-digital-blue-10:not(:disabled):not(.disabled):active, .btn-outline-digital-blue-10:not(:disabled):not(.disabled).active, .show > .btn-outline-digital-blue-10.dropdown-toggle {
  color: #212529;
  background-color: #e7f1f8;
  border-color: #e7f1f8;
}
.btn-outline-digital-blue-10:not(:disabled):not(.disabled):active:focus, .btn-outline-digital-blue-10:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-digital-blue-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(231, 241, 248, 0.5);
}

.btn-outline-cigna-blue-dark {
  color: #004986;
  border-color: #004986;
}
.btn-outline-cigna-blue-dark:hover {
  color: #fff;
  background-color: #004986;
  border-color: #004986;
}
.btn-outline-cigna-blue-dark:focus, .btn-outline-cigna-blue-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 73, 134, 0.5);
}
.btn-outline-cigna-blue-dark.disabled, .btn-outline-cigna-blue-dark:disabled {
  color: #004986;
  background-color: transparent;
}
.btn-outline-cigna-blue-dark:not(:disabled):not(.disabled):active, .btn-outline-cigna-blue-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-cigna-blue-dark.dropdown-toggle {
  color: #fff;
  background-color: #004986;
  border-color: #004986;
}
.btn-outline-cigna-blue-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-cigna-blue-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-cigna-blue-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 73, 134, 0.5);
}

.btn-outline-cigna-blue-light {
  color: #00a9e0;
  border-color: #00a9e0;
}
.btn-outline-cigna-blue-light:hover {
  color: #fff;
  background-color: #00a9e0;
  border-color: #00a9e0;
}
.btn-outline-cigna-blue-light:focus, .btn-outline-cigna-blue-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 169, 224, 0.5);
}
.btn-outline-cigna-blue-light.disabled, .btn-outline-cigna-blue-light:disabled {
  color: #00a9e0;
  background-color: transparent;
}
.btn-outline-cigna-blue-light:not(:disabled):not(.disabled):active, .btn-outline-cigna-blue-light:not(:disabled):not(.disabled).active, .show > .btn-outline-cigna-blue-light.dropdown-toggle {
  color: #fff;
  background-color: #00a9e0;
  border-color: #00a9e0;
}
.btn-outline-cigna-blue-light:not(:disabled):not(.disabled):active:focus, .btn-outline-cigna-blue-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-cigna-blue-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 169, 224, 0.5);
}

.btn-outline-digital-green {
  color: #118503;
  border-color: #118503;
}
.btn-outline-digital-green:hover {
  color: #fff;
  background-color: #118503;
  border-color: #118503;
}
.btn-outline-digital-green:focus, .btn-outline-digital-green.focus {
  box-shadow: 0 0 0 0.2rem rgba(17, 133, 3, 0.5);
}
.btn-outline-digital-green.disabled, .btn-outline-digital-green:disabled {
  color: #118503;
  background-color: transparent;
}
.btn-outline-digital-green:not(:disabled):not(.disabled):active, .btn-outline-digital-green:not(:disabled):not(.disabled).active, .show > .btn-outline-digital-green.dropdown-toggle {
  color: #fff;
  background-color: #118503;
  border-color: #118503;
}
.btn-outline-digital-green:not(:disabled):not(.disabled):active:focus, .btn-outline-digital-green:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-digital-green.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(17, 133, 3, 0.5);
}

.btn-outline-digital-green-10 {
  color: #e7f2e5;
  border-color: #e7f2e5;
}
.btn-outline-digital-green-10:hover {
  color: #212529;
  background-color: #e7f2e5;
  border-color: #e7f2e5;
}
.btn-outline-digital-green-10:focus, .btn-outline-digital-green-10.focus {
  box-shadow: 0 0 0 0.2rem rgba(231, 242, 229, 0.5);
}
.btn-outline-digital-green-10.disabled, .btn-outline-digital-green-10:disabled {
  color: #e7f2e5;
  background-color: transparent;
}
.btn-outline-digital-green-10:not(:disabled):not(.disabled):active, .btn-outline-digital-green-10:not(:disabled):not(.disabled).active, .show > .btn-outline-digital-green-10.dropdown-toggle {
  color: #212529;
  background-color: #e7f2e5;
  border-color: #e7f2e5;
}
.btn-outline-digital-green-10:not(:disabled):not(.disabled):active:focus, .btn-outline-digital-green-10:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-digital-green-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(231, 242, 229, 0.5);
}

.btn-outline-cigna-orange-logo {
  color: #f68621;
  border-color: #f68621;
}
.btn-outline-cigna-orange-logo:hover {
  color: #212529;
  background-color: #f68621;
  border-color: #f68621;
}
.btn-outline-cigna-orange-logo:focus, .btn-outline-cigna-orange-logo.focus {
  box-shadow: 0 0 0 0.2rem rgba(246, 134, 33, 0.5);
}
.btn-outline-cigna-orange-logo.disabled, .btn-outline-cigna-orange-logo:disabled {
  color: #f68621;
  background-color: transparent;
}
.btn-outline-cigna-orange-logo:not(:disabled):not(.disabled):active, .btn-outline-cigna-orange-logo:not(:disabled):not(.disabled).active, .show > .btn-outline-cigna-orange-logo.dropdown-toggle {
  color: #212529;
  background-color: #f68621;
  border-color: #f68621;
}
.btn-outline-cigna-orange-logo:not(:disabled):not(.disabled):active:focus, .btn-outline-cigna-orange-logo:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-cigna-orange-logo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(246, 134, 33, 0.5);
}

.btn-outline-cigna-orange-dark {
  color: #e35205;
  border-color: #e35205;
}
.btn-outline-cigna-orange-dark:hover {
  color: #fff;
  background-color: #e35205;
  border-color: #e35205;
}
.btn-outline-cigna-orange-dark:focus, .btn-outline-cigna-orange-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(227, 82, 5, 0.5);
}
.btn-outline-cigna-orange-dark.disabled, .btn-outline-cigna-orange-dark:disabled {
  color: #e35205;
  background-color: transparent;
}
.btn-outline-cigna-orange-dark:not(:disabled):not(.disabled):active, .btn-outline-cigna-orange-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-cigna-orange-dark.dropdown-toggle {
  color: #fff;
  background-color: #e35205;
  border-color: #e35205;
}
.btn-outline-cigna-orange-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-cigna-orange-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-cigna-orange-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(227, 82, 5, 0.5);
}

.btn-outline-cigna-orange-light-20 {
  color: #fcf0d6;
  border-color: #fcf0d6;
}
.btn-outline-cigna-orange-light-20:hover {
  color: #212529;
  background-color: #fcf0d6;
  border-color: #fcf0d6;
}
.btn-outline-cigna-orange-light-20:focus, .btn-outline-cigna-orange-light-20.focus {
  box-shadow: 0 0 0 0.2rem rgba(252, 240, 214, 0.5);
}
.btn-outline-cigna-orange-light-20.disabled, .btn-outline-cigna-orange-light-20:disabled {
  color: #fcf0d6;
  background-color: transparent;
}
.btn-outline-cigna-orange-light-20:not(:disabled):not(.disabled):active, .btn-outline-cigna-orange-light-20:not(:disabled):not(.disabled).active, .show > .btn-outline-cigna-orange-light-20.dropdown-toggle {
  color: #212529;
  background-color: #fcf0d6;
  border-color: #fcf0d6;
}
.btn-outline-cigna-orange-light-20:not(:disabled):not(.disabled):active:focus, .btn-outline-cigna-orange-light-20:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-cigna-orange-light-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(252, 240, 214, 0.5);
}

.btn-outline-digital-red {
  color: #c00;
  border-color: #c00;
}
.btn-outline-digital-red:hover {
  color: #fff;
  background-color: #c00;
  border-color: #c00;
}
.btn-outline-digital-red:focus, .btn-outline-digital-red.focus {
  box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.5);
}
.btn-outline-digital-red.disabled, .btn-outline-digital-red:disabled {
  color: #c00;
  background-color: transparent;
}
.btn-outline-digital-red:not(:disabled):not(.disabled):active, .btn-outline-digital-red:not(:disabled):not(.disabled).active, .show > .btn-outline-digital-red.dropdown-toggle {
  color: #fff;
  background-color: #c00;
  border-color: #c00;
}
.btn-outline-digital-red:not(:disabled):not(.disabled):active:focus, .btn-outline-digital-red:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-digital-red.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.5);
}

.btn-outline-digital-red-10 {
  color: #ffe3e2;
  border-color: #ffe3e2;
}
.btn-outline-digital-red-10:hover {
  color: #212529;
  background-color: #ffe3e2;
  border-color: #ffe3e2;
}
.btn-outline-digital-red-10:focus, .btn-outline-digital-red-10.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 227, 226, 0.5);
}
.btn-outline-digital-red-10.disabled, .btn-outline-digital-red-10:disabled {
  color: #ffe3e2;
  background-color: transparent;
}
.btn-outline-digital-red-10:not(:disabled):not(.disabled):active, .btn-outline-digital-red-10:not(:disabled):not(.disabled).active, .show > .btn-outline-digital-red-10.dropdown-toggle {
  color: #212529;
  background-color: #ffe3e2;
  border-color: #ffe3e2;
}
.btn-outline-digital-red-10:not(:disabled):not(.disabled):active:focus, .btn-outline-digital-red-10:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-digital-red-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 227, 226, 0.5);
}

.btn-outline-digital-purple {
  color: #800080;
  border-color: #800080;
}
.btn-outline-digital-purple:hover {
  color: #fff;
  background-color: #800080;
  border-color: #800080;
}
.btn-outline-digital-purple:focus, .btn-outline-digital-purple.focus {
  box-shadow: 0 0 0 0.2rem rgba(128, 0, 128, 0.5);
}
.btn-outline-digital-purple.disabled, .btn-outline-digital-purple:disabled {
  color: #800080;
  background-color: transparent;
}
.btn-outline-digital-purple:not(:disabled):not(.disabled):active, .btn-outline-digital-purple:not(:disabled):not(.disabled).active, .show > .btn-outline-digital-purple.dropdown-toggle {
  color: #fff;
  background-color: #800080;
  border-color: #800080;
}
.btn-outline-digital-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-digital-purple:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-digital-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(128, 0, 128, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #0170bc;
  text-decoration: none;
}
.btn-link:hover {
  color: #004986;
  text-decoration: none;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #333;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #0170bc;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0170bc;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 16px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -16px;
    margin-left: -16px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 16px;
    margin-bottom: 0;
    margin-left: 16px;
  }
}

.card-group > .card {
  margin-bottom: 16px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item {
  display: flex;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #013a62;
  background-color: #cce2f2;
  border-color: #b8d7ec;
}
.alert-primary hr {
  border-top-color: #a4cce7;
}
.alert-primary .alert-link {
  color: #001c30;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

.alert-digital-black {
  color: #1b1b1b;
  background-color: #d6d6d6;
  border-color: #c6c6c6;
}
.alert-digital-black hr {
  border-top-color: #b9b9b9;
}
.alert-digital-black .alert-link {
  color: #020202;
}

.alert-gray-60 {
  color: #2a2a2a;
  background-color: gainsboro;
  border-color: #cecece;
}
.alert-gray-60 hr {
  border-top-color: #c1c1c1;
}
.alert-gray-60 .alert-link {
  color: #111111;
}

.alert-gray-40 {
  color: #4c4d4e;
  background-color: #e9eaea;
  border-color: #e1e1e2;
}
.alert-gray-40 hr {
  border-top-color: #d4d4d5;
}
.alert-gray-40 .alert-link {
  color: #333434;
}

.alert-gray-15 {
  color: #777777;
  background-color: #fafafa;
  border-color: #f7f7f7;
}
.alert-gray-15 hr {
  border-top-color: #eaeaea;
}
.alert-gray-15 .alert-link {
  color: #5e5e5e;
}

.alert-gray-05 {
  color: #777777;
  background-color: #fafafa;
  border-color: #f7f7f7;
}
.alert-gray-05 hr {
  border-top-color: #eaeaea;
}
.alert-gray-05 .alert-link {
  color: #5e5e5e;
}

.alert-gray-01 {
  color: #818181;
  background-color: #fefefe;
  border-color: #fdfdfd;
}
.alert-gray-01 hr {
  border-top-color: #f0f0f0;
}
.alert-gray-01 .alert-link {
  color: #686868;
}

.alert-white {
  color: #858585;
  background-color: white;
  border-color: white;
}
.alert-white hr {
  border-top-color: #f2f2f2;
}
.alert-white .alert-link {
  color: #6c6c6c;
}

.alert-digital-blue {
  color: #013a62;
  background-color: #cce2f2;
  border-color: #b8d7ec;
}
.alert-digital-blue hr {
  border-top-color: #a4cce7;
}
.alert-digital-blue .alert-link {
  color: #001c30;
}

.alert-digital-blue-10 {
  color: #787d81;
  background-color: #fafcfe;
  border-color: #f8fbfd;
}
.alert-digital-blue-10 hr {
  border-top-color: #e4f0f7;
}
.alert-digital-blue-10 .alert-link {
  color: #5f6367;
}

.alert-cigna-blue-dark {
  color: #002646;
  background-color: #ccdbe7;
  border-color: #b8ccdd;
}
.alert-cigna-blue-dark hr {
  border-top-color: #a7c0d5;
}
.alert-cigna-blue-dark .alert-link {
  color: #000a13;
}

.alert-cigna-blue-light {
  color: #005874;
  background-color: #cceef9;
  border-color: #b8e7f6;
}
.alert-cigna-blue-light hr {
  border-top-color: #a1dff3;
}
.alert-cigna-blue-light .alert-link {
  color: #003141;
}

.alert-digital-green {
  color: #094502;
  background-color: #cfe7cd;
  border-color: #bcddb8;
}
.alert-digital-green hr {
  border-top-color: #acd5a7;
}
.alert-digital-green .alert-link {
  color: #031301;
}

.alert-digital-green-10 {
  color: #787e77;
  background-color: #fafcfa;
  border-color: #f8fbf8;
}
.alert-digital-green-10 hr {
  border-top-color: #e8f2e8;
}
.alert-digital-green-10 .alert-link {
  color: #5f645e;
}

.alert-cigna-orange-logo {
  color: #804611;
  background-color: #fde7d3;
  border-color: #fcddc1;
}
.alert-cigna-orange-logo hr {
  border-top-color: #fbd0a9;
}
.alert-cigna-orange-logo .alert-link {
  color: #532d0b;
}

.alert-cigna-orange-dark {
  color: #762b03;
  background-color: #f9dccd;
  border-color: #f7cfb9;
}
.alert-cigna-orange-dark hr {
  border-top-color: #f4bfa2;
}
.alert-cigna-orange-dark .alert-link {
  color: #441902;
}

.alert-cigna-orange-light-20 {
  color: #837d6f;
  background-color: #fefcf7;
  border-color: #fefbf4;
}
.alert-cigna-orange-light-20 hr {
  border-top-color: #fcf3dd;
}
.alert-cigna-orange-light-20 .alert-link {
  color: #676358;
}

.alert-digital-red {
  color: #6a0000;
  background-color: #f5cccc;
  border-color: #f1b8b8;
}
.alert-digital-red hr {
  border-top-color: #eda3a3;
}
.alert-digital-red .alert-link {
  color: #370000;
}

.alert-digital-red-10 {
  color: #857676;
  background-color: #fff9f9;
  border-color: #fff7f7;
}
.alert-digital-red-10 hr {
  border-top-color: #ffdede;
}
.alert-digital-red-10 .alert-link {
  color: #6a5e5e;
}

.alert-digital-purple {
  color: #430043;
  background-color: #e6cce6;
  border-color: #dbb8db;
}
.alert-digital-purple hr {
  border-top-color: #d2a7d2;
}
.alert-digital-purple .alert-link {
  color: #100010;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0170bc;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #333;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #0170bc;
  border-color: #0170bc;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #013a62;
  background-color: #b8d7ec;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #013a62;
  background-color: #a4cce7;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #013a62;
  border-color: #013a62;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.list-group-item-digital-black {
  color: #1b1b1b;
  background-color: #c6c6c6;
}
.list-group-item-digital-black.list-group-item-action:hover, .list-group-item-digital-black.list-group-item-action:focus {
  color: #1b1b1b;
  background-color: #b9b9b9;
}
.list-group-item-digital-black.list-group-item-action.active {
  color: #fff;
  background-color: #1b1b1b;
  border-color: #1b1b1b;
}

.list-group-item-gray-60 {
  color: #2a2a2a;
  background-color: #cecece;
}
.list-group-item-gray-60.list-group-item-action:hover, .list-group-item-gray-60.list-group-item-action:focus {
  color: #2a2a2a;
  background-color: #c1c1c1;
}
.list-group-item-gray-60.list-group-item-action.active {
  color: #fff;
  background-color: #2a2a2a;
  border-color: #2a2a2a;
}

.list-group-item-gray-40 {
  color: #4c4d4e;
  background-color: #e1e1e2;
}
.list-group-item-gray-40.list-group-item-action:hover, .list-group-item-gray-40.list-group-item-action:focus {
  color: #4c4d4e;
  background-color: #d4d4d5;
}
.list-group-item-gray-40.list-group-item-action.active {
  color: #fff;
  background-color: #4c4d4e;
  border-color: #4c4d4e;
}

.list-group-item-gray-15 {
  color: #777777;
  background-color: #f7f7f7;
}
.list-group-item-gray-15.list-group-item-action:hover, .list-group-item-gray-15.list-group-item-action:focus {
  color: #777777;
  background-color: #eaeaea;
}
.list-group-item-gray-15.list-group-item-action.active {
  color: #fff;
  background-color: #777777;
  border-color: #777777;
}

.list-group-item-gray-05 {
  color: #777777;
  background-color: #f7f7f7;
}
.list-group-item-gray-05.list-group-item-action:hover, .list-group-item-gray-05.list-group-item-action:focus {
  color: #777777;
  background-color: #eaeaea;
}
.list-group-item-gray-05.list-group-item-action.active {
  color: #fff;
  background-color: #777777;
  border-color: #777777;
}

.list-group-item-gray-01 {
  color: #818181;
  background-color: #fdfdfd;
}
.list-group-item-gray-01.list-group-item-action:hover, .list-group-item-gray-01.list-group-item-action:focus {
  color: #818181;
  background-color: #f0f0f0;
}
.list-group-item-gray-01.list-group-item-action.active {
  color: #fff;
  background-color: #818181;
  border-color: #818181;
}

.list-group-item-white {
  color: #858585;
  background-color: white;
}
.list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}
.list-group-item-white.list-group-item-action.active {
  color: #fff;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-digital-blue {
  color: #013a62;
  background-color: #b8d7ec;
}
.list-group-item-digital-blue.list-group-item-action:hover, .list-group-item-digital-blue.list-group-item-action:focus {
  color: #013a62;
  background-color: #a4cce7;
}
.list-group-item-digital-blue.list-group-item-action.active {
  color: #fff;
  background-color: #013a62;
  border-color: #013a62;
}

.list-group-item-digital-blue-10 {
  color: #787d81;
  background-color: #f8fbfd;
}
.list-group-item-digital-blue-10.list-group-item-action:hover, .list-group-item-digital-blue-10.list-group-item-action:focus {
  color: #787d81;
  background-color: #e4f0f7;
}
.list-group-item-digital-blue-10.list-group-item-action.active {
  color: #fff;
  background-color: #787d81;
  border-color: #787d81;
}

.list-group-item-cigna-blue-dark {
  color: #002646;
  background-color: #b8ccdd;
}
.list-group-item-cigna-blue-dark.list-group-item-action:hover, .list-group-item-cigna-blue-dark.list-group-item-action:focus {
  color: #002646;
  background-color: #a7c0d5;
}
.list-group-item-cigna-blue-dark.list-group-item-action.active {
  color: #fff;
  background-color: #002646;
  border-color: #002646;
}

.list-group-item-cigna-blue-light {
  color: #005874;
  background-color: #b8e7f6;
}
.list-group-item-cigna-blue-light.list-group-item-action:hover, .list-group-item-cigna-blue-light.list-group-item-action:focus {
  color: #005874;
  background-color: #a1dff3;
}
.list-group-item-cigna-blue-light.list-group-item-action.active {
  color: #fff;
  background-color: #005874;
  border-color: #005874;
}

.list-group-item-digital-green {
  color: #094502;
  background-color: #bcddb8;
}
.list-group-item-digital-green.list-group-item-action:hover, .list-group-item-digital-green.list-group-item-action:focus {
  color: #094502;
  background-color: #acd5a7;
}
.list-group-item-digital-green.list-group-item-action.active {
  color: #fff;
  background-color: #094502;
  border-color: #094502;
}

.list-group-item-digital-green-10 {
  color: #787e77;
  background-color: #f8fbf8;
}
.list-group-item-digital-green-10.list-group-item-action:hover, .list-group-item-digital-green-10.list-group-item-action:focus {
  color: #787e77;
  background-color: #e8f2e8;
}
.list-group-item-digital-green-10.list-group-item-action.active {
  color: #fff;
  background-color: #787e77;
  border-color: #787e77;
}

.list-group-item-cigna-orange-logo {
  color: #804611;
  background-color: #fcddc1;
}
.list-group-item-cigna-orange-logo.list-group-item-action:hover, .list-group-item-cigna-orange-logo.list-group-item-action:focus {
  color: #804611;
  background-color: #fbd0a9;
}
.list-group-item-cigna-orange-logo.list-group-item-action.active {
  color: #fff;
  background-color: #804611;
  border-color: #804611;
}

.list-group-item-cigna-orange-dark {
  color: #762b03;
  background-color: #f7cfb9;
}
.list-group-item-cigna-orange-dark.list-group-item-action:hover, .list-group-item-cigna-orange-dark.list-group-item-action:focus {
  color: #762b03;
  background-color: #f4bfa2;
}
.list-group-item-cigna-orange-dark.list-group-item-action.active {
  color: #fff;
  background-color: #762b03;
  border-color: #762b03;
}

.list-group-item-cigna-orange-light-20 {
  color: #837d6f;
  background-color: #fefbf4;
}
.list-group-item-cigna-orange-light-20.list-group-item-action:hover, .list-group-item-cigna-orange-light-20.list-group-item-action:focus {
  color: #837d6f;
  background-color: #fcf3dd;
}
.list-group-item-cigna-orange-light-20.list-group-item-action.active {
  color: #fff;
  background-color: #837d6f;
  border-color: #837d6f;
}

.list-group-item-digital-red {
  color: #6a0000;
  background-color: #f1b8b8;
}
.list-group-item-digital-red.list-group-item-action:hover, .list-group-item-digital-red.list-group-item-action:focus {
  color: #6a0000;
  background-color: #eda3a3;
}
.list-group-item-digital-red.list-group-item-action.active {
  color: #fff;
  background-color: #6a0000;
  border-color: #6a0000;
}

.list-group-item-digital-red-10 {
  color: #857676;
  background-color: #fff7f7;
}
.list-group-item-digital-red-10.list-group-item-action:hover, .list-group-item-digital-red-10.list-group-item-action:focus {
  color: #857676;
  background-color: #ffdede;
}
.list-group-item-digital-red-10.list-group-item-action.active {
  color: #fff;
  background-color: #857676;
  border-color: #857676;
}

.list-group-item-digital-purple {
  color: #430043;
  background-color: #dbb8db;
}
.list-group-item-digital-purple.list-group-item-action:hover, .list-group-item-digital-purple.list-group-item-action:focus {
  color: #430043;
  background-color: #d2a7d2;
}
.list-group-item-digital-purple.list-group-item-action.active {
  color: #fff;
  background-color: #430043;
  border-color: #430043;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e4e4e4;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #e4e4e4;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #333;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #0170bc !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #015289 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-digital-black {
  background-color: #333 !important;
}

a.bg-digital-black:hover, a.bg-digital-black:focus,
button.bg-digital-black:hover,
button.bg-digital-black:focus {
  background-color: #1a1a1a !important;
}

.bg-gray-60 {
  background-color: #505050 !important;
}

a.bg-gray-60:hover, a.bg-gray-60:focus,
button.bg-gray-60:hover,
button.bg-gray-60:focus {
  background-color: #373737 !important;
}

.bg-gray-40 {
  background-color: #939496 !important;
}

a.bg-gray-40:hover, a.bg-gray-40:focus,
button.bg-gray-40:hover,
button.bg-gray-40:focus {
  background-color: #797a7d !important;
}

.bg-gray-15 {
  background-color: #e4e4e4 !important;
}

a.bg-gray-15:hover, a.bg-gray-15:focus,
button.bg-gray-15:hover,
button.bg-gray-15:focus {
  background-color: #cbcbcb !important;
}

.bg-gray-05 {
  background-color: #e4e4e4 !important;
}

a.bg-gray-05:hover, a.bg-gray-05:focus,
button.bg-gray-05:hover,
button.bg-gray-05:focus {
  background-color: #cbcbcb !important;
}

.bg-gray-01 {
  background-color: #f8f8f8 !important;
}

a.bg-gray-01:hover, a.bg-gray-01:focus,
button.bg-gray-01:hover,
button.bg-gray-01:focus {
  background-color: #dfdfdf !important;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-digital-blue {
  background-color: #0170bc !important;
}

a.bg-digital-blue:hover, a.bg-digital-blue:focus,
button.bg-digital-blue:hover,
button.bg-digital-blue:focus {
  background-color: #015289 !important;
}

.bg-digital-blue-10 {
  background-color: #e7f1f8 !important;
}

a.bg-digital-blue-10:hover, a.bg-digital-blue-10:focus,
button.bg-digital-blue-10:hover,
button.bg-digital-blue-10:focus {
  background-color: #c0daec !important;
}

.bg-cigna-blue-dark {
  background-color: #004986 !important;
}

a.bg-cigna-blue-dark:hover, a.bg-cigna-blue-dark:focus,
button.bg-cigna-blue-dark:hover,
button.bg-cigna-blue-dark:focus {
  background-color: #002d53 !important;
}

.bg-cigna-blue-light {
  background-color: #00a9e0 !important;
}

a.bg-cigna-blue-light:hover, a.bg-cigna-blue-light:focus,
button.bg-cigna-blue-light:hover,
button.bg-cigna-blue-light:focus {
  background-color: #0083ad !important;
}

.bg-digital-green {
  background-color: #118503 !important;
}

a.bg-digital-green:hover, a.bg-digital-green:focus,
button.bg-digital-green:hover,
button.bg-digital-green:focus {
  background-color: #0b5302 !important;
}

.bg-digital-green-10 {
  background-color: #e7f2e5 !important;
}

a.bg-digital-green-10:hover, a.bg-digital-green-10:focus,
button.bg-digital-green-10:hover,
button.bg-digital-green-10:focus {
  background-color: #c8e1c3 !important;
}

.bg-cigna-orange-logo {
  background-color: #f68621 !important;
}

a.bg-cigna-orange-logo:hover, a.bg-cigna-orange-logo:focus,
button.bg-cigna-orange-logo:hover,
button.bg-cigna-orange-logo:focus {
  background-color: #db6d09 !important;
}

.bg-cigna-orange-dark {
  background-color: #e35205 !important;
}

a.bg-cigna-orange-dark:hover, a.bg-cigna-orange-dark:focus,
button.bg-cigna-orange-dark:hover,
button.bg-cigna-orange-dark:focus {
  background-color: #b14004 !important;
}

.bg-cigna-orange-light-20 {
  background-color: #fcf0d6 !important;
}

a.bg-cigna-orange-light-20:hover, a.bg-cigna-orange-light-20:focus,
button.bg-cigna-orange-light-20:hover,
button.bg-cigna-orange-light-20:focus {
  background-color: #f9dfa6 !important;
}

.bg-digital-red {
  background-color: #c00 !important;
}

a.bg-digital-red:hover, a.bg-digital-red:focus,
button.bg-digital-red:hover,
button.bg-digital-red:focus {
  background-color: #990000 !important;
}

.bg-digital-red-10 {
  background-color: #ffe3e2 !important;
}

a.bg-digital-red-10:hover, a.bg-digital-red-10:focus,
button.bg-digital-red-10:hover,
button.bg-digital-red-10:focus {
  background-color: #ffb2af !important;
}

.bg-digital-purple {
  background-color: #800080 !important;
}

a.bg-digital-purple:hover, a.bg-digital-purple:focus,
button.bg-digital-purple:hover,
button.bg-digital-purple:focus {
  background-color: #4d004d !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #e4e4e4 !important;
}

.border-top {
  border-top: 1px solid #e4e4e4 !important;
}

.border-right {
  border-right: 1px solid #e4e4e4 !important;
}

.border-bottom {
  border-bottom: 1px solid #e4e4e4 !important;
}

.border-left {
  border-left: 1px solid #e4e4e4 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #0170bc !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-digital-black {
  border-color: #333 !important;
}

.border-gray-60 {
  border-color: #505050 !important;
}

.border-gray-40 {
  border-color: #939496 !important;
}

.border-gray-15 {
  border-color: #e4e4e4 !important;
}

.border-gray-05 {
  border-color: #e4e4e4 !important;
}

.border-gray-01 {
  border-color: #f8f8f8 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-digital-blue {
  border-color: #0170bc !important;
}

.border-digital-blue-10 {
  border-color: #e7f1f8 !important;
}

.border-cigna-blue-dark {
  border-color: #004986 !important;
}

.border-cigna-blue-light {
  border-color: #00a9e0 !important;
}

.border-digital-green {
  border-color: #118503 !important;
}

.border-digital-green-10 {
  border-color: #e7f2e5 !important;
}

.border-cigna-orange-logo {
  border-color: #f68621 !important;
}

.border-cigna-orange-dark {
  border-color: #e35205 !important;
}

.border-cigna-orange-light-20 {
  border-color: #fcf0d6 !important;
}

.border-digital-red {
  border-color: #c00 !important;
}

.border-digital-red-10 {
  border-color: #ffe3e2 !important;
}

.border-digital-purple {
  border-color: #800080 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.m-xs {
  margin: 0.25rem !important;
}

.mt-xs,
.my-xs {
  margin-top: 0.25rem !important;
}

.mr-xs,
.mx-xs {
  margin-right: 0.25rem !important;
}

.mb-xs,
.my-xs {
  margin-bottom: 0.25rem !important;
}

.ml-xs,
.mx-xs {
  margin-left: 0.25rem !important;
}

.m-s {
  margin: 0.5rem !important;
}

.mt-s,
.my-s {
  margin-top: 0.5rem !important;
}

.mr-s,
.mx-s {
  margin-right: 0.5rem !important;
}

.mb-s,
.my-s {
  margin-bottom: 0.5rem !important;
}

.ml-s,
.mx-s {
  margin-left: 0.5rem !important;
}

.m-m {
  margin: 1rem !important;
}

.mt-m,
.my-m {
  margin-top: 1rem !important;
}

.mr-m,
.mx-m {
  margin-right: 1rem !important;
}

.mb-m,
.my-m {
  margin-bottom: 1rem !important;
}

.ml-m,
.mx-m {
  margin-left: 1rem !important;
}

.m-ml {
  margin: 1.5rem !important;
}

.mt-ml,
.my-ml {
  margin-top: 1.5rem !important;
}

.mr-ml,
.mx-ml {
  margin-right: 1.5rem !important;
}

.mb-ml,
.my-ml {
  margin-bottom: 1.5rem !important;
}

.ml-ml,
.mx-ml {
  margin-left: 1.5rem !important;
}

.m-l {
  margin: 2rem !important;
}

.mt-l,
.my-l {
  margin-top: 2rem !important;
}

.mr-l,
.mx-l {
  margin-right: 2rem !important;
}

.mb-l,
.my-l {
  margin-bottom: 2rem !important;
}

.ml-l,
.mx-l {
  margin-left: 2rem !important;
}

.m-xl {
  margin: 3rem !important;
}

.mt-xl,
.my-xl {
  margin-top: 3rem !important;
}

.mr-xl,
.mx-xl {
  margin-right: 3rem !important;
}

.mb-xl,
.my-xl {
  margin-bottom: 3rem !important;
}

.ml-xl,
.mx-xl {
  margin-left: 3rem !important;
}

.m-xxl {
  margin: 4rem !important;
}

.mt-xxl,
.my-xxl {
  margin-top: 4rem !important;
}

.mr-xxl,
.mx-xxl {
  margin-right: 4rem !important;
}

.mb-xxl,
.my-xxl {
  margin-bottom: 4rem !important;
}

.ml-xxl,
.mx-xxl {
  margin-left: 4rem !important;
}

.m-xxxl {
  margin: 4.5rem !important;
}

.mt-xxxl,
.my-xxxl {
  margin-top: 4.5rem !important;
}

.mr-xxxl,
.mx-xxxl {
  margin-right: 4.5rem !important;
}

.mb-xxxl,
.my-xxxl {
  margin-bottom: 4.5rem !important;
}

.ml-xxxl,
.mx-xxxl {
  margin-left: 4.5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.p-xs {
  padding: 0.25rem !important;
}

.pt-xs,
.py-xs {
  padding-top: 0.25rem !important;
}

.pr-xs,
.px-xs {
  padding-right: 0.25rem !important;
}

.pb-xs,
.py-xs {
  padding-bottom: 0.25rem !important;
}

.pl-xs,
.px-xs {
  padding-left: 0.25rem !important;
}

.p-s {
  padding: 0.5rem !important;
}

.pt-s,
.py-s {
  padding-top: 0.5rem !important;
}

.pr-s,
.px-s {
  padding-right: 0.5rem !important;
}

.pb-s,
.py-s {
  padding-bottom: 0.5rem !important;
}

.pl-s,
.px-s {
  padding-left: 0.5rem !important;
}

.p-m {
  padding: 1rem !important;
}

.pt-m,
.py-m {
  padding-top: 1rem !important;
}

.pr-m,
.px-m {
  padding-right: 1rem !important;
}

.pb-m,
.py-m {
  padding-bottom: 1rem !important;
}

.pl-m,
.px-m {
  padding-left: 1rem !important;
}

.p-ml {
  padding: 1.5rem !important;
}

.pt-ml,
.py-ml {
  padding-top: 1.5rem !important;
}

.pr-ml,
.px-ml {
  padding-right: 1.5rem !important;
}

.pb-ml,
.py-ml {
  padding-bottom: 1.5rem !important;
}

.pl-ml,
.px-ml {
  padding-left: 1.5rem !important;
}

.p-l {
  padding: 2rem !important;
}

.pt-l,
.py-l {
  padding-top: 2rem !important;
}

.pr-l,
.px-l {
  padding-right: 2rem !important;
}

.pb-l,
.py-l {
  padding-bottom: 2rem !important;
}

.pl-l,
.px-l {
  padding-left: 2rem !important;
}

.p-xl {
  padding: 3rem !important;
}

.pt-xl,
.py-xl {
  padding-top: 3rem !important;
}

.pr-xl,
.px-xl {
  padding-right: 3rem !important;
}

.pb-xl,
.py-xl {
  padding-bottom: 3rem !important;
}

.pl-xl,
.px-xl {
  padding-left: 3rem !important;
}

.p-xxl {
  padding: 4rem !important;
}

.pt-xxl,
.py-xxl {
  padding-top: 4rem !important;
}

.pr-xxl,
.px-xxl {
  padding-right: 4rem !important;
}

.pb-xxl,
.py-xxl {
  padding-bottom: 4rem !important;
}

.pl-xxl,
.px-xxl {
  padding-left: 4rem !important;
}

.p-xxxl {
  padding: 4.5rem !important;
}

.pt-xxxl,
.py-xxxl {
  padding-top: 4.5rem !important;
}

.pr-xxxl,
.px-xxxl {
  padding-right: 4.5rem !important;
}

.pb-xxxl,
.py-xxxl {
  padding-bottom: 4.5rem !important;
}

.pl-xxxl,
.px-xxxl {
  padding-left: 4.5rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-nxs {
  margin: -0.25rem !important;
}

.mt-nxs,
.my-nxs {
  margin-top: -0.25rem !important;
}

.mr-nxs,
.mx-nxs {
  margin-right: -0.25rem !important;
}

.mb-nxs,
.my-nxs {
  margin-bottom: -0.25rem !important;
}

.ml-nxs,
.mx-nxs {
  margin-left: -0.25rem !important;
}

.m-ns {
  margin: -0.5rem !important;
}

.mt-ns,
.my-ns {
  margin-top: -0.5rem !important;
}

.mr-ns,
.mx-ns {
  margin-right: -0.5rem !important;
}

.mb-ns,
.my-ns {
  margin-bottom: -0.5rem !important;
}

.ml-ns,
.mx-ns {
  margin-left: -0.5rem !important;
}

.m-nm {
  margin: -1rem !important;
}

.mt-nm,
.my-nm {
  margin-top: -1rem !important;
}

.mr-nm,
.mx-nm {
  margin-right: -1rem !important;
}

.mb-nm,
.my-nm {
  margin-bottom: -1rem !important;
}

.ml-nm,
.mx-nm {
  margin-left: -1rem !important;
}

.m-nml {
  margin: -1.5rem !important;
}

.mt-nml,
.my-nml {
  margin-top: -1.5rem !important;
}

.mr-nml,
.mx-nml {
  margin-right: -1.5rem !important;
}

.mb-nml,
.my-nml {
  margin-bottom: -1.5rem !important;
}

.ml-nml,
.mx-nml {
  margin-left: -1.5rem !important;
}

.m-nl {
  margin: -2rem !important;
}

.mt-nl,
.my-nl {
  margin-top: -2rem !important;
}

.mr-nl,
.mx-nl {
  margin-right: -2rem !important;
}

.mb-nl,
.my-nl {
  margin-bottom: -2rem !important;
}

.ml-nl,
.mx-nl {
  margin-left: -2rem !important;
}

.m-nxl {
  margin: -3rem !important;
}

.mt-nxl,
.my-nxl {
  margin-top: -3rem !important;
}

.mr-nxl,
.mx-nxl {
  margin-right: -3rem !important;
}

.mb-nxl,
.my-nxl {
  margin-bottom: -3rem !important;
}

.ml-nxl,
.mx-nxl {
  margin-left: -3rem !important;
}

.m-nxxl {
  margin: -4rem !important;
}

.mt-nxxl,
.my-nxxl {
  margin-top: -4rem !important;
}

.mr-nxxl,
.mx-nxxl {
  margin-right: -4rem !important;
}

.mb-nxxl,
.my-nxxl {
  margin-bottom: -4rem !important;
}

.ml-nxxl,
.mx-nxxl {
  margin-left: -4rem !important;
}

.m-nxxxl {
  margin: -4.5rem !important;
}

.mt-nxxxl,
.my-nxxxl {
  margin-top: -4.5rem !important;
}

.mr-nxxxl,
.mx-nxxxl {
  margin-right: -4.5rem !important;
}

.mb-nxxxl,
.my-nxxxl {
  margin-bottom: -4.5rem !important;
}

.ml-nxxxl,
.mx-nxxxl {
  margin-left: -4.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .m-sm-xs {
    margin: 0.25rem !important;
  }

  .mt-sm-xs,
.my-sm-xs {
    margin-top: 0.25rem !important;
  }

  .mr-sm-xs,
.mx-sm-xs {
    margin-right: 0.25rem !important;
  }

  .mb-sm-xs,
.my-sm-xs {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-xs,
.mx-sm-xs {
    margin-left: 0.25rem !important;
  }

  .m-sm-s {
    margin: 0.5rem !important;
  }

  .mt-sm-s,
.my-sm-s {
    margin-top: 0.5rem !important;
  }

  .mr-sm-s,
.mx-sm-s {
    margin-right: 0.5rem !important;
  }

  .mb-sm-s,
.my-sm-s {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-s,
.mx-sm-s {
    margin-left: 0.5rem !important;
  }

  .m-sm-m {
    margin: 1rem !important;
  }

  .mt-sm-m,
.my-sm-m {
    margin-top: 1rem !important;
  }

  .mr-sm-m,
.mx-sm-m {
    margin-right: 1rem !important;
  }

  .mb-sm-m,
.my-sm-m {
    margin-bottom: 1rem !important;
  }

  .ml-sm-m,
.mx-sm-m {
    margin-left: 1rem !important;
  }

  .m-sm-ml {
    margin: 1.5rem !important;
  }

  .mt-sm-ml,
.my-sm-ml {
    margin-top: 1.5rem !important;
  }

  .mr-sm-ml,
.mx-sm-ml {
    margin-right: 1.5rem !important;
  }

  .mb-sm-ml,
.my-sm-ml {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-ml,
.mx-sm-ml {
    margin-left: 1.5rem !important;
  }

  .m-sm-l {
    margin: 2rem !important;
  }

  .mt-sm-l,
.my-sm-l {
    margin-top: 2rem !important;
  }

  .mr-sm-l,
.mx-sm-l {
    margin-right: 2rem !important;
  }

  .mb-sm-l,
.my-sm-l {
    margin-bottom: 2rem !important;
  }

  .ml-sm-l,
.mx-sm-l {
    margin-left: 2rem !important;
  }

  .m-sm-xl {
    margin: 3rem !important;
  }

  .mt-sm-xl,
.my-sm-xl {
    margin-top: 3rem !important;
  }

  .mr-sm-xl,
.mx-sm-xl {
    margin-right: 3rem !important;
  }

  .mb-sm-xl,
.my-sm-xl {
    margin-bottom: 3rem !important;
  }

  .ml-sm-xl,
.mx-sm-xl {
    margin-left: 3rem !important;
  }

  .m-sm-xxl {
    margin: 4rem !important;
  }

  .mt-sm-xxl,
.my-sm-xxl {
    margin-top: 4rem !important;
  }

  .mr-sm-xxl,
.mx-sm-xxl {
    margin-right: 4rem !important;
  }

  .mb-sm-xxl,
.my-sm-xxl {
    margin-bottom: 4rem !important;
  }

  .ml-sm-xxl,
.mx-sm-xxl {
    margin-left: 4rem !important;
  }

  .m-sm-xxxl {
    margin: 4.5rem !important;
  }

  .mt-sm-xxxl,
.my-sm-xxxl {
    margin-top: 4.5rem !important;
  }

  .mr-sm-xxxl,
.mx-sm-xxxl {
    margin-right: 4.5rem !important;
  }

  .mb-sm-xxxl,
.my-sm-xxxl {
    margin-bottom: 4.5rem !important;
  }

  .ml-sm-xxxl,
.mx-sm-xxxl {
    margin-left: 4.5rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .p-sm-xs {
    padding: 0.25rem !important;
  }

  .pt-sm-xs,
.py-sm-xs {
    padding-top: 0.25rem !important;
  }

  .pr-sm-xs,
.px-sm-xs {
    padding-right: 0.25rem !important;
  }

  .pb-sm-xs,
.py-sm-xs {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-xs,
.px-sm-xs {
    padding-left: 0.25rem !important;
  }

  .p-sm-s {
    padding: 0.5rem !important;
  }

  .pt-sm-s,
.py-sm-s {
    padding-top: 0.5rem !important;
  }

  .pr-sm-s,
.px-sm-s {
    padding-right: 0.5rem !important;
  }

  .pb-sm-s,
.py-sm-s {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-s,
.px-sm-s {
    padding-left: 0.5rem !important;
  }

  .p-sm-m {
    padding: 1rem !important;
  }

  .pt-sm-m,
.py-sm-m {
    padding-top: 1rem !important;
  }

  .pr-sm-m,
.px-sm-m {
    padding-right: 1rem !important;
  }

  .pb-sm-m,
.py-sm-m {
    padding-bottom: 1rem !important;
  }

  .pl-sm-m,
.px-sm-m {
    padding-left: 1rem !important;
  }

  .p-sm-ml {
    padding: 1.5rem !important;
  }

  .pt-sm-ml,
.py-sm-ml {
    padding-top: 1.5rem !important;
  }

  .pr-sm-ml,
.px-sm-ml {
    padding-right: 1.5rem !important;
  }

  .pb-sm-ml,
.py-sm-ml {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-ml,
.px-sm-ml {
    padding-left: 1.5rem !important;
  }

  .p-sm-l {
    padding: 2rem !important;
  }

  .pt-sm-l,
.py-sm-l {
    padding-top: 2rem !important;
  }

  .pr-sm-l,
.px-sm-l {
    padding-right: 2rem !important;
  }

  .pb-sm-l,
.py-sm-l {
    padding-bottom: 2rem !important;
  }

  .pl-sm-l,
.px-sm-l {
    padding-left: 2rem !important;
  }

  .p-sm-xl {
    padding: 3rem !important;
  }

  .pt-sm-xl,
.py-sm-xl {
    padding-top: 3rem !important;
  }

  .pr-sm-xl,
.px-sm-xl {
    padding-right: 3rem !important;
  }

  .pb-sm-xl,
.py-sm-xl {
    padding-bottom: 3rem !important;
  }

  .pl-sm-xl,
.px-sm-xl {
    padding-left: 3rem !important;
  }

  .p-sm-xxl {
    padding: 4rem !important;
  }

  .pt-sm-xxl,
.py-sm-xxl {
    padding-top: 4rem !important;
  }

  .pr-sm-xxl,
.px-sm-xxl {
    padding-right: 4rem !important;
  }

  .pb-sm-xxl,
.py-sm-xxl {
    padding-bottom: 4rem !important;
  }

  .pl-sm-xxl,
.px-sm-xxl {
    padding-left: 4rem !important;
  }

  .p-sm-xxxl {
    padding: 4.5rem !important;
  }

  .pt-sm-xxxl,
.py-sm-xxxl {
    padding-top: 4.5rem !important;
  }

  .pr-sm-xxxl,
.px-sm-xxxl {
    padding-right: 4.5rem !important;
  }

  .pb-sm-xxxl,
.py-sm-xxxl {
    padding-bottom: 4.5rem !important;
  }

  .pl-sm-xxxl,
.px-sm-xxxl {
    padding-left: 4.5rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-nxs {
    margin: -0.25rem !important;
  }

  .mt-sm-nxs,
.my-sm-nxs {
    margin-top: -0.25rem !important;
  }

  .mr-sm-nxs,
.mx-sm-nxs {
    margin-right: -0.25rem !important;
  }

  .mb-sm-nxs,
.my-sm-nxs {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-nxs,
.mx-sm-nxs {
    margin-left: -0.25rem !important;
  }

  .m-sm-ns {
    margin: -0.5rem !important;
  }

  .mt-sm-ns,
.my-sm-ns {
    margin-top: -0.5rem !important;
  }

  .mr-sm-ns,
.mx-sm-ns {
    margin-right: -0.5rem !important;
  }

  .mb-sm-ns,
.my-sm-ns {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-ns,
.mx-sm-ns {
    margin-left: -0.5rem !important;
  }

  .m-sm-nm {
    margin: -1rem !important;
  }

  .mt-sm-nm,
.my-sm-nm {
    margin-top: -1rem !important;
  }

  .mr-sm-nm,
.mx-sm-nm {
    margin-right: -1rem !important;
  }

  .mb-sm-nm,
.my-sm-nm {
    margin-bottom: -1rem !important;
  }

  .ml-sm-nm,
.mx-sm-nm {
    margin-left: -1rem !important;
  }

  .m-sm-nml {
    margin: -1.5rem !important;
  }

  .mt-sm-nml,
.my-sm-nml {
    margin-top: -1.5rem !important;
  }

  .mr-sm-nml,
.mx-sm-nml {
    margin-right: -1.5rem !important;
  }

  .mb-sm-nml,
.my-sm-nml {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-nml,
.mx-sm-nml {
    margin-left: -1.5rem !important;
  }

  .m-sm-nl {
    margin: -2rem !important;
  }

  .mt-sm-nl,
.my-sm-nl {
    margin-top: -2rem !important;
  }

  .mr-sm-nl,
.mx-sm-nl {
    margin-right: -2rem !important;
  }

  .mb-sm-nl,
.my-sm-nl {
    margin-bottom: -2rem !important;
  }

  .ml-sm-nl,
.mx-sm-nl {
    margin-left: -2rem !important;
  }

  .m-sm-nxl {
    margin: -3rem !important;
  }

  .mt-sm-nxl,
.my-sm-nxl {
    margin-top: -3rem !important;
  }

  .mr-sm-nxl,
.mx-sm-nxl {
    margin-right: -3rem !important;
  }

  .mb-sm-nxl,
.my-sm-nxl {
    margin-bottom: -3rem !important;
  }

  .ml-sm-nxl,
.mx-sm-nxl {
    margin-left: -3rem !important;
  }

  .m-sm-nxxl {
    margin: -4rem !important;
  }

  .mt-sm-nxxl,
.my-sm-nxxl {
    margin-top: -4rem !important;
  }

  .mr-sm-nxxl,
.mx-sm-nxxl {
    margin-right: -4rem !important;
  }

  .mb-sm-nxxl,
.my-sm-nxxl {
    margin-bottom: -4rem !important;
  }

  .ml-sm-nxxl,
.mx-sm-nxxl {
    margin-left: -4rem !important;
  }

  .m-sm-nxxxl {
    margin: -4.5rem !important;
  }

  .mt-sm-nxxxl,
.my-sm-nxxxl {
    margin-top: -4.5rem !important;
  }

  .mr-sm-nxxxl,
.mx-sm-nxxxl {
    margin-right: -4.5rem !important;
  }

  .mb-sm-nxxxl,
.my-sm-nxxxl {
    margin-bottom: -4.5rem !important;
  }

  .ml-sm-nxxxl,
.mx-sm-nxxxl {
    margin-left: -4.5rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .m-md-xs {
    margin: 0.25rem !important;
  }

  .mt-md-xs,
.my-md-xs {
    margin-top: 0.25rem !important;
  }

  .mr-md-xs,
.mx-md-xs {
    margin-right: 0.25rem !important;
  }

  .mb-md-xs,
.my-md-xs {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-xs,
.mx-md-xs {
    margin-left: 0.25rem !important;
  }

  .m-md-s {
    margin: 0.5rem !important;
  }

  .mt-md-s,
.my-md-s {
    margin-top: 0.5rem !important;
  }

  .mr-md-s,
.mx-md-s {
    margin-right: 0.5rem !important;
  }

  .mb-md-s,
.my-md-s {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-s,
.mx-md-s {
    margin-left: 0.5rem !important;
  }

  .m-md-m {
    margin: 1rem !important;
  }

  .mt-md-m,
.my-md-m {
    margin-top: 1rem !important;
  }

  .mr-md-m,
.mx-md-m {
    margin-right: 1rem !important;
  }

  .mb-md-m,
.my-md-m {
    margin-bottom: 1rem !important;
  }

  .ml-md-m,
.mx-md-m {
    margin-left: 1rem !important;
  }

  .m-md-ml {
    margin: 1.5rem !important;
  }

  .mt-md-ml,
.my-md-ml {
    margin-top: 1.5rem !important;
  }

  .mr-md-ml,
.mx-md-ml {
    margin-right: 1.5rem !important;
  }

  .mb-md-ml,
.my-md-ml {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-ml,
.mx-md-ml {
    margin-left: 1.5rem !important;
  }

  .m-md-l {
    margin: 2rem !important;
  }

  .mt-md-l,
.my-md-l {
    margin-top: 2rem !important;
  }

  .mr-md-l,
.mx-md-l {
    margin-right: 2rem !important;
  }

  .mb-md-l,
.my-md-l {
    margin-bottom: 2rem !important;
  }

  .ml-md-l,
.mx-md-l {
    margin-left: 2rem !important;
  }

  .m-md-xl {
    margin: 3rem !important;
  }

  .mt-md-xl,
.my-md-xl {
    margin-top: 3rem !important;
  }

  .mr-md-xl,
.mx-md-xl {
    margin-right: 3rem !important;
  }

  .mb-md-xl,
.my-md-xl {
    margin-bottom: 3rem !important;
  }

  .ml-md-xl,
.mx-md-xl {
    margin-left: 3rem !important;
  }

  .m-md-xxl {
    margin: 4rem !important;
  }

  .mt-md-xxl,
.my-md-xxl {
    margin-top: 4rem !important;
  }

  .mr-md-xxl,
.mx-md-xxl {
    margin-right: 4rem !important;
  }

  .mb-md-xxl,
.my-md-xxl {
    margin-bottom: 4rem !important;
  }

  .ml-md-xxl,
.mx-md-xxl {
    margin-left: 4rem !important;
  }

  .m-md-xxxl {
    margin: 4.5rem !important;
  }

  .mt-md-xxxl,
.my-md-xxxl {
    margin-top: 4.5rem !important;
  }

  .mr-md-xxxl,
.mx-md-xxxl {
    margin-right: 4.5rem !important;
  }

  .mb-md-xxxl,
.my-md-xxxl {
    margin-bottom: 4.5rem !important;
  }

  .ml-md-xxxl,
.mx-md-xxxl {
    margin-left: 4.5rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .p-md-xs {
    padding: 0.25rem !important;
  }

  .pt-md-xs,
.py-md-xs {
    padding-top: 0.25rem !important;
  }

  .pr-md-xs,
.px-md-xs {
    padding-right: 0.25rem !important;
  }

  .pb-md-xs,
.py-md-xs {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-xs,
.px-md-xs {
    padding-left: 0.25rem !important;
  }

  .p-md-s {
    padding: 0.5rem !important;
  }

  .pt-md-s,
.py-md-s {
    padding-top: 0.5rem !important;
  }

  .pr-md-s,
.px-md-s {
    padding-right: 0.5rem !important;
  }

  .pb-md-s,
.py-md-s {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-s,
.px-md-s {
    padding-left: 0.5rem !important;
  }

  .p-md-m {
    padding: 1rem !important;
  }

  .pt-md-m,
.py-md-m {
    padding-top: 1rem !important;
  }

  .pr-md-m,
.px-md-m {
    padding-right: 1rem !important;
  }

  .pb-md-m,
.py-md-m {
    padding-bottom: 1rem !important;
  }

  .pl-md-m,
.px-md-m {
    padding-left: 1rem !important;
  }

  .p-md-ml {
    padding: 1.5rem !important;
  }

  .pt-md-ml,
.py-md-ml {
    padding-top: 1.5rem !important;
  }

  .pr-md-ml,
.px-md-ml {
    padding-right: 1.5rem !important;
  }

  .pb-md-ml,
.py-md-ml {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-ml,
.px-md-ml {
    padding-left: 1.5rem !important;
  }

  .p-md-l {
    padding: 2rem !important;
  }

  .pt-md-l,
.py-md-l {
    padding-top: 2rem !important;
  }

  .pr-md-l,
.px-md-l {
    padding-right: 2rem !important;
  }

  .pb-md-l,
.py-md-l {
    padding-bottom: 2rem !important;
  }

  .pl-md-l,
.px-md-l {
    padding-left: 2rem !important;
  }

  .p-md-xl {
    padding: 3rem !important;
  }

  .pt-md-xl,
.py-md-xl {
    padding-top: 3rem !important;
  }

  .pr-md-xl,
.px-md-xl {
    padding-right: 3rem !important;
  }

  .pb-md-xl,
.py-md-xl {
    padding-bottom: 3rem !important;
  }

  .pl-md-xl,
.px-md-xl {
    padding-left: 3rem !important;
  }

  .p-md-xxl {
    padding: 4rem !important;
  }

  .pt-md-xxl,
.py-md-xxl {
    padding-top: 4rem !important;
  }

  .pr-md-xxl,
.px-md-xxl {
    padding-right: 4rem !important;
  }

  .pb-md-xxl,
.py-md-xxl {
    padding-bottom: 4rem !important;
  }

  .pl-md-xxl,
.px-md-xxl {
    padding-left: 4rem !important;
  }

  .p-md-xxxl {
    padding: 4.5rem !important;
  }

  .pt-md-xxxl,
.py-md-xxxl {
    padding-top: 4.5rem !important;
  }

  .pr-md-xxxl,
.px-md-xxxl {
    padding-right: 4.5rem !important;
  }

  .pb-md-xxxl,
.py-md-xxxl {
    padding-bottom: 4.5rem !important;
  }

  .pl-md-xxxl,
.px-md-xxxl {
    padding-left: 4.5rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-nxs {
    margin: -0.25rem !important;
  }

  .mt-md-nxs,
.my-md-nxs {
    margin-top: -0.25rem !important;
  }

  .mr-md-nxs,
.mx-md-nxs {
    margin-right: -0.25rem !important;
  }

  .mb-md-nxs,
.my-md-nxs {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-nxs,
.mx-md-nxs {
    margin-left: -0.25rem !important;
  }

  .m-md-ns {
    margin: -0.5rem !important;
  }

  .mt-md-ns,
.my-md-ns {
    margin-top: -0.5rem !important;
  }

  .mr-md-ns,
.mx-md-ns {
    margin-right: -0.5rem !important;
  }

  .mb-md-ns,
.my-md-ns {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-ns,
.mx-md-ns {
    margin-left: -0.5rem !important;
  }

  .m-md-nm {
    margin: -1rem !important;
  }

  .mt-md-nm,
.my-md-nm {
    margin-top: -1rem !important;
  }

  .mr-md-nm,
.mx-md-nm {
    margin-right: -1rem !important;
  }

  .mb-md-nm,
.my-md-nm {
    margin-bottom: -1rem !important;
  }

  .ml-md-nm,
.mx-md-nm {
    margin-left: -1rem !important;
  }

  .m-md-nml {
    margin: -1.5rem !important;
  }

  .mt-md-nml,
.my-md-nml {
    margin-top: -1.5rem !important;
  }

  .mr-md-nml,
.mx-md-nml {
    margin-right: -1.5rem !important;
  }

  .mb-md-nml,
.my-md-nml {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-nml,
.mx-md-nml {
    margin-left: -1.5rem !important;
  }

  .m-md-nl {
    margin: -2rem !important;
  }

  .mt-md-nl,
.my-md-nl {
    margin-top: -2rem !important;
  }

  .mr-md-nl,
.mx-md-nl {
    margin-right: -2rem !important;
  }

  .mb-md-nl,
.my-md-nl {
    margin-bottom: -2rem !important;
  }

  .ml-md-nl,
.mx-md-nl {
    margin-left: -2rem !important;
  }

  .m-md-nxl {
    margin: -3rem !important;
  }

  .mt-md-nxl,
.my-md-nxl {
    margin-top: -3rem !important;
  }

  .mr-md-nxl,
.mx-md-nxl {
    margin-right: -3rem !important;
  }

  .mb-md-nxl,
.my-md-nxl {
    margin-bottom: -3rem !important;
  }

  .ml-md-nxl,
.mx-md-nxl {
    margin-left: -3rem !important;
  }

  .m-md-nxxl {
    margin: -4rem !important;
  }

  .mt-md-nxxl,
.my-md-nxxl {
    margin-top: -4rem !important;
  }

  .mr-md-nxxl,
.mx-md-nxxl {
    margin-right: -4rem !important;
  }

  .mb-md-nxxl,
.my-md-nxxl {
    margin-bottom: -4rem !important;
  }

  .ml-md-nxxl,
.mx-md-nxxl {
    margin-left: -4rem !important;
  }

  .m-md-nxxxl {
    margin: -4.5rem !important;
  }

  .mt-md-nxxxl,
.my-md-nxxxl {
    margin-top: -4.5rem !important;
  }

  .mr-md-nxxxl,
.mx-md-nxxxl {
    margin-right: -4.5rem !important;
  }

  .mb-md-nxxxl,
.my-md-nxxxl {
    margin-bottom: -4.5rem !important;
  }

  .ml-md-nxxxl,
.mx-md-nxxxl {
    margin-left: -4.5rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .m-lg-xs {
    margin: 0.25rem !important;
  }

  .mt-lg-xs,
.my-lg-xs {
    margin-top: 0.25rem !important;
  }

  .mr-lg-xs,
.mx-lg-xs {
    margin-right: 0.25rem !important;
  }

  .mb-lg-xs,
.my-lg-xs {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-xs,
.mx-lg-xs {
    margin-left: 0.25rem !important;
  }

  .m-lg-s {
    margin: 0.5rem !important;
  }

  .mt-lg-s,
.my-lg-s {
    margin-top: 0.5rem !important;
  }

  .mr-lg-s,
.mx-lg-s {
    margin-right: 0.5rem !important;
  }

  .mb-lg-s,
.my-lg-s {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-s,
.mx-lg-s {
    margin-left: 0.5rem !important;
  }

  .m-lg-m {
    margin: 1rem !important;
  }

  .mt-lg-m,
.my-lg-m {
    margin-top: 1rem !important;
  }

  .mr-lg-m,
.mx-lg-m {
    margin-right: 1rem !important;
  }

  .mb-lg-m,
.my-lg-m {
    margin-bottom: 1rem !important;
  }

  .ml-lg-m,
.mx-lg-m {
    margin-left: 1rem !important;
  }

  .m-lg-ml {
    margin: 1.5rem !important;
  }

  .mt-lg-ml,
.my-lg-ml {
    margin-top: 1.5rem !important;
  }

  .mr-lg-ml,
.mx-lg-ml {
    margin-right: 1.5rem !important;
  }

  .mb-lg-ml,
.my-lg-ml {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-ml,
.mx-lg-ml {
    margin-left: 1.5rem !important;
  }

  .m-lg-l {
    margin: 2rem !important;
  }

  .mt-lg-l,
.my-lg-l {
    margin-top: 2rem !important;
  }

  .mr-lg-l,
.mx-lg-l {
    margin-right: 2rem !important;
  }

  .mb-lg-l,
.my-lg-l {
    margin-bottom: 2rem !important;
  }

  .ml-lg-l,
.mx-lg-l {
    margin-left: 2rem !important;
  }

  .m-lg-xl {
    margin: 3rem !important;
  }

  .mt-lg-xl,
.my-lg-xl {
    margin-top: 3rem !important;
  }

  .mr-lg-xl,
.mx-lg-xl {
    margin-right: 3rem !important;
  }

  .mb-lg-xl,
.my-lg-xl {
    margin-bottom: 3rem !important;
  }

  .ml-lg-xl,
.mx-lg-xl {
    margin-left: 3rem !important;
  }

  .m-lg-xxl {
    margin: 4rem !important;
  }

  .mt-lg-xxl,
.my-lg-xxl {
    margin-top: 4rem !important;
  }

  .mr-lg-xxl,
.mx-lg-xxl {
    margin-right: 4rem !important;
  }

  .mb-lg-xxl,
.my-lg-xxl {
    margin-bottom: 4rem !important;
  }

  .ml-lg-xxl,
.mx-lg-xxl {
    margin-left: 4rem !important;
  }

  .m-lg-xxxl {
    margin: 4.5rem !important;
  }

  .mt-lg-xxxl,
.my-lg-xxxl {
    margin-top: 4.5rem !important;
  }

  .mr-lg-xxxl,
.mx-lg-xxxl {
    margin-right: 4.5rem !important;
  }

  .mb-lg-xxxl,
.my-lg-xxxl {
    margin-bottom: 4.5rem !important;
  }

  .ml-lg-xxxl,
.mx-lg-xxxl {
    margin-left: 4.5rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .p-lg-xs {
    padding: 0.25rem !important;
  }

  .pt-lg-xs,
.py-lg-xs {
    padding-top: 0.25rem !important;
  }

  .pr-lg-xs,
.px-lg-xs {
    padding-right: 0.25rem !important;
  }

  .pb-lg-xs,
.py-lg-xs {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-xs,
.px-lg-xs {
    padding-left: 0.25rem !important;
  }

  .p-lg-s {
    padding: 0.5rem !important;
  }

  .pt-lg-s,
.py-lg-s {
    padding-top: 0.5rem !important;
  }

  .pr-lg-s,
.px-lg-s {
    padding-right: 0.5rem !important;
  }

  .pb-lg-s,
.py-lg-s {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-s,
.px-lg-s {
    padding-left: 0.5rem !important;
  }

  .p-lg-m {
    padding: 1rem !important;
  }

  .pt-lg-m,
.py-lg-m {
    padding-top: 1rem !important;
  }

  .pr-lg-m,
.px-lg-m {
    padding-right: 1rem !important;
  }

  .pb-lg-m,
.py-lg-m {
    padding-bottom: 1rem !important;
  }

  .pl-lg-m,
.px-lg-m {
    padding-left: 1rem !important;
  }

  .p-lg-ml {
    padding: 1.5rem !important;
  }

  .pt-lg-ml,
.py-lg-ml {
    padding-top: 1.5rem !important;
  }

  .pr-lg-ml,
.px-lg-ml {
    padding-right: 1.5rem !important;
  }

  .pb-lg-ml,
.py-lg-ml {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-ml,
.px-lg-ml {
    padding-left: 1.5rem !important;
  }

  .p-lg-l {
    padding: 2rem !important;
  }

  .pt-lg-l,
.py-lg-l {
    padding-top: 2rem !important;
  }

  .pr-lg-l,
.px-lg-l {
    padding-right: 2rem !important;
  }

  .pb-lg-l,
.py-lg-l {
    padding-bottom: 2rem !important;
  }

  .pl-lg-l,
.px-lg-l {
    padding-left: 2rem !important;
  }

  .p-lg-xl {
    padding: 3rem !important;
  }

  .pt-lg-xl,
.py-lg-xl {
    padding-top: 3rem !important;
  }

  .pr-lg-xl,
.px-lg-xl {
    padding-right: 3rem !important;
  }

  .pb-lg-xl,
.py-lg-xl {
    padding-bottom: 3rem !important;
  }

  .pl-lg-xl,
.px-lg-xl {
    padding-left: 3rem !important;
  }

  .p-lg-xxl {
    padding: 4rem !important;
  }

  .pt-lg-xxl,
.py-lg-xxl {
    padding-top: 4rem !important;
  }

  .pr-lg-xxl,
.px-lg-xxl {
    padding-right: 4rem !important;
  }

  .pb-lg-xxl,
.py-lg-xxl {
    padding-bottom: 4rem !important;
  }

  .pl-lg-xxl,
.px-lg-xxl {
    padding-left: 4rem !important;
  }

  .p-lg-xxxl {
    padding: 4.5rem !important;
  }

  .pt-lg-xxxl,
.py-lg-xxxl {
    padding-top: 4.5rem !important;
  }

  .pr-lg-xxxl,
.px-lg-xxxl {
    padding-right: 4.5rem !important;
  }

  .pb-lg-xxxl,
.py-lg-xxxl {
    padding-bottom: 4.5rem !important;
  }

  .pl-lg-xxxl,
.px-lg-xxxl {
    padding-left: 4.5rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-nxs {
    margin: -0.25rem !important;
  }

  .mt-lg-nxs,
.my-lg-nxs {
    margin-top: -0.25rem !important;
  }

  .mr-lg-nxs,
.mx-lg-nxs {
    margin-right: -0.25rem !important;
  }

  .mb-lg-nxs,
.my-lg-nxs {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-nxs,
.mx-lg-nxs {
    margin-left: -0.25rem !important;
  }

  .m-lg-ns {
    margin: -0.5rem !important;
  }

  .mt-lg-ns,
.my-lg-ns {
    margin-top: -0.5rem !important;
  }

  .mr-lg-ns,
.mx-lg-ns {
    margin-right: -0.5rem !important;
  }

  .mb-lg-ns,
.my-lg-ns {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-ns,
.mx-lg-ns {
    margin-left: -0.5rem !important;
  }

  .m-lg-nm {
    margin: -1rem !important;
  }

  .mt-lg-nm,
.my-lg-nm {
    margin-top: -1rem !important;
  }

  .mr-lg-nm,
.mx-lg-nm {
    margin-right: -1rem !important;
  }

  .mb-lg-nm,
.my-lg-nm {
    margin-bottom: -1rem !important;
  }

  .ml-lg-nm,
.mx-lg-nm {
    margin-left: -1rem !important;
  }

  .m-lg-nml {
    margin: -1.5rem !important;
  }

  .mt-lg-nml,
.my-lg-nml {
    margin-top: -1.5rem !important;
  }

  .mr-lg-nml,
.mx-lg-nml {
    margin-right: -1.5rem !important;
  }

  .mb-lg-nml,
.my-lg-nml {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-nml,
.mx-lg-nml {
    margin-left: -1.5rem !important;
  }

  .m-lg-nl {
    margin: -2rem !important;
  }

  .mt-lg-nl,
.my-lg-nl {
    margin-top: -2rem !important;
  }

  .mr-lg-nl,
.mx-lg-nl {
    margin-right: -2rem !important;
  }

  .mb-lg-nl,
.my-lg-nl {
    margin-bottom: -2rem !important;
  }

  .ml-lg-nl,
.mx-lg-nl {
    margin-left: -2rem !important;
  }

  .m-lg-nxl {
    margin: -3rem !important;
  }

  .mt-lg-nxl,
.my-lg-nxl {
    margin-top: -3rem !important;
  }

  .mr-lg-nxl,
.mx-lg-nxl {
    margin-right: -3rem !important;
  }

  .mb-lg-nxl,
.my-lg-nxl {
    margin-bottom: -3rem !important;
  }

  .ml-lg-nxl,
.mx-lg-nxl {
    margin-left: -3rem !important;
  }

  .m-lg-nxxl {
    margin: -4rem !important;
  }

  .mt-lg-nxxl,
.my-lg-nxxl {
    margin-top: -4rem !important;
  }

  .mr-lg-nxxl,
.mx-lg-nxxl {
    margin-right: -4rem !important;
  }

  .mb-lg-nxxl,
.my-lg-nxxl {
    margin-bottom: -4rem !important;
  }

  .ml-lg-nxxl,
.mx-lg-nxxl {
    margin-left: -4rem !important;
  }

  .m-lg-nxxxl {
    margin: -4.5rem !important;
  }

  .mt-lg-nxxxl,
.my-lg-nxxxl {
    margin-top: -4.5rem !important;
  }

  .mr-lg-nxxxl,
.mx-lg-nxxxl {
    margin-right: -4.5rem !important;
  }

  .mb-lg-nxxxl,
.my-lg-nxxxl {
    margin-bottom: -4.5rem !important;
  }

  .ml-lg-nxxxl,
.mx-lg-nxxxl {
    margin-left: -4.5rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .m-xl-xs {
    margin: 0.25rem !important;
  }

  .mt-xl-xs,
.my-xl-xs {
    margin-top: 0.25rem !important;
  }

  .mr-xl-xs,
.mx-xl-xs {
    margin-right: 0.25rem !important;
  }

  .mb-xl-xs,
.my-xl-xs {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-xs,
.mx-xl-xs {
    margin-left: 0.25rem !important;
  }

  .m-xl-s {
    margin: 0.5rem !important;
  }

  .mt-xl-s,
.my-xl-s {
    margin-top: 0.5rem !important;
  }

  .mr-xl-s,
.mx-xl-s {
    margin-right: 0.5rem !important;
  }

  .mb-xl-s,
.my-xl-s {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-s,
.mx-xl-s {
    margin-left: 0.5rem !important;
  }

  .m-xl-m {
    margin: 1rem !important;
  }

  .mt-xl-m,
.my-xl-m {
    margin-top: 1rem !important;
  }

  .mr-xl-m,
.mx-xl-m {
    margin-right: 1rem !important;
  }

  .mb-xl-m,
.my-xl-m {
    margin-bottom: 1rem !important;
  }

  .ml-xl-m,
.mx-xl-m {
    margin-left: 1rem !important;
  }

  .m-xl-ml {
    margin: 1.5rem !important;
  }

  .mt-xl-ml,
.my-xl-ml {
    margin-top: 1.5rem !important;
  }

  .mr-xl-ml,
.mx-xl-ml {
    margin-right: 1.5rem !important;
  }

  .mb-xl-ml,
.my-xl-ml {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-ml,
.mx-xl-ml {
    margin-left: 1.5rem !important;
  }

  .m-xl-l {
    margin: 2rem !important;
  }

  .mt-xl-l,
.my-xl-l {
    margin-top: 2rem !important;
  }

  .mr-xl-l,
.mx-xl-l {
    margin-right: 2rem !important;
  }

  .mb-xl-l,
.my-xl-l {
    margin-bottom: 2rem !important;
  }

  .ml-xl-l,
.mx-xl-l {
    margin-left: 2rem !important;
  }

  .m-xl-xl {
    margin: 3rem !important;
  }

  .mt-xl-xl,
.my-xl-xl {
    margin-top: 3rem !important;
  }

  .mr-xl-xl,
.mx-xl-xl {
    margin-right: 3rem !important;
  }

  .mb-xl-xl,
.my-xl-xl {
    margin-bottom: 3rem !important;
  }

  .ml-xl-xl,
.mx-xl-xl {
    margin-left: 3rem !important;
  }

  .m-xl-xxl {
    margin: 4rem !important;
  }

  .mt-xl-xxl,
.my-xl-xxl {
    margin-top: 4rem !important;
  }

  .mr-xl-xxl,
.mx-xl-xxl {
    margin-right: 4rem !important;
  }

  .mb-xl-xxl,
.my-xl-xxl {
    margin-bottom: 4rem !important;
  }

  .ml-xl-xxl,
.mx-xl-xxl {
    margin-left: 4rem !important;
  }

  .m-xl-xxxl {
    margin: 4.5rem !important;
  }

  .mt-xl-xxxl,
.my-xl-xxxl {
    margin-top: 4.5rem !important;
  }

  .mr-xl-xxxl,
.mx-xl-xxxl {
    margin-right: 4.5rem !important;
  }

  .mb-xl-xxxl,
.my-xl-xxxl {
    margin-bottom: 4.5rem !important;
  }

  .ml-xl-xxxl,
.mx-xl-xxxl {
    margin-left: 4.5rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .p-xl-xs {
    padding: 0.25rem !important;
  }

  .pt-xl-xs,
.py-xl-xs {
    padding-top: 0.25rem !important;
  }

  .pr-xl-xs,
.px-xl-xs {
    padding-right: 0.25rem !important;
  }

  .pb-xl-xs,
.py-xl-xs {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-xs,
.px-xl-xs {
    padding-left: 0.25rem !important;
  }

  .p-xl-s {
    padding: 0.5rem !important;
  }

  .pt-xl-s,
.py-xl-s {
    padding-top: 0.5rem !important;
  }

  .pr-xl-s,
.px-xl-s {
    padding-right: 0.5rem !important;
  }

  .pb-xl-s,
.py-xl-s {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-s,
.px-xl-s {
    padding-left: 0.5rem !important;
  }

  .p-xl-m {
    padding: 1rem !important;
  }

  .pt-xl-m,
.py-xl-m {
    padding-top: 1rem !important;
  }

  .pr-xl-m,
.px-xl-m {
    padding-right: 1rem !important;
  }

  .pb-xl-m,
.py-xl-m {
    padding-bottom: 1rem !important;
  }

  .pl-xl-m,
.px-xl-m {
    padding-left: 1rem !important;
  }

  .p-xl-ml {
    padding: 1.5rem !important;
  }

  .pt-xl-ml,
.py-xl-ml {
    padding-top: 1.5rem !important;
  }

  .pr-xl-ml,
.px-xl-ml {
    padding-right: 1.5rem !important;
  }

  .pb-xl-ml,
.py-xl-ml {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-ml,
.px-xl-ml {
    padding-left: 1.5rem !important;
  }

  .p-xl-l {
    padding: 2rem !important;
  }

  .pt-xl-l,
.py-xl-l {
    padding-top: 2rem !important;
  }

  .pr-xl-l,
.px-xl-l {
    padding-right: 2rem !important;
  }

  .pb-xl-l,
.py-xl-l {
    padding-bottom: 2rem !important;
  }

  .pl-xl-l,
.px-xl-l {
    padding-left: 2rem !important;
  }

  .p-xl-xl {
    padding: 3rem !important;
  }

  .pt-xl-xl,
.py-xl-xl {
    padding-top: 3rem !important;
  }

  .pr-xl-xl,
.px-xl-xl {
    padding-right: 3rem !important;
  }

  .pb-xl-xl,
.py-xl-xl {
    padding-bottom: 3rem !important;
  }

  .pl-xl-xl,
.px-xl-xl {
    padding-left: 3rem !important;
  }

  .p-xl-xxl {
    padding: 4rem !important;
  }

  .pt-xl-xxl,
.py-xl-xxl {
    padding-top: 4rem !important;
  }

  .pr-xl-xxl,
.px-xl-xxl {
    padding-right: 4rem !important;
  }

  .pb-xl-xxl,
.py-xl-xxl {
    padding-bottom: 4rem !important;
  }

  .pl-xl-xxl,
.px-xl-xxl {
    padding-left: 4rem !important;
  }

  .p-xl-xxxl {
    padding: 4.5rem !important;
  }

  .pt-xl-xxxl,
.py-xl-xxxl {
    padding-top: 4.5rem !important;
  }

  .pr-xl-xxxl,
.px-xl-xxxl {
    padding-right: 4.5rem !important;
  }

  .pb-xl-xxxl,
.py-xl-xxxl {
    padding-bottom: 4.5rem !important;
  }

  .pl-xl-xxxl,
.px-xl-xxxl {
    padding-left: 4.5rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-nxs {
    margin: -0.25rem !important;
  }

  .mt-xl-nxs,
.my-xl-nxs {
    margin-top: -0.25rem !important;
  }

  .mr-xl-nxs,
.mx-xl-nxs {
    margin-right: -0.25rem !important;
  }

  .mb-xl-nxs,
.my-xl-nxs {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-nxs,
.mx-xl-nxs {
    margin-left: -0.25rem !important;
  }

  .m-xl-ns {
    margin: -0.5rem !important;
  }

  .mt-xl-ns,
.my-xl-ns {
    margin-top: -0.5rem !important;
  }

  .mr-xl-ns,
.mx-xl-ns {
    margin-right: -0.5rem !important;
  }

  .mb-xl-ns,
.my-xl-ns {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-ns,
.mx-xl-ns {
    margin-left: -0.5rem !important;
  }

  .m-xl-nm {
    margin: -1rem !important;
  }

  .mt-xl-nm,
.my-xl-nm {
    margin-top: -1rem !important;
  }

  .mr-xl-nm,
.mx-xl-nm {
    margin-right: -1rem !important;
  }

  .mb-xl-nm,
.my-xl-nm {
    margin-bottom: -1rem !important;
  }

  .ml-xl-nm,
.mx-xl-nm {
    margin-left: -1rem !important;
  }

  .m-xl-nml {
    margin: -1.5rem !important;
  }

  .mt-xl-nml,
.my-xl-nml {
    margin-top: -1.5rem !important;
  }

  .mr-xl-nml,
.mx-xl-nml {
    margin-right: -1.5rem !important;
  }

  .mb-xl-nml,
.my-xl-nml {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-nml,
.mx-xl-nml {
    margin-left: -1.5rem !important;
  }

  .m-xl-nl {
    margin: -2rem !important;
  }

  .mt-xl-nl,
.my-xl-nl {
    margin-top: -2rem !important;
  }

  .mr-xl-nl,
.mx-xl-nl {
    margin-right: -2rem !important;
  }

  .mb-xl-nl,
.my-xl-nl {
    margin-bottom: -2rem !important;
  }

  .ml-xl-nl,
.mx-xl-nl {
    margin-left: -2rem !important;
  }

  .m-xl-nxl {
    margin: -3rem !important;
  }

  .mt-xl-nxl,
.my-xl-nxl {
    margin-top: -3rem !important;
  }

  .mr-xl-nxl,
.mx-xl-nxl {
    margin-right: -3rem !important;
  }

  .mb-xl-nxl,
.my-xl-nxl {
    margin-bottom: -3rem !important;
  }

  .ml-xl-nxl,
.mx-xl-nxl {
    margin-left: -3rem !important;
  }

  .m-xl-nxxl {
    margin: -4rem !important;
  }

  .mt-xl-nxxl,
.my-xl-nxxl {
    margin-top: -4rem !important;
  }

  .mr-xl-nxxl,
.mx-xl-nxxl {
    margin-right: -4rem !important;
  }

  .mb-xl-nxxl,
.my-xl-nxxl {
    margin-bottom: -4rem !important;
  }

  .ml-xl-nxxl,
.mx-xl-nxxl {
    margin-left: -4rem !important;
  }

  .m-xl-nxxxl {
    margin: -4.5rem !important;
  }

  .mt-xl-nxxxl,
.my-xl-nxxxl {
    margin-top: -4.5rem !important;
  }

  .mr-xl-nxxxl,
.mx-xl-nxxxl {
    margin-right: -4.5rem !important;
  }

  .mb-xl-nxxxl,
.my-xl-nxxxl {
    margin-bottom: -4.5rem !important;
  }

  .ml-xl-nxxxl,
.mx-xl-nxxxl {
    margin-left: -4.5rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #0170bc !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #014370 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-digital-black {
  color: #333 !important;
}

a.text-digital-black:hover, a.text-digital-black:focus {
  color: #0d0d0d !important;
}

.text-gray-60 {
  color: #505050 !important;
}

a.text-gray-60:hover, a.text-gray-60:focus {
  color: #2a2a2a !important;
}

.text-gray-40 {
  color: #939496 !important;
}

a.text-gray-40:hover, a.text-gray-40:focus {
  color: #6d6e70 !important;
}

.text-gray-15 {
  color: #e4e4e4 !important;
}

a.text-gray-15:hover, a.text-gray-15:focus {
  color: #bebebe !important;
}

.text-gray-05 {
  color: #e4e4e4 !important;
}

a.text-gray-05:hover, a.text-gray-05:focus {
  color: #bebebe !important;
}

.text-gray-01 {
  color: #f8f8f8 !important;
}

a.text-gray-01:hover, a.text-gray-01:focus {
  color: #d2d2d2 !important;
}

.text-white {
  color: #fff !important;
}

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important;
}

.text-digital-blue {
  color: #0170bc !important;
}

a.text-digital-blue:hover, a.text-digital-blue:focus {
  color: #014370 !important;
}

.text-digital-blue-10 {
  color: #e7f1f8 !important;
}

a.text-digital-blue-10:hover, a.text-digital-blue-10:focus {
  color: #accee7 !important;
}

.text-cigna-blue-dark {
  color: #004986 !important;
}

a.text-cigna-blue-dark:hover, a.text-cigna-blue-dark:focus {
  color: #001f3a !important;
}

.text-cigna-blue-light {
  color: #00a9e0 !important;
}

a.text-cigna-blue-light:hover, a.text-cigna-blue-light:focus {
  color: #006f94 !important;
}

.text-digital-green {
  color: #118503 !important;
}

a.text-digital-green:hover, a.text-digital-green:focus {
  color: #073a01 !important;
}

.text-digital-green-10 {
  color: #e7f2e5 !important;
}

a.text-digital-green-10:hover, a.text-digital-green-10:focus {
  color: #b8d9b2 !important;
}

.text-cigna-orange-logo {
  color: #f68621 !important;
}

a.text-cigna-orange-logo:hover, a.text-cigna-orange-logo:focus {
  color: #c36008 !important;
}

.text-cigna-orange-dark {
  color: #e35205 !important;
}

a.text-cigna-orange-dark:hover, a.text-cigna-orange-dark:focus {
  color: #983703 !important;
}

.text-cigna-orange-light-20 {
  color: #fcf0d6 !important;
}

a.text-cigna-orange-light-20:hover, a.text-cigna-orange-light-20:focus {
  color: #f7d68f !important;
}

.text-digital-red {
  color: #c00 !important;
}

a.text-digital-red:hover, a.text-digital-red:focus {
  color: maroon !important;
}

.text-digital-red-10 {
  color: #ffe3e2 !important;
}

a.text-digital-red-10:hover, a.text-digital-red-10:focus {
  color: #ff9996 !important;
}

.text-digital-purple {
  color: #800080 !important;
}

a.text-digital-purple:hover, a.text-digital-purple:focus {
  color: #340034 !important;
}

.text-body {
  color: #333 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e4e4e4;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #e4e4e4;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
a {
  background-image: linear-gradient(rgba(51, 51, 51, 0.3), rgba(51, 51, 51, 0.3));
  background-position: 0 88%;
  background-repeat: repeat-x;
  background-size: 1px 1px;
  padding-bottom: 4px;
}
a:hover {
  text-decoration: none;
}

/*!
	Copyright (C) 2011-2017 Hoefler & Co.
	This software is the property of Hoefler & Co. (H&Co).
	Your right to access and use this software is subject to the
	applicable License Agreement, or Terms of Service, that exists
	between you and H&Co. If no such agreement exists, you may not
	access or use this software for any purpose.
	This software may only be hosted at the locations specified in
	the applicable License Agreement or Terms of Service, and only
	for the purposes expressly set forth therein. You may not copy,
	modify, convert, create derivative works from or distribute this
	software in any way, or make it accessible to any third party,
	without first obtaining the written permission of H&Co.
	For more information, please visit us at http://typography.com.
	53236-109108-20170615
*/
/* stylelint-disable-next-line function-url-quotes */
/* stylelint-disable-next-line function-url-quotes */
@font-face {
  font-display: swap;
  font-family: "HCo Gotham SSm";
  font-style: normal;
  font-weight: 400;
  src: url("../../src/fonts/gotham/7F9E03A0F57418CCA.woff2") format("woff2"), url("../../src/fonts/gotham/7F9E03A0F57418CCA.woff") format("woff");
}
/* stylelint-disable-next-line function-url-quotes */
/* stylelint-disable-next-line function-url-quotes */
@font-face {
  font-display: swap;
  font-family: "HCo Gotham SSm";
  font-style: italic;
  font-weight: 400;
  src: url("../../src/fonts/gotham/4682495EFF42FECFA.woff2") format("woff2"), url("../../src/fonts/gotham/4682495EFF42FECFA.woff") format("woff");
}
/* stylelint-disable-next-line function-url-quotes */
/* stylelint-disable-next-line function-url-quotes */
@font-face {
  font-display: swap;
  font-family: "HCo Gotham SSm";
  font-style: normal;
  font-weight: 500;
  src: url("../../src/fonts/gotham/B549DC717A82215E5.woff2") format("woff2"), url("../../src/fonts/gotham/B549DC717A82215E5.woff") format("woff");
}
/* stylelint-disable-next-line function-url-quotes */
/* stylelint-disable-next-line function-url-quotes */
@font-face {
  font-display: swap;
  font-family: "HCo Gotham SSm";
  font-style: italic;
  font-weight: 500;
  src: url("../../src/fonts/gotham/8A759DCC601661D38.woff2") format("woff2"), url("../../src/fonts/gotham/8A759DCC601661D38.woff") format("woff");
}
/* stylelint-disable-next-line function-url-quotes */
/* stylelint-disable-next-line function-url-quotes */
@font-face {
  font-display: swap;
  font-family: "HCo Gotham SSm";
  font-style: normal;
  font-weight: 700;
  src: url("../../src/fonts/gotham/CA3CAD619E0A246D7.woff2") format("woff2"), url("../../src/fonts/gotham/CA3CAD619E0A246D7.woff") format("woff");
}
/* stylelint-disable-next-line function-url-quotes */
/* stylelint-disable-next-line function-url-quotes */
@font-face {
  font-display: swap;
  font-family: "HCo Gotham SSm";
  font-style: italic;
  font-weight: 700;
  src: url("../../src/fonts/gotham/BD64AA1D63D0D4DA1.woff2") format("woff2"), url("../../src/fonts/gotham/BD64AA1D63D0D4DA1.woff") format("woff");
}
/* stylelint-disable-next-line function-url-quotes */
/* stylelint-disable-next-line function-url-quotes */
@font-face {
  font-display: swap;
  font-family: "HCo Gotham SSm";
  font-style: normal;
  font-weight: 800;
  src: url("../../src/fonts/gotham/2E7E8DF35E4DCF79F.woff2") format("woff2"), url("../../src/fonts/gotham/2E7E8DF35E4DCF79F.woff") format("woff");
}
/* stylelint-disable-next-line function-url-quotes */
/* stylelint-disable-next-line function-url-quotes */
@font-face {
  font-display: swap;
  font-family: "HCo Gotham SSm";
  font-style: normal;
  font-weight: 800;
  src: url("../../src/fonts/gotham/EE8C4CE5E730CFD28.woff2") format("woff2"), url("../../src/fonts/gotham/EE8C4CE5E730CFD28.woff") format("woff");
}
/* stylelint-disable-next-line function-url-quotes */
/* stylelint-disable-next-line function-url-quotes */
@font-face {
  font-display: swap;
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 400;
  src: url("../../src/fonts/merriweather/merriweather-v19-latin-italic.woff2") format("woff2"), url("../../src/fonts/merriweather/merriweather-v19-latin-italic.woff") format("woff");
}
/* stylelint-disable-next-line function-url-quotes */
/* stylelint-disable-next-line function-url-quotes */
@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../../src/fonts/open-sans/open-sans-v15-latin-regular.woff2") format("woff2"), url("../../src/fonts/open-sans/open-sans-v15-latin-regular.woff") format("woff");
}
/* stylelint-disable-next-line function-url-quotes */
/* stylelint-disable-next-line function-url-quotes */
@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: url("../../src/fonts/open-sans/open-sans-v15-latin-italic.woff2") format("woff2"), url("../../src/fonts/open-sans/open-sans-v15-latin-italic.woff") format("woff");
}
/* stylelint-disable-next-line function-url-quotes */
/* stylelint-disable-next-line function-url-quotes */
@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../../src/fonts/open-sans/open-sans-v15-latin-600.woff2") format("woff2"), url("../../src/fonts/open-sans/open-sans-v15-latin-600.woff") format("woff");
}
/* stylelint-disable-next-line function-url-quotes */
/* stylelint-disable-next-line function-url-quotes */
@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: url("../../src/fonts/open-sans/open-sans-v15-latin-600italic.woff2") format("woff2"), url("../../src/fonts/open-sans/open-sans-v15-latin-600italic.woff") format("woff");
}
/* stylelint-disable-next-line function-url-quotes */
/* stylelint-disable-next-line function-url-quotes */
@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../../src/fonts/open-sans/open-sans-v15-latin-700.woff2") format("woff2"), url("../../src/fonts/open-sans/open-sans-v15-latin-700.woff") format("woff");
}
/* stylelint-disable-next-line function-url-quotes */
/* stylelint-disable-next-line function-url-quotes */
@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: url("../../src/fonts/open-sans/open-sans-v15-latin-700italic.woff2") format("woff2"), url("../../src/fonts/open-sans/open-sans-v15-latin-700italic.woff") format("woff");
}
.bg-digital-black {
  background-color: #333 !important;
}

.fill-digital-black {
  fill: #333 !important;
}

.text-digital-black {
  color: #333 !important;
}

.bg-gray-60 {
  background-color: #505050 !important;
}

.fill-gray-60 {
  fill: #505050 !important;
}

.text-gray-60 {
  color: #505050 !important;
}

.bg-gray-40 {
  background-color: #939496 !important;
}

.fill-gray-40 {
  fill: #939496 !important;
}

.text-gray-40 {
  color: #939496 !important;
}

.bg-gray-15 {
  background-color: #e4e4e4 !important;
}

.fill-gray-15 {
  fill: #e4e4e4 !important;
}

.text-gray-15 {
  color: #e4e4e4 !important;
}

.bg-gray-05 {
  background-color: #e4e4e4 !important;
}

.fill-gray-05 {
  fill: #e4e4e4 !important;
}

.text-gray-05 {
  color: #e4e4e4 !important;
}

.bg-gray-01 {
  background-color: #f8f8f8 !important;
}

.fill-gray-01 {
  fill: #f8f8f8 !important;
}

.text-gray-01 {
  color: #f8f8f8 !important;
}

.bg-white {
  background-color: #fff !important;
}

.fill-white {
  fill: #fff !important;
}

.text-white {
  color: #fff !important;
}

.bg-digital-blue {
  background-color: #0170bc !important;
}

.fill-digital-blue {
  fill: #0170bc !important;
}

.text-digital-blue {
  color: #0170bc !important;
}

.bg-digital-blue-10 {
  background-color: #e7f1f8 !important;
}

.fill-digital-blue-10 {
  fill: #e7f1f8 !important;
}

.text-digital-blue-10 {
  color: #e7f1f8 !important;
}

.bg-cigna-blue-dark {
  background-color: #004986 !important;
}

.fill-cigna-blue-dark {
  fill: #004986 !important;
}

.text-cigna-blue-dark {
  color: #004986 !important;
}

.bg-cigna-blue-light {
  background-color: #00a9e0 !important;
}

.fill-cigna-blue-light {
  fill: #00a9e0 !important;
}

.text-cigna-blue-light {
  color: #00a9e0 !important;
}

.bg-digital-green {
  background-color: #118503 !important;
}

.fill-digital-green {
  fill: #118503 !important;
}

.text-digital-green {
  color: #118503 !important;
}

.bg-digital-green-10 {
  background-color: #e7f2e5 !important;
}

.fill-digital-green-10 {
  fill: #e7f2e5 !important;
}

.text-digital-green-10 {
  color: #e7f2e5 !important;
}

.bg-cigna-orange-logo {
  background-color: #f68621 !important;
}

.fill-cigna-orange-logo {
  fill: #f68621 !important;
}

.text-cigna-orange-logo {
  color: #f68621 !important;
}

.bg-cigna-orange-dark {
  background-color: #e35205 !important;
}

.fill-cigna-orange-dark {
  fill: #e35205 !important;
}

.text-cigna-orange-dark {
  color: #e35205 !important;
}

.bg-cigna-orange-light-20 {
  background-color: #fcf0d6 !important;
}

.fill-cigna-orange-light-20 {
  fill: #fcf0d6 !important;
}

.text-cigna-orange-light-20 {
  color: #fcf0d6 !important;
}

.bg-digital-red {
  background-color: #c00 !important;
}

.fill-digital-red {
  fill: #c00 !important;
}

.text-digital-red {
  color: #c00 !important;
}

.bg-digital-red-10 {
  background-color: #ffe3e2 !important;
}

.fill-digital-red-10 {
  fill: #ffe3e2 !important;
}

.text-digital-red-10 {
  color: #ffe3e2 !important;
}

.bg-digital-purple {
  background-color: #800080 !important;
}

.fill-digital-purple {
  fill: #800080 !important;
}

.text-digital-purple {
  color: #800080 !important;
}

.icon-s {
  height: 0.5rem !important;
  width: 0.5rem !important;
}

.icon-m {
  height: 1rem !important;
  width: 1rem !important;
}

.icon-ml {
  height: 1.5rem !important;
  width: 1.5rem !important;
}

.icon-l {
  height: 2rem !important;
  width: 2rem !important;
}

.icon-xl {
  height: 3rem !important;
  width: 3rem !important;
}

.icon-xxl {
  height: 4rem !important;
  width: 4rem !important;
}

.icon-xxxl {
  height: 4.5rem !important;
  width: 4.5rem !important;
}

body {
  /* stylelint-disable */
  /* stylelint-enable */
}
body::before {
  content: "xs";
  display: none;
}
@media (min-width: 576px) {
  body::before {
    content: "sm";
  }
}
@media (min-width: 768px) {
  body::before {
    content: "md";
  }
}
@media (min-width: 992px) {
  body::before {
    content: "lg";
  }
}
@media (min-width: 1200px) {
  body::before {
    content: "xl";
  }
}
body::after {
  display: none;
}
@media screen and (orientation: portrait) {
  body::after {
    content: "portrait";
  }
}
@media screen and (orientation: landscape) {
  body::after {
    content: "landscape";
  }
}

.rounded-xs {
  border-radius: 0.125rem !important;
}
.rounded-top-xs {
  border-top-left-radius: 0.125rem !important;
  border-top-right-radius: 0.125rem !important;
}
.rounded-right-xs {
  border-bottom-right-radius: 0.125rem !important;
  border-top-right-radius: 0.125rem !important;
}
.rounded-bottom-xs {
  border-bottom-left-radius: 0.125rem !important;
  border-bottom-right-radius: 0.125rem !important;
}
.rounded-left-xs {
  border-bottom-left-radius: 0.125rem !important;
  border-top-left-radius: 0.125rem !important;
}

.rounded-s {
  border-radius: 0.25rem !important;
}
.rounded-top-s {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.rounded-right-s {
  border-bottom-right-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.rounded-bottom-s {
  border-bottom-left-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.rounded-left-s {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.rounded-m {
  border-radius: 0.5rem !important;
}
.rounded-top-m {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}
.rounded-right-m {
  border-bottom-right-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}
.rounded-bottom-m {
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}
.rounded-left-m {
  border-bottom-left-radius: 0.5rem !important;
  border-top-left-radius: 0.5rem !important;
}

.rounded-l {
  border-radius: 0.75rem !important;
}
.rounded-top-l {
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}
.rounded-right-l {
  border-bottom-right-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}
.rounded-bottom-l {
  border-bottom-left-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
}
.rounded-left-l {
  border-bottom-left-radius: 0.75rem !important;
  border-top-left-radius: 0.75rem !important;
}

.display-desktop,
.display-mobile,
.subhead-1,
.subhead-2,
.subhead-3,
.subhead-4,
h1,
h2,
h3,
h4 {
  font-family: "HCo Gotham SSm", "Verdana", "Geneva", sans-serif;
  font-weight: 500;
}

h1 > a,
h2 > a,
h3 > a,
h4 > a {
  background-image: none;
  background-position: 0% 89%;
  background-repeat: repeat-x;
  background-size: 1px 1px;
  color: #0170bc;
  cursor: pointer;
  font-weight: inherit;
  padding-bottom: 4px;
}
h1 > a:visited,
h2 > a:visited,
h3 > a:visited,
h4 > a:visited {
  color: #0170bc;
}
h1 > a:hover,
h2 > a:hover,
h3 > a:hover,
h4 > a:hover {
  background-image: linear-gradient(#004986, #004986);
  color: #004986;
}

.display,
.display-desktop,
.display-mobile {
  color: #118503;
  font-size: 2.25rem;
  letter-spacing: -0.25px;
  line-height: 1.25;
}

.display-desktop {
  font-size: 2.25rem;
}

.display-mobile {
  font-size: 2.25rem;
}

.promo-content-container .promo-subhead,
.subhead-1,
h1,
.h1 {
  font-size: 2.25rem;
  line-height: 1.25;
}

.subhead-2,
h2,
.h2 {
  font-size: 1.75rem;
  line-height: 1.2428571429;
}

.callout-side-heading, .callout-block-heading,
.subhead-3,
h3,
.h3 {
  font-size: 1.375rem;
  line-height: 1.2727272727;
}

.share-buttons-heading, .light-accordion-holder .accordion-title .panel-header,
.light-accordion-holder .accordion-title-single .panel-header,
.subhead-4,
h4,
.h4 {
  font-size: 1.125rem;
  line-height: 1.3333333333;
}

.subhead-5,
.body-text,
h5,
.h5 {
  font-size: 1rem;
  line-height: 1.5;
}

.subhead-5,
h5,
.h5 {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 700;
}

.lead-paragraph {
  font-size: 1.125rem;
  line-height: 1.7777777778;
}

.footer-text,
.small-text,
small,
.small {
  font-size: 0.875rem;
  line-height: 1.4285714286;
}

.micro-text,
.footnotes {
  color: #505050;
  font-size: 0.75rem;
  line-height: 1.416;
}
@media (max-width: 767.98px) {
  .micro-text a,
.footnotes a {
    word-wrap: break-word;
  }
}

.hero-floating-photo-meta-copy,
.hero-floating-photo-legal-copy,
.quotation.side .quote-text,
.quotation.blockquote .quote-text,
.accent-small,
.accent-medium,
.accent-large,
.accent-xlarge {
  color: #333;
  font-family: "Merriweather", serif;
  font-style: italic;
  font-weight: 400;
}

.hero-floating-photo-meta-copy,
.hero-floating-photo-legal-copy,
.accent-small {
  color: #505050;
  font-size: 0.875rem;
  line-height: 1.2428571429;
}

.accent-medium {
  font-size: 1rem;
  line-height: 1.5;
}

.quotation.side .quote-text,
.accent-large {
  font-size: 1.25rem;
  line-height: 1.6;
}

.quotation.blockquote .quote-text,
.accent-xlarge {
  font-size: 1.75rem;
  line-height: 1.4285714286;
}

.homepage-h1 {
  color: #333;
  font-size: 2rem;
  margin-bottom: 2rem;
}
@media (max-width: 767.98px) {
  .homepage-h1 {
    font-size: 1.75rem;
  }
}

.homepage-h2 {
  color: #118503;
  font-size: 2.25rem;
}
@media (max-width: 767.98px) {
  .homepage-h2 {
    font-size: 2rem;
  }
}

a,
a:not([href])[data-toggle=modal] {
  color: #0170bc;
  cursor: pointer;
}
a:focus,
a:not([href])[data-toggle=modal]:focus {
  background-color: #fcf0d6;
}

a:not([href])[data-toggle=modal]:active, a:not([href])[data-toggle=modal]:hover {
  background-image: linear-gradient(rgba(0, 73, 134, 0.3), rgba(0, 73, 134, 0.3));
  color: #004986;
  transition: color 0.2s;
}

a {
  font-weight: 600;
}
a:visited {
  color: #800080;
}
a:hover {
  background-image: linear-gradient(rgba(0, 73, 134, 0.3), rgba(0, 73, 134, 0.3));
  color: #004986;
  transition: color 0.2s;
}

.link-action .icon,
.link-external .icon,
.link-secure .icon {
  height: 1.125rem;
  width: 1.125rem;
  fill: currentColor;
  margin-bottom: 0.25rem;
  margin-left: 0.25rem;
}
.link-action .icon.action,
.link-external .icon.action,
.link-secure .icon.action {
  margin-left: 0.5rem;
}
small .link-action .icon, .small-text .link-action .icon, .small .link-action .icon,
small .link-external .icon,
.small-text .link-external .icon,
.small .link-external .icon,
small .link-secure .icon,
.small-text .link-secure .icon,
.small .link-secure .icon {
  height: 1rem;
  width: 1rem;
}

.link-action {
  background-image: none;
  background-position: inherit;
  background-repeat: inherit;
  background-size: inherit;
  color: #0170bc;
}
small .link-action .icon.action, .small-text .link-action .icon.action, .small .link-action .icon.action {
  margin-left: 6px;
}
.link-action:active, .link-action:hover {
  background-image: linear-gradient(rgba(0, 73, 134, 0.3), rgba(0, 73, 134, 0.3));
  background-position: 0% 88%;
  background-repeat: repeat-x;
  background-size: 1px 1px;
  color: #004986;
  padding-bottom: 4px;
  transition: color 0.2s;
}

.strong {
  font-weight: 700;
}

.break-word {
  word-break: break-word;
}

address {
  margin-bottom: 1.5rem;
}

body :focus:not(.focus-visible) {
  outline: 0;
}

ol,
ul {
  counter-reset: item;
  list-style: none;
  margin-bottom: 1.5rem;
  padding-left: 2rem;
}
ol li,
ul li {
  position: relative;
}

ul li table {
  margin-top: 1rem;
}
ul > li::before {
  content: "•";
  counter-increment: item;
  font-weight: 700;
  left: -1.375rem;
  position: absolute;
  text-align: left;
}

ol > li::before {
  content: counter(item) ".";
  counter-increment: item;
  font-weight: 700;
  left: -1.5rem;
  position: absolute;
  text-align: right;
}

ol ol > li::before {
  content: counter(item, lower-alpha) ".";
}

ol ol ol > li::before {
  content: counter(item, lower-roman) ".";
}

.lower-alpha > li::before {
  content: counter(item, lower-alpha) ".";
}

.upper-alpha > li::before {
  content: counter(item, upper-alpha) ".";
}

.lower-alpha > li::before {
  content: counter(item, lower-alpha) ".";
}

.lower-roman > li::before {
  content: counter(item, lower-roman) ".";
}

.upper-roman > li::before {
  content: counter(item, upper-roman) ".";
}

.decimal > li::before {
  content: counter(item, decimal) ".";
}

.decimal-zero > li::before {
  content: counter(item, decimal-leading-zero) ".";
  left: -2rem;
}

.lower-greek > li::before {
  content: counter(item, lower-greek) ".";
}

.list-no-indent,
.list-style-none,
.list-unstyled {
  list-style: none;
  padding-left: 0;
}
.list-no-indent > li::before,
.list-style-none > li::before,
.list-unstyled > li::before {
  content: "";
  display: none;
}

.list-inline-item::before {
  content: "";
  display: none;
}

.link-lists {
  padding-left: 0;
  list-style: none;
}
.link-lists li {
  margin-bottom: 1rem;
}
.link-lists li::before {
  content: none;
}
.link-lists a,
.link-lists .btn-link,
.link-lists a:not([href])[data-toggle=modal] {
  background-image: none;
  background-position: inherit;
  background-repeat: inherit;
  background-size: inherit;
}
.link-lists a:visited,
.link-lists .btn-link:visited,
.link-lists a:not([href])[data-toggle=modal]:visited {
  color: #0170bc;
}
.link-lists a:hover,
.link-lists .btn-link:hover,
.link-lists a:not([href])[data-toggle=modal]:hover {
  background-image: linear-gradient(#004986, #004986);
  background-repeat: repeat-x;
  background-size: 1px 1px;
  color: #004986;
  transition: color 0.2s ease-in;
}
.link-lists a,
.link-lists a:not([href])[data-toggle=modal] {
  background-position: 0% 88%;
}
.link-lists .btn-link {
  background-position: 0% 100%;
  font-family: inherit;
  font-weight: 600;
  line-height: inherit;
  padding-bottom: 0;
}

.action-link-blurb-body p:last-child,
.light-cta-body p:last-child {
  margin-bottom: 0;
}

.accordion-title,
.accordion-title-single,
.expand-control-text {
  text-align: left;
}

.accordion-title,
.accordion-title-single {
  align-items: center;
  width: 100%;
}

.accordion-content p:last-child {
  margin-bottom: 0;
}

.light-accordion-holder {
  border-bottom: 2px solid #e4e4e4;
  margin-bottom: 3rem;
}
.light-accordion-holder--side {
  border-bottom: 0;
  margin-bottom: 0;
}
.light-accordion-holder--side svg {
  height: 1.5rem;
  width: 1.5rem;
}
.light-accordion-holder .accordion-title,
.light-accordion-holder .accordion-title-single {
  background: transparent;
  border-radius: 0;
  color: #0170bc;
  cursor: pointer;
  flex: 0 0 100%;
  margin-bottom: 0;
  max-width: 100%;
  padding: 1rem 0;
  border: 0;
  border-top: 2px solid #e4e4e4;
  display: flex;
}
.light-accordion-holder .accordion-title .panel-header,
.light-accordion-holder .accordion-title-single .panel-header {
  color: #0170bc;
  display: inline-block;
  margin-bottom: 0;
  margin-top: 0;
}
.light-accordion-holder .accordion-title.active,
.light-accordion-holder .accordion-title-single.active {
  color: #004986;
}
.light-accordion-holder .accordion-content {
  padding: 1rem 0 2rem 1.5rem;
}
.light-accordion-holder .individual-accordion button:focus {
  outline: none;
}

.individual-inline-accordion {
  margin-bottom: 3rem;
}
.individual-inline-accordion .leadin-inline > :last-child {
  margin-bottom: 0;
}
.individual-inline-accordion .accordion-content {
  margin: 0 0 2rem 1.5rem;
}

.accordion-control-inline {
  align-items: center;
  display: flex;
  padding: 1rem 0;
}
.accordion-control-inline .control-arrow {
  cursor: pointer;
  fill: #0170bc;
}

.control-arrow {
  flex-shrink: 0;
  height: 1rem;
  margin-right: 0.25rem;
  width: 1rem;
}
.control-arrow.down, .control-arrow.right, .control-arrow.up {
  transition: 0.2s transform ease-in-out;
}
.control-arrow.right {
  transform: rotate(0deg);
}
.control-arrow.down {
  transform: rotate(90deg);
}
.control-arrow.up {
  transform: rotate(-90deg);
}
.light-accordion-holder .control-arrow {
  fill: #0170bc;
}

.accordion-block .light-accordion-holder {
  margin-bottom: 0;
}

.accordion-controls {
  padding-bottom: 1rem;
}
.accordion-controls .expand-all,
.accordion-controls .collapse-all {
  margin-right: 1rem;
}

.accordion-fade .accordion-control-inline {
  align-items: center;
  display: flex;
  padding: 1rem 0;
}
.accordion-fade .back-to-top {
  background-image: none;
  display: block;
  float: right;
  font-family: "HCo Gotham SSm", "Verdana", "Geneva", sans-serif;
  font-weight: 400;
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .accordion-fade .back-to-top {
    display: inline-block;
    margin-bottom: 0;
  }
}
.accordion-fade__body {
  margin-bottom: 2rem;
}
.accordion-fade__heading {
  display: inline-block;
  margin-bottom: 1.5rem;
}
.accordion-fade button {
  background-image: none;
}

.collapse-custom {
  height: 70px;
  overflow: hidden;
  position: relative;
  transition: max-height 0.2s ease-in-out;
}
.collapse-custom--fade::after {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, white 80%);
  bottom: 0;
  content: "";
  display: block;
  height: 20px;
  position: absolute;
  width: 100%;
}
.collapse-custom.show {
  overflow: auto;
}

svg.angle-down {
  transform: rotate(0deg);
}
svg.angle-up {
  transform: rotate(-180deg);
}

.alert {
  border: 0;
  border-radius: 0.75rem;
  color: #333;
  margin-bottom: 3rem;
  padding: 0;
}
.alert .a-message {
  display: inline-block;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
  /* stylelint-disable */
  /* stylelint-enable */
}
.alert .a-message .a-header {
  margin-bottom: 0.5rem;
}
.alert .a-message p:last-child {
  margin-bottom: 0;
}
@media (min-width: 576px) {
  .alert .a-message {
    display: table;
    padding: 1.5rem 3rem 1.5rem 1.5rem;
  }
  .alert .a-message .a-header {
    margin-bottom: 0;
  }
}
.alert .alert-icon {
  display: inline-block;
  float: left;
  height: 44px;
  margin-left: 1.5rem;
  margin-top: 1.5rem;
  width: 44px;
}
@media (min-width: 576px) {
  .alert .alert-icon {
    display: table;
  }
}
.alert .close {
  color: #333;
  cursor: pointer;
  height: 44px;
  opacity: 1;
  padding: 9px;
  text-align: center;
  width: 44px;
}

.alert-warning {
  background-color: #fcf0d6;
}
.alert-warning .alert-icon {
  fill: #e35205;
}

.alert-info {
  background-color: #e7f1f8;
}
.alert-info .alert-icon {
  fill: #004986;
}

.alert-success {
  background-color: #e7f2e5;
}
.alert-success .alert-icon {
  fill: #118503;
}

.alert-danger {
  background-color: #ffe3e2;
}
.alert-danger .alert-icon {
  fill: #c00;
}

.alert-banner {
  background-color: #e7f1f8;
  margin-bottom: 0;
  padding: 1.5rem 1rem;
}
@media (min-width: 768px) {
  .alert-banner {
    padding-left: 0;
    padding-right: 0;
  }
}

.alert-banner-body {
  font-size: 0.875rem;
  width: 100%;
}
@media (min-width: 576px) {
  .alert-banner-body {
    font-size: 1rem;
  }
}
@media (min-width: 768px) {
  .alert-banner-body {
    text-align: left;
  }
}
.alert-banner-body > :last-child {
  margin-bottom: 0;
}

.btn {
  padding: 1rem 1.5rem;
  font-size: 1rem;
  line-height: 16px;
  border-radius: 0.5rem;
  background-image: none;
  cursor: pointer;
  display: inline-block;
  font-family: "HCo Gotham SSm", "Verdana", "Geneva", sans-serif;
  font-weight: 500;
  white-space: normal;
}
.btn:link:hover {
  background-image: none;
}
.btn .external {
  height: 1rem;
  margin-bottom: 0.125rem;
  width: 1rem;
}
.btn--language {
  color: transparent;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 600;
}
.btn--language:hover {
  color: #fff;
  background-color: #0170bc;
  border-color: #00a9e0;
}
.btn--language:hover .btn-icon {
  fill: #fff;
}
.btn--language.hover {
  color: #fff;
  background-color: #0170bc;
  border-color: #00a9e0;
}
.btn--language:focus, .btn--language.focus {
  background-color: transparent;
  box-shadow: 0 0 4px 1px #00a9e0;
}
.btn--language.disabled, .btn--language:disabled {
  background-color: #e4e4e4;
  box-shadow: none;
  border-color: transparent;
}
.btn--language:not([disabled]):not(.disabled):active, .btn--language:not([disabled]):not(.disabled).active, .show > .btn--language.dropdown-toggle {
  color: transparent;
  background-color: #004986;
  border-color: transparent;
  box-shadow: 0 0 4px 1px #00a9e0;
}
.btn--language .btn-icon {
  fill: transparent;
}
.btn--language:focus, .btn--language:active {
  box-shadow: none !important;
}
.btn--btm-language-menu {
  align-items: center;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
  color: #333;
  display: flex;
  height: 58px;
  justify-content: space-between;
  line-height: 1.5;
  padding: 0.5rem 1.5rem 0.5rem 1rem;
  width: 100%;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 14px;
  border-radius: 0.5rem;
}

.btn-primary {
  color: #fff;
  background-color: #0170bc;
  border-color: transparent;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.4);
}
.btn-primary:hover {
  color: #fff;
  background-color: #004986;
  border-color: #00a9e0;
}
.btn-primary:hover .btn-icon {
  fill: #fff;
}
.btn-primary.hover {
  color: #fff;
  background-color: #004986;
  border-color: #00a9e0;
}
.btn-primary:focus, .btn-primary.focus {
  background-color: #0170bc;
  box-shadow: 0 0 4px 1px #00a9e0;
}
.btn-primary.disabled, .btn-primary:disabled {
  background-color: #e4e4e4;
  box-shadow: none;
  border-color: transparent;
}
.btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #004986;
  border-color: transparent;
  box-shadow: 0 0 4px 1px #00a9e0;
}
.btn-primary .btn-icon {
  fill: #fff;
}
.btn-primary:link, .btn-primary:visited {
  color: #fff;
}
.btn-primary.disabled, .btn-primary:disabled {
  box-shadow: none;
  color: #505050;
}

.btn-secondary,
.btn-load-more {
  color: #0170bc;
  background-color: #f8f8f8;
  border-color: transparent;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
}
.btn-secondary:hover,
.btn-load-more:hover {
  color: #0170bc;
  background-color: #e7f1f8;
  border-color: transparent;
}
.btn-secondary:hover .btn-icon,
.btn-load-more:hover .btn-icon {
  fill: #0170bc;
}
.btn-secondary.hover,
.btn-load-more.hover {
  color: #0170bc;
  background-color: #e7f1f8;
  border-color: transparent;
}
.btn-secondary:focus, .btn-secondary.focus,
.btn-load-more:focus,
.btn-load-more.focus {
  background-color: #f8f8f8;
  box-shadow: 0 0 4px 1px #00a9e0;
}
.btn-secondary.disabled, .btn-secondary:disabled,
.btn-load-more.disabled,
.btn-load-more:disabled {
  background-color: #e4e4e4;
  box-shadow: none;
  border-color: transparent;
}
.btn-secondary:not([disabled]):not(.disabled):active, .btn-secondary:not([disabled]):not(.disabled).active, .show > .btn-secondary.dropdown-toggle,
.btn-load-more:not([disabled]):not(.disabled):active,
.btn-load-more:not([disabled]):not(.disabled).active,
.show > .btn-load-more.dropdown-toggle {
  color: #0170bc;
  background-color: #e7f1f8;
  border-color: transparent;
  box-shadow: 0 0 4px 1px #00a9e0;
}
.btn-secondary .btn-icon,
.btn-load-more .btn-icon {
  fill: #0170bc;
}
.btn-secondary:link, .btn-secondary:visited,
.btn-load-more:link,
.btn-load-more:visited {
  color: #0170bc;
}
.btn-secondary.disabled, .btn-secondary:disabled,
.btn-load-more.disabled,
.btn-load-more:disabled {
  color: #505050;
}

.btn-tertiary {
  background-color: transparent;
  color: #0170bc;
}
.btn-tertiary:hover {
  background-color: transparent;
  border-color: transparent;
  color: #004986;
  text-decoration: underline;
}
.btn-tertiary:link, .btn-tertiary:visited {
  color: #0170bc;
}
.btn-tertiary.hover {
  background-color: transparent;
  border-color: transparent;
  color: #004986;
  text-decoration: underline;
}
.btn-tertiary:focus, .btn-tertiary.focus {
  background-color: transparent;
  border-color: transparent;
  box-shadow: 0 0 4px 1px #00a9e0;
}
.btn-tertiary:disabled, .btn-tertiary.disabled {
  color: #6c757d;
}
.btn-tertiary:not([disabled]):not(.disabled):active, .btn-tertiary:not([disabled]):not(.disabled).active {
  box-shadow: 0 0 4px 1px #00a9e0;
  text-decoration: underline;
}

.btn-link {
  color: #0170bc;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  background-image: linear-gradient(rgba(51, 51, 51, 0.3), rgba(51, 51, 51, 0.3));
  background-position: 0% 100%;
  background-repeat: repeat-x;
  background-size: 1px 1px;
  border: 0;
  border-radius: 0rem;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 600;
  line-height: 1.5;
  padding: 0;
}
.btn-link:hover {
  color: #004986;
  background-color: transparent;
  border-color: transparent;
}
.btn-link:hover .btn-icon {
  fill: #004986;
}
.btn-link.hover {
  color: #004986;
  background-color: transparent;
  border-color: transparent;
}
.btn-link:focus, .btn-link.focus {
  background-color: transparent;
  box-shadow: 0 0 4px 1px #00a9e0;
}
.btn-link.disabled, .btn-link:disabled {
  background-color: transparent;
  box-shadow: none;
  border-color: transparent;
}
.btn-link:not([disabled]):not(.disabled):active, .btn-link:not([disabled]):not(.disabled).active, .show > .btn-link.dropdown-toggle {
  color: #0170bc;
  background-color: transparent;
  border-color: transparent;
  box-shadow: 0 0 4px 1px #00a9e0;
}
.btn-link .btn-icon {
  fill: #0170bc;
}
.btn-link:active, .btn-link:hover, .btn-link:focus {
  background-image: linear-gradient(rgba(0, 73, 134, 0.3), rgba(0, 73, 134, 0.3));
  box-shadow: none;
  transition: color 0.2s;
}
.btn-link:active:not([disabled]):not(.disabled), .btn-link:hover:not([disabled]):not(.disabled), .btn-link:focus:not([disabled]):not(.disabled) {
  box-shadow: none;
}
.btn-link:focus {
  background-color: #fcf0d6;
}
.btn-link:not(.btn):visited {
  color: #800080;
}

.btn-flat-secondary {
  color: #0170bc;
  background-color: #f8f8f8;
  border-color: transparent;
  box-shadow: none;
}
.btn-flat-secondary:hover {
  color: #fff;
  background-color: #0170bc;
  border-color: transparent;
}
.btn-flat-secondary:hover .btn-icon {
  fill: #fff;
}
.btn-flat-secondary.hover {
  color: #fff;
  background-color: #0170bc;
  border-color: transparent;
}
.btn-flat-secondary:focus, .btn-flat-secondary.focus {
  background-color: #f8f8f8;
  box-shadow: 0 0 4px 1px #00a9e0;
}
.btn-flat-secondary.disabled, .btn-flat-secondary:disabled {
  background-color: #e4e4e4;
  box-shadow: none;
  border-color: transparent;
}
.btn-flat-secondary:not([disabled]):not(.disabled):active, .btn-flat-secondary:not([disabled]):not(.disabled).active, .show > .btn-flat-secondary.dropdown-toggle {
  color: #0170bc;
  background-color: #0170bc;
  border-color: transparent;
  box-shadow: 0 0 4px 1px #00a9e0;
}
.btn-flat-secondary .btn-icon {
  fill: #0170bc;
}
.btn-flat-secondary:hover {
  background-color: #0170bc;
  color: #fff;
}
.btn-flat-secondary.disabled, .btn-flat-secondary:disabled {
  color: #505050;
}
.btn-flat-secondary:not([disabled]):not(.disabled):active, .btn-flat-secondary:not([disabled]):not(.disabled).active, .show > .btn-flat-secondary.dropdown-toggle {
  color: #fff;
}

.btn-quote {
  color: #fff;
  background-color: #f68621;
  border-color: transparent;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.4);
}
.btn-quote:hover {
  color: #fff;
  background-color: #e35205;
  border-color: #fcf0d6;
}
.btn-quote:hover .btn-icon {
  fill: #fff;
}
.btn-quote.hover {
  color: #fff;
  background-color: #e35205;
  border-color: #fcf0d6;
}
.btn-quote:focus, .btn-quote.focus {
  background-color: #f68621;
  box-shadow: 0 0 4px 1px #00a9e0;
}
.btn-quote.disabled, .btn-quote:disabled {
  background-color: #e4e4e4;
  box-shadow: none;
  border-color: transparent;
}
.btn-quote:not([disabled]):not(.disabled):active, .btn-quote:not([disabled]):not(.disabled).active, .show > .btn-quote.dropdown-toggle {
  color: #fff;
  background-color: #e35205;
  border-color: transparent;
  box-shadow: 0 0 4px 1px #00a9e0;
}
.btn-quote .btn-icon {
  fill: #fff;
}
.btn-quote:hover {
  background-color: #e35205;
  color: #fff;
}
.btn-quote.disabled, .btn-quote:disabled {
  box-shadow: none;
  color: #505050;
}

.btn-load-more {
  margin-bottom: 4rem;
  text-align: center;
  width: 100%;
}
@media (min-width: 686px) {
  .btn-load-more {
    width: 686px;
  }
}

.btn-icon {
  height: 1.25em;
  width: 1.25em;
  /* Using ems here to allow .btn-small to lower width and height. */
  margin-right: 0.5rem;
  pointer-events: none;
  vertical-align: inherit;
}
.btn-close .btn-icon {
  height: 1.5em;
  width: 1.5em;
  margin-right: 0.25rem;
}
.btn-icon--left {
  margin-right: 0.25rem;
}
.btn-icon--right {
  margin-left: 0.25rem;
}

.btn-close {
  padding: 0 0;
  font-size: 1.125rem;
  line-height: 1.3333;
  border-radius: 0;
  align-items: center;
  background-color: transparent;
  border-bottom-color: 1px solid transparent;
  color: #0170bc;
  display: flex;
  margin: 0 0.25rem;
  z-index: 100;
}
.btn-close:hover {
  background-color: inherit;
  border-bottom-color: #004986;
  color: #004986;
  text-decoration: none;
}
.btn-close:focus, .btn-close.focus {
  background-color: transparent;
  border-color: transparent;
}
.btn-close-x {
  font-family: inherit;
  font-size: 1.625rem;
  font-weight: inherit;
  line-height: 0.9231;
  padding-left: 0.5rem;
}
.btn-close .btn-icon {
  height: 1.5em;
  width: 1.5em;
  margin-right: 0.25rem;
}

[data-action=share] {
  position: relative;
}

.share-buttons {
  display: inline-flex;
  flex-direction: row;
  will-change: left, opacity, position, top, transform;
}
@media (min-width: 1200px) and (max-width: 1299.98px) {
  .share-buttons {
    position: relative;
  }
}
.share-buttons--horizontal .share-buttons-heading {
  margin-bottom: 0;
}
@media (min-width: 1300px) {
  .share-buttons--vertical {
    flex-direction: column;
    margin-left: -70px;
    position: absolute;
    top: 0;
  }
}
@media (min-width: 1300px) {
  .share-buttons--vertical .btn-share {
    margin-right: 0;
  }
}

.share-buttons-fixed {
  position: fixed;
}

.share-buttons-set {
  opacity: 1;
}

.share-buttons-heading {
  font-weight: 500;
  margin-right: 1rem;
}
@media (min-width: 1300px) {
  .share-buttons-heading {
    color: #505050;
    font-size: 0.75rem;
    line-height: 1.416;
    font-weight: 700;
    margin-bottom: 1rem;
    /*margin-right: 0;*/
  }
}

.btn-share {
  padding: 0.625rem 0.625rem;
  font-size: 0.875rem;
  line-height: 14px;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
}
.btn-share:hover {
  background-image: none;
}
.btn-share:focus .btn-icon {
  fill: #0170bc;
}
.btn-share:active .btn-icon {
  fill: #fff;
}
.btn-share .btn-icon {
  height: 1.5em;
  width: 1.5em;
}

.crumbs {
  align-items: center;
  margin: 0;
  padding: 1rem 0;
  position: relative;
  top: 0;
  z-index: 1;
}
.crumbs .crumb-nav {
  align-items: center;
  display: inline-flex;
}
.crumbs .crumb-nav .crumb-link {
  background-image: none;
  padding: 0;
  white-space: nowrap;
}
.crumbs .crumb-nav svg {
  padding: 0 0.25rem;
}
.crumbs .crumb-nav:last-child {
  cursor: default;
  white-space: normal;
}

.input-holder,
.form-drop,
.input-holder-r {
  margin-bottom: 2rem;
  position: relative;
}

.main-input {
  position: relative;
}

.basic-input {
  background: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 0.5rem;
  box-shadow: inset 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
  padding: 0.375rem 0.75rem;
}
.basic-input:focus {
  border: 1px solid #00a9e0;
  box-shadow: inset 1px 1px 4px 0 rgba(0, 169, 224, 0.6);
}
.basic-input::placeholder {
  color: #e4e4e4;
}

.select {
  appearance: none;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1792 1792'%3E%3Cpath fill='%230170bc' d='M1395 736q0 13-10 23l-466 466q-10 10-23 10t-23-10L407 759q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l393 393 393-393q10-10 23-10t23 10l50 50q10 10 10 23z'/%3E%3C/svg%3E") no-repeat right 1rem center;
  background-size: 20px 20px;
  border: 1px solid #e4e4e4;
  border-radius: 0.5rem;
  color: #e4e4e4;
  display: inline-block;
  line-height: 1.5;
  padding: 0.5rem 2rem 0.5rem 1rem;
  vertical-align: middle;
  width: 100%;
}
.select:focus {
  background-color: #fff;
  outline: 0;
}
.select:invalid {
  color: #e4e4e4;
}
.select--lg {
  padding: 0.75rem;
}
.select option {
  color: #333;
}
.select option[disabled] {
  color: #e4e4e4;
  display: none;
}

.custom-select,
.select-input {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: #fff url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'><path fill='#343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>") no-repeat right 1rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  box-shadow: inset 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
  color: #495057;
  display: inline-block;
  height: calc(1.5em + 0.75rem + 2px);
  line-height: 1.5;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  vertical-align: middle;
  width: 100%;
  border-radius: 0.25rem;
}
.custom-select:focus,
.select-input:focus {
  border-color: #3fb0fe;
  box-shadow: 0 0 0 0.2rem rgba(1, 112, 188, 0.25);
}
.custom-select:focus::-ms-value,
.select-input:focus::-ms-value {
  background-color: #fff;
  color: #495057;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]),
.select-input[multiple],
.select-input[size]:not([size="1"]) {
  background-image: none;
  height: auto;
  padding-right: 0.75rem;
}
.custom-select:disabled,
.select-input:disabled {
  background-color: #e9ecef;
  color: #6c757d;
}
.custom-select::-ms-expand,
.select-input::-ms-expand {
  opacity: 0;
}

.input__text {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid;
  border-radius: 0.5rem;
  display: block;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.5rem 1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}
.input__text:focus {
  background-color: #fff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  outline: 0;
}
.input--lg {
  border-radius: 0.3rem;
  font-size: 1.25rem;
  line-height: 1.5;
  padding: 1rem 1.5rem;
}
.input--gutter {
  margin-bottom: 1.5rem;
}
.input__inline {
  align-items: center;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}
.input__col {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}
.input__col--three {
  flex: 0 0 25%;
  max-width: 25%;
}

.input-holder.input-error .basic-input,
.input-holder.input-error .custom-select,
.input-holder-r.input-error .basic-input,
.input-holder-r.input-error .custom-select {
  border: 2px solid #c00;
  box-shadow: inset 1px 1px 4px 0 rgba(204, 0, 0, 0.6);
}
.input-holder.input-error .error-feedback,
.input-holder-r.input-error .error-feedback {
  align-items: center;
  color: #c00;
  display: flex;
  margin-top: 0.5rem;
}
.input-holder.input-error .feedback-icn,
.input-holder-r.input-error .feedback-icn {
  align-self: flex-start;
  fill: #c00;
  flex: 0 0 1rem;
  height: 1.5rem;
}
.input-holder.input-error .error-message,
.input-holder-r.input-error .error-message {
  margin-bottom: 0;
  margin-left: 0.5rem;
}

.input-holder.input-success .validation-icn,
.input-holder-r.input-success .validation-icn {
  display: block;
  fill: #118503;
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}
.input-holder.input-success.form-drop .validation-icn,
.input-holder-r.input-success.form-drop .validation-icn {
  display: none;
}

.form-drop .dropdown-toggle::after {
  display: none;
}
.form-drop .drop-arrow {
  pointer-events: none;
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}
.form-drop .dropdown-menu, .form-drop option {
  border: 1px solid #e4e4e4;
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 100%;
}
.form-drop .dropdown-menu .dropdown-item, .form-drop option .dropdown-item {
  background-image: none;
  color: #333;
}
.form-drop .dropdown-menu .dropdown-item:focus, .form-drop .dropdown-menu .dropdown-item:hover, .form-drop option .dropdown-item:focus, .form-drop option .dropdown-item:hover {
  background-color: #e7f1f8;
}

.input-holder-r .important-label {
  color: #505050;
  margin-bottom: 0;
  margin-left: 0.5rem;
}

.input-optional .optional-label {
  color: #505050;
  margin-bottom: 0;
  margin-left: 0.5rem;
}

.error-feedback,
.validation-icn {
  display: none;
}

.input-label,
.error-feedback {
  font-weight: 600;
}

.input-label {
  display: inline-flex;
}

.checkbox-holder, .radio-holder {
  margin-bottom: 1rem;
}

.checkbox-label, .radio-label {
  display: block;
  margin-bottom: 0;
  padding-left: 26px;
  position: relative;
  user-select: none;
}

.checkbox-label input, .radio-label input {
  opacity: 0;
  position: absolute;
}

.custom-checkbox {
  background-color: #f8f8f8;
  border: 1px solid #e4e4e4;
  border-radius: 0.25rem;
  cursor: pointer;
  height: 16px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
}

.checkbox-label input:checked ~ .custom-checkbox {
  background-color: #0170bc;
  border: 1px solid #0170bc;
}

.custom-checkbox::after, .custom-radio::after {
  content: "";
  display: none;
  position: absolute;
}

.checkbox-label input:checked ~ .custom-checkbox::after, .radio-label input:checked ~ .custom-radio::after {
  display: block;
}

.checkbox-label .custom-checkbox::after {
  content: url("data:image/svg+xml;charset=UTF-8, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1792 1792' fill='%23fff'%3E%3Cpath d='M1550 232q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136L28 662Q0 634 0 594t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z'/%3E%3C/svg%3E");
  height: 12px;
  left: 2px;
  position: absolute;
  top: -3px;
  width: 12px;
}

.radio-label .custom-radio::after {
  background: #0170bc;
  border-radius: 50%;
  height: 10px;
  left: 50%;
  margin-left: -5px;
  margin-top: -5px;
  position: absolute;
  top: 50%;
  width: 10px;
}

.custom-radio {
  background-color: #f8f8f8;
  border: 1px solid #e4e4e4;
  border-radius: 100%;
  cursor: pointer;
  height: 1rem;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  height: 4rem;
  width: 4rem;
  animation: spin 1.7s infinite linear;
  border: 0.25rem solid rgba(227, 82, 5, 0.7);
  border-left-color: #f8f8f8;
  border-radius: 50%;
}

.spinner-border.loading {
  height: 4rem;
  width: 4rem;
  color: rgba(227, 82, 5, 0.7);
}

@media (max-width: 1199.98px) {
  .callout-block {
    margin-top: 3rem;
  }
}

.callout-side {
  margin-bottom: 1.5rem;
}
.callout-side-heading {
  display: inline-block;
}

.callout-side-heading, .callout-block-heading {
  border-bottom: 4px solid #f68621;
  margin-bottom: 1rem;
  width: fit-content;
}

.callout p:last-child {
  margin-bottom: 0;
}

.currency-switcher {
  display: none;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
}
.currency-switcher__toggle {
  background-color: #fff;
  border: 1px solid #e7f1f8;
  display: block;
  padding: 1rem 1rem;
  text-align: center;
  width: 100%;
}
.currency-switcher__toggle:hover {
  background-color: #e7f1f8;
}
.currency-switcher__toggle:focus {
  outline: none;
}
.currency-switcher__active {
  background-color: #004986;
  border: 1px solid #004986;
  color: #fff;
}
.currency-switcher__active:hover {
  background-color: #004986;
}

.product__plans {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  margin-bottom: 4rem;
}
@media (min-width: 576px) {
  .product__plans {
    max-width: 544px;
  }
}
@media (min-width: 768px) {
  .product__plans {
    max-width: 724px;
  }
}
@media (min-width: 992px) {
  .product__plans {
    max-width: 964px;
  }
}
@media (min-width: 1200px) {
  .product__plans {
    max-width: 1144px;
  }
}
.product__plans--top {
  padding: 1.5rem;
}
.product__plans--bottom {
  background-color: #e7f1f8;
  padding: 1.5rem;
}
.product__plans--card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  margin-bottom: 1.5rem;
  padding: 1.5rem;
}
.product__plans--subheading {
  text-transform: uppercase;
}
.product__plans--icons {
  display: flex;
  flex-wrap: wrap;
}
.product__plans--icons span {
  padding-right: 2rem;
  text-align: center;
}
@media (min-width: 576px) {
  .product__plans--icons span {
    padding-right: 2rem;
  }
}
.product__plans--icons span:first-child {
  padding-left: 0;
}
@media (max-width: 575.98px) {
  .product__plans--icons svg {
    height: 2rem !important;
    width: 2rem !important;
  }
}
@media (min-width: 768px) {
  .product__plans--buttons {
    display: flex;
  }
}
@media (min-width: 768px) {
  .product__plans--buttons a, .product__plans--buttons button {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
.product__plans--buttons a {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}
.product__plans--buttons a:not(:last-child),
.product__plans--buttons button:not(:last-child) {
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .product__plans--buttons a:not(:last-child),
.product__plans--buttons button:not(:last-child) {
    margin-bottom: 0;
    margin-right: 1rem;
  }
}

.plan-cards__body {
  padding: 3rem 1.5rem;
}
.plan-cards__body--top p {
  margin-bottom: 0;
}
.plan-cards__collapse-body {
  height: 0;
}
.plan-cards [data-toggle=collapse-custom] {
  padding: 1rem 0;
}
.plan-cards .btn-link {
  background-image: none;
}
.plan-cards .btn-link:focus {
  background-color: transparent;
}

.quotation.blockquote {
  border-left: 6px solid;
  margin: 2rem 0 3rem;
  padding-left: 2rem;
}
.quotation.blockquote--border-orange {
  border-left-color: #f68621;
}
@media (min-width: 768px) {
  .quotation.blockquote--border-orange {
    margin: 2rem 100px;
  }
}
.quotation.blockquote--border-blue {
  border-left-color: #0170bc;
}
@media (min-width: 768px) {
  .quotation.blockquote--border-blue {
    margin: 2rem 100px;
  }
}
.quotation.side {
  margin-bottom: 1.5rem;
}
.quotation.side .quote-text::after {
  height: 6px;
  width: 56px;
  background-color: #f68621;
  content: " ";
  display: block;
  margin-top: 1rem;
}

.quotation .quote-text {
  margin-bottom: 1rem;
}
.quotation .quote-text p:last-child {
  margin-bottom: 0;
}
.quotation .quote-attribution {
  color: #505050;
}

.tooltip {
  border-radius: 0.75rem;
  filter: alpha(opacity=100) !important;
  opacity: 1 !important;
}

.tooltip-item {
  border-bottom: 2px dotted #0170bc;
  color: #0170bc;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
}
@media (min-width: 768px) {
  .tooltip-item {
    cursor: auto;
  }
}
.tooltip-item:hover {
  border-bottom-style: solid;
}

.tooltip-inner {
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 0 2px 10px -3px black;
  color: #333;
  filter: alpha(opacity=100) !important;
  min-width: 260px;
  opacity: 1 !important;
  padding: 1rem;
  text-align: left;
}

.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.bs-tooltip-top .arrow::before {
  border-top-color: #fff;
}

.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::after, .tooltip.bs-tooltip-top .arrow::after {
  background-color: white;
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.2);
  content: "";
  height: 10px;
  left: 2px;
  position: absolute;
  top: -5px;
  transform: rotate(45deg);
  /* Prefixes... */
  width: 11px;
}

.tooltip.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.bs-tooltip-bottom .arrow::before {
  border-top-color: #fff;
}

.tooltip.bs-tooltip-auto[x-placement^=bottom] .arrow::after, .tooltip.bs-tooltip-bottom .arrow::after {
  background-color: white;
  box-shadow: -1px -1px 1px 0 rgba(0, 0, 0, 0.3);
  content: "";
  height: 10px;
  left: 2px;
  position: absolute;
  top: 0px;
  transform: rotate(45deg);
  /* Prefixes... */
  width: 11px;
}

.tooltip.show {
  z-index: 1073;
}

/* Icons classes */
.icn {
  align-content: center;
  align-items: center;
  display: inline-flex;
}

.icn svg {
  margin: 0 auto;
}

.hero-icn {
  border-radius: 72px;
  height: 144px;
  margin: 1.5rem;
  width: 144px;
}

.hero-icn svg {
  height: 112px;
  width: 112px;
}

.block-icn {
  border-radius: 44px;
  height: 88px;
  margin: 1rem;
  width: 88px;
}

.block-icn svg {
  height: 72px;
  width: 72px;
}

.teaser-icn {
  height: 64px;
  width: 64px;
}

.teaser-icn svg {
  height: 64px;
  width: 64px;
}

.xl-icn, .xl-icn svg {
  height: 44px;
  width: 44px;
}

.ml-icn, .ml-icn svg {
  height: 24px;
  width: 24px;
}

.m-icn, .m-icn svg {
  height: 16px;
  width: 16px;
}

.xs-icn, .xs-icn svg {
  height: 14px;
  width: 14px;
}

.white-icn svg {
  fill: #fff;
}

.digital-blue-icn svg {
  fill: #0170bc;
}

.icon-block-container .icon-block-2 {
  margin-bottom: 3rem;
}
.icon-block-container .icon-block-img-2 {
  margin-bottom: 1rem;
}
@media (min-width: 576px) {
  .icon-block-container .icon-block-img-2 {
    display: table-cell;
    margin-bottom: 0;
    vertical-align: top;
  }
}
@media (min-width: 576px) {
  .icon-block-container .icon-block-content-2 {
    display: table-cell;
    padding-left: 2rem;
    vertical-align: middle;
  }
}
.icon-block-container .icon-block-content-2 header {
  margin-bottom: 1rem;
}

.icon-block-container .icon-block-3 {
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .icon-block-container .icon-block-3 {
    margin-bottom: 1rem;
  }
}
.icon-block-container .icon-block-img-3 {
  margin-bottom: 1rem;
}
@media (min-width: 576px) {
  .icon-block-container .icon-block-img-3 {
    display: table-cell;
  }
}
@media (min-width: 768px) {
  .icon-block-container .icon-block-img-3 {
    display: block;
    margin-bottom: 1rem;
  }
}
@media (min-width: 576px) {
  .icon-block-container .icon-block-content-3 {
    display: table-cell;
    padding-left: 1.5rem;
    vertical-align: middle;
  }
}
@media (min-width: 768px) {
  .icon-block-container .icon-block-content-3 {
    display: block;
    padding-left: 0;
    padding-right: 1.5rem;
  }
}
.icon-block-container .icon-block-content-3 header {
  margin-bottom: 0.5rem;
}

.icon-block-container .icon-block-4 {
  margin-bottom: 3rem;
  margin-right: 8.33%;
}
@media (min-width: 768px) {
  .icon-block-container .icon-block-4 {
    margin-bottom: 2rem;
  }
}
@media (min-width: 992px) {
  .icon-block-container .icon-block-4 {
    margin-right: 0;
  }
}
.icon-block-container .icon-block-img-4 {
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .icon-block-container .icon-block-img-4 {
    display: block;
    margin-bottom: 1rem;
  }
}
@media (min-width: 576px) {
  .icon-block-container .icon-block-content-4 {
    vertical-align: middle;
  }
}
@media (min-width: 768px) {
  .icon-block-container .icon-block-content-4 {
    display: block;
    padding-left: 0;
  }
}
.icon-block-container .icon-block-content-4 header {
  margin-bottom: 0.5rem;
}

.icn.icn-b.block-icn {
  margin: 0;
}

.icon__list {
  list-style: none;
  padding-left: 0;
}
.icon__list > * + * {
  padding-top: 1rem;
}
.icon__list-item {
  align-items: center;
  display: flex;
}
.icon__list-item::before {
  content: "";
}
.icon__checkmark {
  align-self: flex-start;
  fill: #0170bc;
  flex: 0 0 2rem;
  height: 2rem;
  margin-right: 1rem;
  width: 2rem;
}

.image-teaser {
  margin-bottom: 2rem;
}
.image-teaser__body {
  margin-bottom: 1rem;
}
.image-teaser__heading-right-link {
  background-image: none;
  display: inline-flex;
  float: right;
  font-weight: 600;
  margin-bottom: 1rem;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .image-teaser__heading-right-link {
    margin-bottom: 0;
  }
}
.image-teaser__heading-right-link:visited {
  color: #0170bc;
}

.teaser-img {
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .teaser-img {
    margin-bottom: 0;
  }
}
.teaser-img img {
  border-radius: 0.5rem;
}

.teaser-meta {
  font-family: "HCo Gotham SSm", "Verdana", "Geneva", sans-serif;
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 500;
  margin-bottom: 1rem;
}
.teaser-meta__date {
  color: #939496;
}

.teaser-title {
  margin-bottom: 0.5rem;
}
.teaser-title > a,
.teaser-title > a:not([href])[data-toggle=modal] {
  background-image: none;
  background-position: 0% 89%;
  background-repeat: repeat-x;
  background-size: 1px 1px;
  color: #0170bc;
  cursor: pointer;
  font-weight: inherit;
  padding-bottom: 4px;
}
.teaser-title > a:visited,
.teaser-title > a:not([href])[data-toggle=modal]:visited {
  color: #0170bc;
}
.teaser-title > a:hover,
.teaser-title > a:not([href])[data-toggle=modal]:hover {
  background-image: linear-gradient(#004986, #004986);
  color: #004986;
}

.teaser-content {
  font-family: "HCo Gotham SSm", "Verdana", "Geneva", sans-serif;
}

.teaser-content > :last-child {
  margin-bottom: 0;
}

.image-teaser-small {
  min-height: 100px;
}
@media (min-width: 576px) {
  .image-teaser-small {
    margin-bottom: 0;
  }
}
@media (min-width: 576px) {
  .image-teaser-small .teaser-img {
    flex: 0 0 120px;
    margin-bottom: 0;
    max-width: 120px;
  }
}
@media (min-width: 576px) {
  .image-teaser-small .teaser-content {
    flex-basis: calc(100% - 120px);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(100% - 120px);
    padding-left: 1.5rem;
  }
}
@media (min-width: 576px) {
  .image-teaser-small .teaser-content .teaser-title {
    margin-bottom: 0;
  }
}

.image-teaser-medium:last-child,
.image-teaser-large:last-child {
  margin-bottom: 3rem;
}

@media (min-width: 768px) {
  .image-teaser-medium .teaser-img {
    flex: 0 0 200px;
    max-width: 200px;
  }
}
@media (min-width: 992px) {
  .image-teaser-medium {
    /*
    .teaser-img {
      flex: 0 0 percentage(2/7);
      max-width: percentage(2/7);
    }
    */
  }
  .image-teaser-medium .teaser-content {
    flex: 0 0 71.4285714286%;
    max-width: 71.4285714286%;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .image-teaser-large-2x {
    margin-bottom: 3rem;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .image-teaser-large-2x .teaser-img {
    margin-bottom: 1rem;
  }
}
@media (min-width: 992px) {
  .image-teaser-large-3x {
    margin-bottom: 3rem;
  }
}
@media (min-width: 992px) {
  .image-teaser-large-3x .teaser-img {
    margin-bottom: 1rem;
  }
}

.image-teaser-vertical .teaser-img {
  margin-bottom: 2rem;
}

.pagination {
  align-items: center;
  display: inline-flex;
  list-style: none;
  padding-left: 0;
}
.pagination__container {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}
@media (min-width: 576px) {
  .pagination__container {
    max-width: 544px;
  }
}
@media (min-width: 768px) {
  .pagination__container {
    max-width: 724px;
  }
}
@media (min-width: 992px) {
  .pagination__container {
    max-width: 964px;
  }
}
@media (min-width: 1200px) {
  .pagination__container {
    max-width: 1144px;
  }
}
.pagination__item {
  margin-right: 1rem;
}
.pagination__item::before {
  content: "";
}
.pagination__item:last-child {
  margin-right: 0;
}
.pagination__item svg {
  fill: #0170bc;
  height: 16px;
  pointer-events: none;
  width: 16px;
}

.text-promo {
  background-color: #f8f8f8;
}

.full-promo {
  margin-bottom: 3rem;
}
@media (min-width: 992px) {
  .full-promo {
    margin-bottom: 4rem;
  }
}
.full-promo .body-text {
  margin-bottom: 1.5rem;
}
.full-promo .btn {
  margin-bottom: 1.5rem;
  margin-right: 0.25rem;
}
@media (min-width: 768px) {
  .full-promo .btn {
    margin-bottom: 0.5rem;
    margin-right: 1rem;
  }
}
.full-promo .promo-col {
  padding: 2rem;
}
@media (min-width: 576px) {
  .full-promo .promo-col {
    padding: 3rem 0 3rem 0;
  }
}
@media (min-width: 992px) {
  .full-promo .promo-col {
    padding-bottom: 4.5rem;
    padding-top: 4.5rem;
  }
}

.promo-content-container .promo-content,
.promo-content-container-w .promo-content,
.promo-content-container-g .promo-content {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.promo-gray {
  background-color: #f8f8f8;
}

.promo-img-container-g {
  margin: 0 2rem 2rem;
  padding: 0;
}
@media (min-width: 576px) {
  .promo-img-container-g {
    margin: 0 8.33% 2rem 8.33%;
  }
}
@media (min-width: 768px) {
  .promo-img-container-g {
    margin: 0 0 0 8.33%;
  }
}

.promo-content-container-g {
  margin: 0 2rem 0 2rem;
  padding: 0;
}
@media (min-width: 576px) {
  .promo-content-container-g {
    margin: 0 8.33% 2rem 8.33%;
  }
}
@media (min-width: 768px) {
  .promo-content-container-g {
    margin: 0 8.33% 0 0;
    padding-left: 2rem;
  }
}

.promo {
  border-radius: 0.75rem;
  margin-bottom: 3rem;
}

.promo-img-container .promo-bg {
  background-position: center;
  background-size: cover;
  display: block;
}
@media (max-width: 991.98px) {
  .promo-img-container .promo-bg {
    border-radius: 0.75rem;
    height: 300px;
  }
}
@media (min-width: 992px) {
  .promo-img-container .promo-bg {
    border-radius: 0.75rem;
    height: 100%;
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 991.98px) {
  .promo-content-container {
    background-color: #fff;
    border: 2px solid #e4e4e4;
    border-radius: 0.75rem;
    margin: -3rem 8.33% 2rem 8.33%;
    padding: 1rem;
  }
}
@media (min-width: 992px) {
  .promo-content-container {
    margin-bottom: 3rem;
    margin-top: 3rem;
  }
}
.promo-content-container .promo-subhead {
  margin-bottom: 1.5rem;
}
@media (min-width: 992px) {
  .promo-content-container .promo-subhead {
    margin-bottom: 2rem;
  }
}
@media (max-width: 1199.98px) {
  .promo-content-container .promo-subhead {
    font-size: 1.75rem;
    line-height: 1.2428571429;
  }
}
@media (max-width: 575.98px) {
  .promo-content-container .promo-subhead {
    font-size: 1.375rem;
    line-height: 1.2727272727;
  }
}
.promo-content-container .body-text {
  margin-bottom: 1.5rem;
}
@media (min-width: 992px) {
  .promo-content-container .body-text {
    margin-bottom: 2rem;
  }
}
.promo-content-container .link-action {
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  .promo-content-container .link-action {
    margin-bottom: 3rem;
  }
}

.promo-cta {
  align-items: center;
}
@media (min-width: 992px) {
  .promo-cta {
    display: flex;
  }
}
.promo-cta .promo-cta-btn {
  margin-bottom: 1rem;
  white-space: pre;
}
@media (min-width: 992px) {
  .promo-cta .promo-cta-btn {
    margin-bottom: 0;
  }
}
.promo-cta .promo-cta-support-copy {
  margin-bottom: 0;
}

.testimonial {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 4rem;
}
@media (min-width: 576px) {
  .testimonial {
    max-width: 544px;
  }
}
@media (min-width: 768px) {
  .testimonial {
    max-width: 724px;
  }
}
@media (min-width: 992px) {
  .testimonial {
    max-width: 964px;
  }
}
@media (min-width: 1200px) {
  .testimonial {
    max-width: 1144px;
  }
}
@media (min-width: 768px) {
  .testimonial {
    justify-content: center;
  }
}
.testimonial__header {
  margin-bottom: 2rem;
  text-align: center;
}
@media (min-width: 768px) {
  .testimonial__list {
    background: linear-gradient(180deg, #FFF 0%, #F8F8F8 100%);
    display: flex;
    overflow-x: auto;
  }
}
@media (min-width: 768px) {
  .testimonial__list--center {
    justify-content: center;
  }
}
.testimonial__body {
  margin-bottom: 3rem;
  min-width: 100%;
  padding: 0 1rem;
}
@media (min-width: 768px) {
  .testimonial__body {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    min-width: auto;
    padding: 0 2rem;
  }
}
.testimonial__body--col-3 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.testimonial__author {
  display: flex;
  flex-wrap: wrap;
  font-family: "HCo Gotham SSm", "Verdana", "Geneva", sans-serif;
}
.testimonial__author-heading {
  margin-bottom: 0;
}
.testimonial__author-subheading {
  color: #333;
  text-transform: uppercase;
}
.testimonial__avatar {
  margin-right: 1rem;
}
.testimonial__avatar img {
  border-radius: 50%;
  height: 80px;
  width: 80px;
}
@media (min-width: 768px) {
  .testimonial__avatar img {
    height: 80px;
    width: 80px;
  }
}
@media (min-width: 768px) {
  .testimonial__content {
    padding-left: 85px;
  }
}
.testimonial__quote {
  border-top: 1px solid #ccc;
  font-family: "Merriweather", serif;
  font-size: 1.25rem;
  font-style: italic;
  padding-top: 1.5rem;
}

.tier-3-proposition {
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
  margin-left: -16px;
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
  margin-bottom: 4rem;
  padding: 1rem 2rem;
}
@media (min-width: 576px) {
  .tier-3-proposition {
    max-width: 544px;
  }
}
@media (min-width: 768px) {
  .tier-3-proposition {
    max-width: 724px;
  }
}
@media (min-width: 992px) {
  .tier-3-proposition {
    max-width: 964px;
  }
}
@media (min-width: 1200px) {
  .tier-3-proposition {
    max-width: 1144px;
  }
}
@media (min-width: 768px) {
  .tier-3-proposition {
    border-radius: 12px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
    padding: 3rem 2rem;
  }
}
.tier-3-proposition__bkgd {
  display: none;
}
@media (min-width: 768px) {
  .tier-3-proposition__bkgd {
    background: linear-gradient(to bottom, white 0%, #f8f8f8 50%, #f8f8f8 100%);
    bottom: 40px;
    display: block;
    min-height: 200px;
    position: absolute;
    width: 100%;
    z-index: -1;
  }
}
.tier-3-proposition__container {
  position: relative;
}
.tier-3-proposition__heading {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 1rem;
  text-align: center;
}
@media (min-width: 576px) {
  .tier-3-proposition__heading {
    max-width: 544px;
  }
}
@media (min-width: 768px) {
  .tier-3-proposition__heading {
    max-width: 724px;
  }
}
@media (min-width: 992px) {
  .tier-3-proposition__heading {
    max-width: 964px;
  }
}
@media (min-width: 1200px) {
  .tier-3-proposition__heading {
    max-width: 1144px;
  }
}
.tier-3-proposition__icon {
  margin-bottom: 1rem;
}
.tier-3-proposition__item {
  margin-bottom: 2rem;
  text-align: center;
}
@media (min-width: 768px) {
  .tier-3-proposition__item {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    padding: 0 1.5rem;
  }
}
.tier-3-proposition__link {
  text-align: center;
  width: 100%;
}
.tier-3-proposition__link p {
  margin-bottom: 0;
}

ol,
ul {
  counter-reset: item;
  list-style: none;
  margin-bottom: 1.5rem;
  padding-left: 2rem;
}
ol li,
ul li {
  position: relative;
}

ul li table {
  margin-top: 1rem;
}
ul > li::before {
  content: "•";
  counter-increment: item;
  font-weight: 700;
  left: -1.375rem;
  position: absolute;
  text-align: left;
}

ol > li::before {
  content: counter(item) ".";
  counter-increment: item;
  font-weight: 700;
  left: -1.5rem;
  position: absolute;
  text-align: right;
}

ol ol > li::before {
  content: counter(item, lower-alpha) ".";
}

ol ol ol > li::before {
  content: counter(item, lower-roman) ".";
}

.lower-alpha > li::before {
  content: counter(item, lower-alpha) ".";
}

.upper-alpha > li::before {
  content: counter(item, upper-alpha) ".";
}

.lower-alpha > li::before {
  content: counter(item, lower-alpha) ".";
}

.lower-roman > li::before {
  content: counter(item, lower-roman) ".";
}

.upper-roman > li::before {
  content: counter(item, upper-roman) ".";
}

.decimal > li::before {
  content: counter(item, decimal) ".";
}

.decimal-zero > li::before {
  content: counter(item, decimal-leading-zero) ".";
  left: -2rem;
}

.lower-greek > li::before {
  content: counter(item, lower-greek) ".";
}

.list-no-indent,
.list-style-none,
.list-unstyled {
  list-style: none;
  padding-left: 0;
}
.list-no-indent > li::before,
.list-style-none > li::before,
.list-unstyled > li::before {
  content: "";
  display: none;
}

.list-inline-item::before {
  content: "";
  display: none;
}

.link-lists {
  padding-left: 0;
  list-style: none;
}
.link-lists li {
  margin-bottom: 1rem;
}
.link-lists li::before {
  content: none;
}
.link-lists a,
.link-lists .btn-link,
.link-lists a:not([href])[data-toggle=modal] {
  background-image: none;
  background-position: inherit;
  background-repeat: inherit;
  background-size: inherit;
}
.link-lists a:visited,
.link-lists .btn-link:visited,
.link-lists a:not([href])[data-toggle=modal]:visited {
  color: #0170bc;
}
.link-lists a:hover,
.link-lists .btn-link:hover,
.link-lists a:not([href])[data-toggle=modal]:hover {
  background-image: linear-gradient(#004986, #004986);
  background-repeat: repeat-x;
  background-size: 1px 1px;
  color: #004986;
  transition: color 0.2s ease-in;
}
.link-lists a,
.link-lists a:not([href])[data-toggle=modal] {
  background-position: 0% 88%;
}
.link-lists .btn-link {
  background-position: 0% 100%;
  font-family: inherit;
  font-weight: 600;
  line-height: inherit;
  padding-bottom: 0;
}

.bio-block-container {
  background: #f8f8f8;
  border-radius: 0.75rem;
  padding: 2rem 1.5rem;
}
@media (min-width: 576px) {
  .bio-block-container {
    padding: 2rem 0;
  }
}
@media (min-width: 768px) {
  .bio-block-container {
    padding: 4rem 0;
  }
}
@media (min-width: 992px) {
  .bio-block-container {
    padding: 4.5rem 0;
  }
}
.bio-block-container .bio-block-image {
  margin-bottom: 1.5rem;
}
@media (min-width: 768px) {
  .bio-block-container .bio-block-image {
    display: table-cell;
    width: 128px;
  }
}
.bio-block-container .bio-block-image img {
  border-radius: 0.5rem;
  height: 128px;
  width: 128px;
}
@media (min-width: 768px) {
  .bio-block-container .bio-block-content {
    display: table-cell;
    padding-left: 2rem;
    vertical-align: middle;
  }
}
@media (min-width: 992px) {
  .bio-block-container .bio-block-content {
    padding-left: 4rem;
  }
}

.headshot-img {
  height: 92px;
  margin: 0 25px 0 0;
  width: 92px;
}
@media (min-width: 576px) {
  .headshot-img {
    margin: 9px 25px 9px 8px;
  }
}

.content-block-section > :last-child {
  margin-bottom: 1.5rem;
}
@media (max-width: 767.98px) {
  .content-block-section + .spacer {
    margin-top: 2.5rem;
  }
}

.five-six-content-block .spacer {
  border-top: 2px solid #e4e4e4;
  margin: 0 auto 3rem;
}
@media (max-width: 1199.98px) {
  .five-six-content-block .spacer {
    max-width: calc(100% - 30px);
  }
}
@media (min-width: 992px) {
  .five-six-content-block .spacer {
    border-right: 2px solid #e4e4e4;
    border-top: 0;
    margin: 0 4.1666666667% 4rem 0;
    max-width: 4.1666666667%;
    padding: 0;
  }
}

.content-block-section-image {
  z-index: 100;
}

.content-block-section-image-shadow {
  background-color: transparent;
  bottom: 0;
  box-shadow: 0 0.75rem 2.5rem 0 rgba(0, 0, 0, 0.3);
  height: 4rem;
  left: 2rem;
  opacity: 0;
  right: 2rem;
  z-index: 0;
}

.sticky-retrieve-quote {
  background-color: #e7f2e5;
  padding: 1rem 0;
  width: 100%;
}
.sticky-retrieve-quote__container {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .sticky-retrieve-quote__container {
    max-width: 544px;
  }
}
@media (min-width: 768px) {
  .sticky-retrieve-quote__container {
    max-width: 724px;
  }
}
@media (min-width: 992px) {
  .sticky-retrieve-quote__container {
    max-width: 964px;
  }
}
@media (min-width: 1200px) {
  .sticky-retrieve-quote__container {
    max-width: 1144px;
  }
}
.sticky-retrieve-quote__body {
  align-items: center;
  display: flex;
  justify-content: space-around;
}

.card--blue-10 {
  background-color: #e7f1f8;
}
.card--border-top-blue {
  border-top: 0.5rem solid #0170bc;
}
.card--radius-s {
  border-radius: 0.5rem;
}
.card--shadow {
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.3);
}

.block-selection__body {
  background-color: #e7f1f8;
  border-radius: 12px;
  margin-bottom: 4rem;
  padding: 1.5rem;
}
@media (min-width: 992px) {
  .block-selection__body {
    margin-bottom: 4.5rem;
  }
}
.block-selection__content {
  display: none;
  margin-bottom: 4rem;
}
.block-selection__item {
  background-color: #fff;
  background-image: none;
  border-radius: 8px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  color: #333;
  display: inline-block;
  font-family: "HCo Gotham SSm", "Verdana", "Geneva", sans-serif;
  font-weight: normal;
  margin-bottom: 2rem;
  min-height: 190px;
  padding: 1rem;
  text-align: center;
  transition: all 0.2s ease-in-out;
  width: 100%;
  word-break: break-word;
}
.block-selection__item:focus {
  background-color: #fff;
}
.block-selection__item:hover {
  background-image: none;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.4);
  margin-top: -10px;
  min-height: 200px;
  padding-top: calc(1rem + 10px);
  transition: all 0.2s ease-in-out;
}
.block-selection__item--active {
  border-bottom: 8px solid #f68621;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.4);
  margin-top: -10px;
  min-height: 200px;
  padding-top: calc(1rem + 10px);
}
.block-selection__icons {
  margin-bottom: 0.5rem;
}
.block-selection__icons svg {
  margin: 0 -10px;
}
.block-selection .checkbox-holder {
  margin-bottom: 0;
}

.quicklinks-block {
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .quicklinks-block {
    margin-bottom: 0;
  }
}
.quicklinks-block .content {
  background: #e7f1f8;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.3);
  color: #333;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  max-width: 100%;
  padding: 1.5rem 1rem;
  width: 100%;
}
.quicklinks-block .content header, .quicklinks-block .content p {
  margin-bottom: 0.5rem;
}

.hero {
  position: relative;
  z-index: 1;
}
.hero p {
  margin-bottom: 0.75rem;
}
.hero .lead-paragraph {
  font-size: 1rem;
  line-height: 1.5;
  line-height: 2rem;
  text-align: left;
}
@media (min-width: 992px) {
  .hero .lead-paragraph {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}
.hero h1 {
  color: #333;
  margin-bottom: 1rem;
  word-break: break-word;
}
.hero h2 {
  margin-bottom: 1rem;
  word-break: break-word;
}
@media (max-width: 991.98px) {
  .hero h1, .hero h2 {
    font-size: 32px;
  }
}
.hero .crumbs {
  padding: 1.5rem 0 0 0;
}

.hero-basic {
  padding-bottom: 3rem;
}
.hero-basic p.subhead-3 {
  font-size: 1.125rem;
  line-height: 1.7777777778;
}

.hero-padding {
  padding-top: 2rem;
}
@media (min-width: 576px) {
  .hero-padding {
    padding-top: 3rem;
  }
}

@media (min-width: 768px) {
  .hero-gradient {
    background: linear-gradient(180deg, white 0%, white 0%, #e6f1f8 100%, #e6f1f8 100%);
  }
}

.hero-gradient-all-sizes {
  background: linear-gradient(180deg, white 0%, white 0%, #e6f1f8 100%, #e6f1f8 100%);
}

.hero-long-intro {
  margin-bottom: 3rem;
  min-height: 384px;
}
@media (min-width: 768px) {
  .hero-long-intro {
    padding-bottom: 3rem;
  }
}
.hero-long-intro .lead-paragraph {
  margin-bottom: 0;
  padding: 0 16px;
}
.hero-long-intro .long-intro-image {
  margin-top: 1.5rem;
}
@media (min-width: 576px) {
  .hero-long-intro .long-intro-image {
    margin-top: 2rem;
  }
}
@media (min-width: 768px) {
  .hero-long-intro .long-intro-image {
    margin-top: 0;
  }
}
.hero-long-intro .long-intro-image .hero-image {
  border: 2px solid #d9d9d9;
}

.hero-floating-photo,
.hero-floating-photo-cta {
  margin-bottom: 3rem;
  padding-bottom: 3rem;
}
@media (min-width: 992px) {
  .hero-floating-photo,
.hero-floating-photo-cta {
    margin-bottom: 4.5rem;
  }
}
.hero-floating-photo .lead-paragraph,
.hero-floating-photo-cta .lead-paragraph {
  margin-bottom: 1.5rem;
}
.hero-floating-photo .hero-cta,
.hero-floating-photo-cta .hero-cta {
  margin-top: 0;
}
.hero-floating-photo .link-action,
.hero-floating-photo-cta .link-action {
  margin-bottom: 0;
}
@media (max-width: 767.98px) {
  .hero-floating-photo.hero-gradient,
.hero-floating-photo-cta.hero-gradient {
    background: linear-gradient(180deg, white 0%, white 0%, #e6f1f8 100%, #e6f1f8 100%);
    background-repeat: no-repeat;
  }
}

.hero-floating-photo-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
  margin-left: -16px;
}
@media (min-width: 768px) {
  .hero-floating-photo-row {
    display: block;
  }
  .hero-floating-photo-row::after {
    display: block;
    clear: both;
    content: "";
  }
}

.hero-floating-photo-image-wrapper {
  position: relative;
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-top: 2rem;
  order: 2;
}
@media (min-width: 768px) {
  .hero-floating-photo-image-wrapper {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    float: right;
    margin-top: 0;
    order: 1;
  }
}
@media (min-width: 992px) {
  .hero-floating-photo-image-wrapper {
    bottom: -40px;
    position: absolute;
    right: 0;
  }
}

.hero-floating-photo-image {
  max-width: 100%;
  height: auto;
  border: 2px solid #d9d9d9;
  border-radius: 0.75rem;
  box-shadow: 0 20px 36px -23px rgba(0, 0, 0, 0.65);
}

.hero-floating-photo-optional-content,
.hero-floating-photo-main-content {
  position: relative;
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
}
@media (min-width: 768px) {
  .hero-floating-photo-optional-content,
.hero-floating-photo-main-content {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    float: left;
  }
}

.hero-floating-photo-meta-copy,
.hero-floating-photo-legal-copy {
  font-size: 0.875rem;
  line-height: 1.4285714286;
}

@media (min-width: 1200px) {
  .hero-floating-photo-content-lead-in {
    width: 83.3333333333%;
  }
}

.hero-floating-photo-cta-support-copy {
  display: block;
  font-weight: 700;
  margin-top: 1rem;
}
@media (min-width: 576px) {
  .hero-floating-photo-cta-support-copy {
    display: inline-block;
    margin-left: 0.5rem;
  }
}

@media (min-width: 768px) {
  .hero-full-width-photo .lead-paragraph {
    margin-bottom: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .hero-full-width-photo .lead-paragraph {
    font-size: 1rem;
  }
}
@media (min-width: 1200px) {
  .hero-full-width-photo .lead-paragraph {
    margin-bottom: 1.5rem;
  }
}
@media (min-width: 992px) {
  .hero-full-width-photo .full-width-container {
    position: absolute;
  }
}
.hero-full-width-photo .full-width-row {
  margin: 0 auto;
  width: 100%;
}
.hero-full-width-photo .hero-full-w-content {
  max-width: 100%;
}
@media (min-width: 768px) {
  .hero-full-width-photo .hero-full-w-content {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.hero-bg-holder {
  background: linear-gradient(180deg, white 0%, white 0%, #efefef 100%, #efefef 100%);
  display: flex;
  justify-content: center;
  padding: 0;
}

@media (min-width: 576px) {
  .hero-cta {
    margin-top: 1rem;
  }
}
.hero-cta button, .hero-cta .call-link {
  display: block;
  line-height: 16px;
  margin-top: 1rem;
}
@media (min-width: 576px) {
  .hero-cta button, .hero-cta .call-link {
    display: inline-block;
    margin-top: 0;
  }
}
@media (min-width: 576px) {
  .hero-cta .call-link {
    margin-left: 0.5rem;
  }
}

.call-us-cta {
  margin-top: 1rem;
}
@media (min-width: 768px) {
  .call-us-cta {
    margin-top: 0.5rem;
  }
}
.call-us-cta a {
  background: none;
  color: #333;
  font-weight: 700;
  text-decoration: none;
}

.hero-white-left-box {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
}
@media (min-width: 576px) {
  .hero-white-left-box {
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .hero-white-left-box {
    max-width: 362px;
  }
}
@media (min-width: 992px) {
  .hero-white-left-box {
    max-width: 470px;
  }
}
@media (min-width: 1200px) {
  .hero-white-left-box {
    max-width: 470px;
  }
}
@media (min-width: 768px) {
  .hero-white-left-box {
    border: 2px solid #d9d9d9;
    border-radius: 0.75rem;
    box-shadow: 0 20px 36px -23px rgba(0, 0, 0, 0.65);
    margin-top: 0;
    min-height: 248px;
    padding: 1.5rem;
    position: absolute;
    top: 2rem;
    z-index: 2;
  }
}
@media (min-width: 992px) {
  .hero-white-left-box {
    min-height: 284px;
    padding: 2rem 1.5rem 2rem 2rem;
  }
}
@media (min-width: 1200px) {
  .hero-white-left-box {
    min-height: 328px;
    top: 3rem;
  }
}
@media (min-width: 768px) {
  .hero-white-left-box .hero-header {
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .hero-white-left-box .full-width-border {
    display: none;
  }
}

.hero-actions {
  align-items: flex-start;
  display: flex;
  flex-flow: column wrap;
  margin-bottom: 0;
  width: 100%;
}
@media (max-width: 767.98px) {
  .hero-actions {
    margin-bottom: 3rem;
  }
}
@media (min-width: 768px) {
  .hero-actions {
    flex-direction: row;
  }
}
.hero-actions > button,
.hero-actions > a {
  margin-bottom: 1rem;
  margin-right: 1rem;
}
.hero-actions > button:only-child,
.hero-actions > a:only-child {
  margin-bottom: 0;
  margin-right: 0;
}
.hero-actions > :last-child {
  margin-bottom: 0;
}

.hero-background-image {
  height: 144px;
  max-width: 576px;
  width: 100%;
}
@media (min-width: 576px) {
  .hero-background-image {
    height: 192px;
    max-width: 768px;
  }
}
@media (min-width: 768px) {
  .hero-background-image {
    height: calc(212px + 2rem);
    max-width: 992px;
  }
}
@media (min-width: 992px) {
  .hero-background-image {
    height: calc(272px + 2rem);
    max-width: 1200px;
  }
}
@media (min-width: 1200px) {
  .hero-background-image {
    height: calc(312px + 3rem);
    margin: 0 auto;
    max-width: 1440px;
  }
}

.hero-bg {
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 1440px;
  width: 100%;
}

.hero-image {
  border-radius: 0.75rem;
  box-shadow: 0 20px 36px -23px rgba(0, 0, 0, 0.65);
  width: 100%;
}

.hero-plain {
  margin-bottom: 2rem;
  padding-bottom: 3rem;
}
@media (min-width: 576px) {
  .hero-plain {
    margin-bottom: 3rem;
  }
}
.hero-plain p.subhead-3 {
  font-size: 1rem;
  line-height: 1.5;
}
@media (min-width: 992px) {
  .hero-plain p.subhead-3 {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}

.hero-tel,
.tel-hidden {
  background: none;
  color: #0170bc;
  text-decoration: none;
}
@media (min-width: 768px) {
  .hero-tel,
.tel-hidden {
    color: #333;
    cursor: default;
    pointer-events: none;
  }
}

.hero-icon {
  margin-bottom: 3rem;
  padding-bottom: 3rem;
}
.hero-icon .hero-icon-sub-content {
  display: flex;
}
.hero-icon .hero-icn {
  display: none;
}
@media (min-width: 768px) {
  .hero-icon .hero-icn {
    display: inline-flex;
    margin: 0 3rem 0 0;
    min-width: 144px;
  }
}
.hero-icon .block-icn {
  display: none;
}
@media (min-width: 576px) {
  .hero-icon .block-icn {
    display: inline-flex;
    margin: 0 2rem 0 0;
    min-width: 88px;
  }
}
@media (min-width: 768px) {
  .hero-icon .block-icn {
    display: none;
  }
}

.menu-language {
  padding: 3rem 1rem 3rem;
}
@media (min-width: 768px) {
  .menu-language {
    display: none;
  }
}
.menu-language__item {
  margin-bottom: 1rem;
}
.menu-language__link:not(.active) {
  color: #333;
}
.menu-language svg {
  vertical-align: text-bottom;
}

.menu__list {
  margin-bottom: 4rem;
}
@media (min-width: 992px) {
  .menu__list {
    display: none !important;
  }
}
.menu__list.collapse, .menu__list.collapsing {
  margin-bottom: 0;
}
.menu__list.show {
  height: calc(100vh - 71px);
  overflow-y: scroll;
}
.menu__list a {
  background-image: none;
}
.menu__item {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
@media (min-width: 576px) {
  .menu__item {
    padding: 1rem;
  }
}
.menu__item:first-child {
  border-top: 0;
}
.menu__text {
  min-height: 40px;
}
.menu__link {
  background-color: transparent;
  border: 0;
  color: #333;
  font-family: "HCo Gotham SSm", "Verdana", "Geneva", sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
  padding-left: 0;
  padding-right: 0;
  text-align: left;
  width: 100%;
}
@media (max-width: 767.98px) {
  .menu__link {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}
.menu__link--mobile {
  color: #333;
}
.menu__link svg {
  float: right;
}
.menu__link.collapsed svg {
  transform: rotate(180deg);
}
.menu__content {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 2rem 1.5rem;
}
.menu__search {
  padding: 2rem 1rem 1rem;
}

.menu__search + .menu__item {
  border-top: 0;
}

.bottom-menu {
  background-color: #f8f8f8;
  padding: 2rem 0;
}
@media (min-width: 768px) {
  .bottom-menu {
    display: none;
  }
}
.bottom-menu__language {
  padding: 1rem;
}
.bottom-menu .menu__link {
  color: #333;
}
.bottom-menu .menu__link:focus {
  background-color: transparent;
  outline: none;
}
.bottom-menu__dropmenu {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  font-size: 1rem;
  margin: 0.125rem 0 0;
  padding: 0.5rem 0;
  text-align: left;
  width: calc(100% - 30px);
}
.bottom-menu .dropdown.show .language-menu-icn {
  transform: rotate(180deg);
}
.bottom-menu label {
  font-family: "HCo Gotham SSm", "Verdana", "Geneva", sans-serif;
}

.dropdown-menu {
  display: none;
}
.dropdown-menu.show {
  display: block;
}

.dropmenu {
  background-color: #fff;
  border: 1px solid rgba(147, 148, 150, 0.75);
  border-radius: 0.5rem;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  margin: 0.5rem 0;
  max-width: 375px;
  padding: 0.25rem 0 0;
  z-index: 1000;
}
.dropmenu::before {
  background-color: #fff;
  border-color: rgba(147, 148, 150, 0.75) transparent transparent rgba(147, 148, 150, 0.75);
  border-style: solid;
  border-width: 1px;
  content: "";
  height: 1rem;
  left: calc(50% - 0.5rem);
  padding: 0;
  position: absolute;
  top: -0.5rem;
  transform: rotate(45deg);
  transform-origin: center;
  width: 1rem;
  z-index: 1000;
}
.dropmenu__item {
  margin: 1rem 1.5rem;
}
.dropmenu__item > * + * {
  margin-top: 0.5rem;
}
.dropmenu__link {
  white-space: nowrap;
}
.dropmenu__description {
  font-size: 0.875rem;
  line-height: 1.4285714286;
}

.table th,
.table td {
  padding: inherit;
}

.table tr {
  padding: 1rem 0 1rem 0.5rem;
}
.table thead {
  background-color: #e7f1f8;
}
.table thead th:first-child {
  border-radius: 0.3rem 0 0;
}
.table thead th:last-child {
  border-radius: 0 0.3rem 0 0;
}
.table thead th {
  border-top: inherit;
}
.table tbody {
  /* stylelint-disable-next-line selector-max-compound-selectors */
}
.table tbody th {
  background-color: #f8f8f8;
}
.table tbody td p:last-child {
  margin-bottom: 0;
}

.table-responsive th,
.table-responsive-sm th,
.table-responsive-md th,
.table-responsive-lg th,
.table-responsive-xl th {
  border-top-color: #e4e4e4;
}
.table-responsive thead,
.table-responsive-sm thead,
.table-responsive-md thead,
.table-responsive-lg thead,
.table-responsive-xl thead {
  background-color: #e7f1f8;
}
.table-responsive thead th,
.table-responsive-sm thead th,
.table-responsive-md thead th,
.table-responsive-lg thead th,
.table-responsive-xl thead th {
  border-top-color: #e7f1f8;
}
.table-responsive tbody th,
.table-responsive-sm tbody th,
.table-responsive-md tbody th,
.table-responsive-lg tbody th,
.table-responsive-xl tbody th {
  background-color: #f8f8f8;
}

ol,
ul {
  counter-reset: item;
  list-style: none;
  margin-bottom: 1.5rem;
  padding-left: 2rem;
}
ol li,
ul li {
  position: relative;
}

ul li table {
  margin-top: 1rem;
}
ul > li::before {
  content: "•";
  counter-increment: item;
  font-weight: 700;
  left: -1.375rem;
  position: absolute;
  text-align: left;
}

ol > li::before {
  content: counter(item) ".";
  counter-increment: item;
  font-weight: 700;
  left: -1.5rem;
  position: absolute;
  text-align: right;
}

ol ol > li::before {
  content: counter(item, lower-alpha) ".";
}

ol ol ol > li::before {
  content: counter(item, lower-roman) ".";
}

.lower-alpha > li::before {
  content: counter(item, lower-alpha) ".";
}

.upper-alpha > li::before {
  content: counter(item, upper-alpha) ".";
}

.lower-alpha > li::before {
  content: counter(item, lower-alpha) ".";
}

.lower-roman > li::before {
  content: counter(item, lower-roman) ".";
}

.upper-roman > li::before {
  content: counter(item, upper-roman) ".";
}

.decimal > li::before {
  content: counter(item, decimal) ".";
}

.decimal-zero > li::before {
  content: counter(item, decimal-leading-zero) ".";
  left: -2rem;
}

.lower-greek > li::before {
  content: counter(item, lower-greek) ".";
}

.list-no-indent,
.list-style-none,
.list-unstyled {
  list-style: none;
  padding-left: 0;
}
.list-no-indent > li::before,
.list-style-none > li::before,
.list-unstyled > li::before {
  content: "";
  display: none;
}

.list-inline-item::before {
  content: "";
  display: none;
}

.social-media-icn {
  border-radius: 0.25rem;
  height: 2rem;
  margin-right: 0.25rem;
  width: 2rem;
}

.social-media-icn svg {
  height: 1.75rem;
  width: 1.75rem;
}

.top-footer-nav-heading {
  font-weight: 700;
  margin-bottom: 1.5rem;
}

.top-footer-nav-item {
  margin-bottom: 0.75rem;
}
.top-footer-nav-item:last-child {
  margin-bottom: -0.25rem;
}

.top-footer-nav-link {
  background-image: none;
  background-position: 0% 89%;
  background-repeat: repeat-x;
  background-size: 1px 1px;
  color: #0170bc;
  cursor: pointer;
  font-weight: 600;
  padding-bottom: 4px;
}
.top-footer-nav-link:visited {
  color: #0170bc;
}
.top-footer-nav-link:hover {
  background-image: linear-gradient(#004986, #004986);
  color: #004986;
}

.social-media-group {
  margin: 0;
  margin-top: 3rem;
  padding: 0;
}
.social-media-group .social-icons-holder {
  flex-wrap: wrap;
}

.social-media-link,
.social-media-group .top-footer-nav-link {
  background-image: none;
  line-height: 1;
  margin-right: 0.5rem;
  padding-bottom: 0;
}
.social-media-link:link, .social-media-link:visited,
.social-media-group .top-footer-nav-link:link,
.social-media-group .top-footer-nav-link:visited {
  text-decoration: none;
}

.social-media-link:hover {
  background-image: none;
}

.social-media-links-header {
  color: #333;
  font-size: 0.875rem;
  margin: 0;
  margin-bottom: 1rem;
}

.top-footer-row, .bottom-footer-row {
  padding-bottom: 3rem;
  padding-top: 3rem;
}
@media (min-width: 576px) {
  .top-footer-row, .bottom-footer-row {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (min-width: 992px) {
  .top-footer-row, .bottom-footer-row {
    padding: 4rem 0;
  }
}

.top-footer-column {
  margin-bottom: 3rem;
}
@media (min-width: 992px) {
  .top-footer-column {
    margin-bottom: 0;
  }
}

.bottom-footer-nav-item {
  margin-bottom: 0.75rem;
}
.bottom-footer-nav-item:last-child {
  margin-bottom: -0.25rem;
}
.bottom-footer-nav-item a {
  background-image: none;
}

.bottom-footer hr {
  border-color: #505050;
}
.bottom-footer p a, .bottom-footer-nav-link, .bottom-footer-external-link {
  background-image: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3));
  background-position: 0% 89%;
  background-repeat: repeat-x;
  background-size: 1px 1px;
  color: #e4e4e4;
  cursor: pointer;
  font-weight: 600;
  padding-bottom: 4px;
}
.bottom-footer p a:visited, .bottom-footer-nav-link:visited, .bottom-footer-external-link:visited {
  color: #e4e4e4;
}
.bottom-footer p a:hover, .bottom-footer-nav-link:hover, .bottom-footer-external-link:hover {
  background-image: linear-gradient(#fff, #fff);
  color: #fff;
}
.bottom-footer p a:focus, .bottom-footer-nav-link:focus, .bottom-footer-external-link:focus {
  background-color: transparent;
  background-image: linear-gradient(#fff, #fff);
  color: #fff;
}
.bottom-footer .bottom-navigation {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.bottom-footer .bottom-navigation .bottom-nav-link {
  color: #fff;
  padding: 0 0.5rem;
}
@media (min-width: 768px) {
  .bottom-footer .bottom-navigation .bottom-nav-link {
    padding: 0 1rem;
  }
}
.bottom-footer .bottom-navigation li:not(:last-child) {
  /* stylelint-disable-next-line */
}
.bottom-footer .bottom-navigation li:not(:last-child) .bottom-nav-link {
  border-right: 1px solid #fff;
}
.bottom-footer .bottom-nav-container {
  /* stylelint-disable-next-line */
}
@media (min-width: 768px) {
  .bottom-footer .bottom-nav-container {
    align-items: center;
    display: flex;
  }
  .bottom-footer .bottom-nav-container .bottom-navigation {
    margin-bottom: 0;
  }
}
.bottom-footer .bottom-nav-container .logo svg {
  height: 40px;
  width: 120px;
}

.article {
  font-family: "HCo Gotham SSm", "Verdana", "Geneva", sans-serif;
}
.article__author {
  color: #939496;
  font-family: "HCo Gotham SSm", "Verdana", "Geneva", sans-serif;
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
}
.article__row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
  margin-left: -16px;
}
.article__body {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 3rem;
  position: relative;
}
@media (min-width: 576px) {
  .article__body {
    max-width: 544px;
  }
}
@media (min-width: 768px) {
  .article__body {
    max-width: 724px;
  }
}
@media (min-width: 992px) {
  .article__body {
    max-width: 964px;
  }
}
@media (min-width: 1200px) {
  .article__body {
    max-width: 1144px;
  }
}
@media (min-width: 768px) {
  .article__body {
    max-width: calc(991px + 70px);
    padding: 0 70px;
  }
}
.article__posts {
  margin-bottom: 1.5rem;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 576px) {
  .article__posts {
    max-width: 544px;
  }
}
@media (min-width: 768px) {
  .article__posts {
    max-width: 724px;
  }
}
@media (min-width: 992px) {
  .article__posts {
    max-width: 964px;
  }
}
@media (min-width: 1200px) {
  .article__posts {
    max-width: 1144px;
  }
}
@media (min-width: 768px) {
  .article__posts {
    max-width: calc(991px + 70px);
    padding: 0 70px;
  }
}
.article__posts-title {
  font-family: "HCo Gotham SSm", "Verdana", "Geneva", sans-serif;
  font-size: 1.75rem;
  margin-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
@media (min-width: 576px) {
  .article__posts-title {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.article__tags {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2rem;
}
@media (min-width: 576px) {
  .article__tags {
    max-width: 544px;
  }
}
@media (min-width: 768px) {
  .article__tags {
    max-width: 724px;
  }
}
@media (min-width: 992px) {
  .article__tags {
    max-width: 964px;
  }
}
@media (min-width: 1200px) {
  .article__tags {
    max-width: 1144px;
  }
}
@media (min-width: 768px) {
  .article__tags {
    max-width: calc(991px + 70px);
    padding: 0 70px;
  }
}
.article__tags div {
  align-items: center;
  background-color: #f8f8f8;
  border-color: transparent;
  border-radius: 0.5rem;
  color: #505050;
  display: inline-flex;
  font-size: 0.75rem;
  /*font-weight: $font-weight-regular;*/
  justify-content: center;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  padding: 0.5rem 1rem;
  text-align: center;
}
.article__tags div {
  align-items: center;
  background-color: #f8f8f8;
  border-color: transparent;
  border-radius: 0.5rem;
  color: #505050;
  display: inline-flex;
  font-size: 0.75rem;
  justify-content: center;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  padding: 0.5rem 1rem;
  text-align: center;
}
.article__share {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2rem;
}
@media (min-width: 576px) {
  .article__share {
    max-width: 544px;
  }
}
@media (min-width: 768px) {
  .article__share {
    max-width: 724px;
  }
}
@media (min-width: 992px) {
  .article__share {
    max-width: 964px;
  }
}
@media (min-width: 1200px) {
  .article__share {
    max-width: 1144px;
  }
}
@media (min-width: 768px) {
  .article__share {
    max-width: calc(991px + 70px);
    padding: 0 70px;
  }
}
.article__guide-scroll {
  align-items: center;
  color: #e4e4e4;
  display: flex;
  flex-direction: column;
  font-family: "HCo Gotham SSm", "Verdana", "Geneva", sans-serif;
  font-size: 0.875rem;
  margin-bottom: 2rem;
}
.article__sources {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 4rem;
  overflow-wrap: break-word;
}
@media (min-width: 576px) {
  .article__sources {
    max-width: 544px;
  }
}
@media (min-width: 768px) {
  .article__sources {
    max-width: 724px;
  }
}
@media (min-width: 992px) {
  .article__sources {
    max-width: 964px;
  }
}
@media (min-width: 1200px) {
  .article__sources {
    max-width: 1144px;
  }
}
@media (min-width: 768px) {
  .article__sources {
    max-width: calc(991px + 70px);
    padding: 0 70px;
  }
}
.article__sources :first-child {
  margin-top: 1rem;
}
.article__sources header {
  font-weight: 600;
  margin-bottom: 1rem;
  text-transform: uppercase;
}
.article__sources p {
  margin-bottom: 0.5rem;
}
.article__sources a {
  background-image: none;
}
.article__footer {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 4rem;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 576px) {
  .article__footer {
    max-width: 544px;
  }
}
@media (min-width: 768px) {
  .article__footer {
    max-width: 724px;
  }
}
@media (min-width: 992px) {
  .article__footer {
    max-width: 964px;
  }
}
@media (min-width: 1200px) {
  .article__footer {
    max-width: 1144px;
  }
}
@media (min-width: 768px) {
  .article__footer {
    max-width: calc(991px + 70px);
    padding: 0 70px;
  }
}
.article__footer-body {
  border-top: 1px solid #e4e4e4;
  color: #e4e4e4;
}
.article__footer-body p:first-child {
  margin-top: 1rem;
}
.article__footer-body p:last-child {
  margin-bottom: 2rem;
}

.post {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
  font-family: "HCo Gotham SSm", "Verdana", "Geneva", sans-serif;
}
@media (min-width: 576px) {
  .post {
    max-width: 544px;
  }
}
@media (min-width: 768px) {
  .post {
    max-width: 724px;
  }
}
@media (min-width: 992px) {
  .post {
    max-width: 964px;
  }
}
@media (min-width: 1200px) {
  .post {
    max-width: 1144px;
  }
}
.post__row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
  margin-left: -16px;
}
.post__body {
  margin-bottom: 4rem;
}
.post__category {
  text-transform: uppercase;
}
.post__date {
  margin-right: 5px;
  text-transform: uppercase;
}
.post__heading {
  margin-bottom: 1.5rem;
}
.post__sub {
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.post__image {
  border-radius: 0.75rem;
  margin-bottom: 1.5rem;
  overflow: hidden;
}
.post__link {
  float: right;
}
.post--col2 {
  position: relative;
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
}
@media (min-width: 768px) {
  .post--col2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.post--col3 {
  position: relative;
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
}
@media (min-width: 768px) {
  .post--col3 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}
.post img {
  width: 100%;
}

.post-filter {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
  font-family: "HCo Gotham SSm", "Verdana", "Geneva", sans-serif;
  margin-bottom: 3rem;
}
@media (min-width: 576px) {
  .post-filter {
    max-width: 544px;
  }
}
@media (min-width: 768px) {
  .post-filter {
    max-width: 724px;
  }
}
@media (min-width: 992px) {
  .post-filter {
    max-width: 964px;
  }
}
@media (min-width: 1200px) {
  .post-filter {
    max-width: 1144px;
  }
}
.post-filter__reset-button {
  background-image: none;
  color: #0170bc;
}
.post-filter__reset-button:hover {
  background-image: none;
}
.post-filter__reset-button:focus {
  background: none;
}
.post-filter__reset-button--mobile-filter {
  padding-top: 0;
  text-align: end;
  width: 100%;
}
.post-filter__mobile-button {
  background-color: transparent;
  border: 1px solid #0170bc;
  border-radius: 0;
  color: #0170bc;
  display: flex;
  justify-content: center;
  width: 100%;
}
.post-filter__mobile-button:active {
  box-shadow: none !important;
}
.post-filter__mobile-button:focus {
  box-shadow: none;
}
.post-filter__header {
  align-items: center;
  display: flex;
  margin-bottom: 1.5rem;
}
.post-filter__body {
  margin-bottom: 6rem;
}
@media (min-width: 768px) {
  .post-filter__body {
    display: flex;
    flex-wrap: wrap;
    margin-right: -16px;
    margin-left: -16px;
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .post-filter__item {
    position: relative;
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}
.post-filter__item .checkbox-label--mobile {
  border-bottom: 1px solid #939496;
  padding: 0.5rem 1.5rem 1.5rem;
  position: relative;
}
.post-filter__item .checkbox-label--mobile .custom-checkbox {
  left: inherit;
  right: 1.5rem;
  top: 1.2rem;
}
.post-filter__heading {
  margin-bottom: 0;
  margin-right: 1rem;
}
@media (max-width: 767.98px) {
  .post-filter--desktop-only {
    display: none;
  }
}
.post-filter--mobile-only {
  display: flex;
  justify-content: center;
  margin: 1rem 0 3rem;
}
@media (min-width: 576px) {
  .post-filter--mobile-only {
    display: none;
  }
}

.post-horizontal {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
}
.post-horizontal__body {
  border-top: 1px solid #939496;
  display: inline-block;
  padding: 3rem 0;
}

.post-horizontal-image {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
}
.post-horizontal-image__body {
  display: flex;
  margin-bottom: 3rem;
}
@media (max-width: 767.98px) {
  .post-horizontal-image__body {
    flex-direction: column;
  }
}
.post-horizontal-image img {
  border-radius: 0.75rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  max-width: 100px;
  min-width: 100px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .post-horizontal-image img {
    margin-bottom: 0;
    max-width: 200px;
    min-width: 200px;
  }
}
.post-horizontal-image__link {
  margin-bottom: 1rem;
}

.filter-flag {
  align-items: center;
  background-color: #f8f8f8;
  border-color: transparent;
  border-radius: 0.5rem;
  color: #505050;
  display: inline-flex;
  font-size: 0.75rem;
  /*font-weight: $font-weight-regular;*/
  justify-content: center;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  padding: 0.5rem 1rem;
  text-align: center;
}
.filter-flag__icon {
  cursor: pointer;
  fill: currentColor;
  height: 1rem;
  margin-left: 1rem;
  pointer-events: visible;
  width: 1rem;
}
.filter-flag:last-child {
  margin-bottom: 3rem;
}

.article .compat-object-fit {
  background-position: center center;
  background-size: cover;
  width: 100%;
}

.article__hero-basic {
  margin-bottom: 4rem;
  position: relative;
}
.article__hero-basic img {
  height: 600px;
  object-fit: cover;
  width: 100%;
}
.article__hero-basic--rounded {
  border-radius: 0.75rem;
}
.article__hero-basic--variation-small {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .article__hero-basic--variation-small {
    max-width: 768px;
  }
}
.article__hero-basic--variation-small img, .article__hero-basic--variation-small .compat-object-fit {
  height: 385px;
}
.article__hero-basic--variation-medium {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .article__hero-basic--variation-medium {
    max-width: calc(991px + 70px);
    padding: 0 70px;
  }
}
.article__hero-basic--variation-medium img, .article__hero-basic--variation-medium .compat-object-fit {
  height: 440px;
}

.post {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
  font-family: "HCo Gotham SSm", "Verdana", "Geneva", sans-serif;
}
@media (min-width: 576px) {
  .post {
    max-width: 544px;
  }
}
@media (min-width: 768px) {
  .post {
    max-width: 724px;
  }
}
@media (min-width: 992px) {
  .post {
    max-width: 964px;
  }
}
@media (min-width: 1200px) {
  .post {
    max-width: 1144px;
  }
}
.post__row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
  margin-left: -16px;
}
.post__body {
  margin-bottom: 4rem;
}
.post__category {
  text-transform: uppercase;
}
.post__date {
  color: #939496;
  margin-right: 5px;
  text-transform: uppercase;
}
.post__heading {
  color: #333;
  margin-bottom: 1.5rem;
}
.post__sub {
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.post__image {
  border-radius: 0.75rem;
  margin-bottom: 1.5rem;
  overflow: hidden;
}
.post--col2 {
  position: relative;
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
}
@media (min-width: 768px) {
  .post--col2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.post--col3 {
  position: relative;
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
}
@media (min-width: 768px) {
  .post--col3 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}
.post img {
  width: 100%;
}

.post-filter {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
  /*font-family: $font-family-sans-serif-2;*/
  margin-bottom: 3rem;
}
@media (min-width: 576px) {
  .post-filter {
    max-width: 544px;
  }
}
@media (min-width: 768px) {
  .post-filter {
    max-width: 724px;
  }
}
@media (min-width: 992px) {
  .post-filter {
    max-width: 964px;
  }
}
@media (min-width: 1200px) {
  .post-filter {
    max-width: 1144px;
  }
}
.post-filter__reset-button {
  color: #0170bc;
}
.post-filter__reset-button:active {
  box-shadow: none !important;
}
.post-filter__reset-button:focus {
  box-shadow: none;
}
.post-filter__reset-button:hover {
  color: #004986;
}
.post-filter__reset-button--mobile-filter {
  padding-top: 0;
  text-align: end;
  width: 100%;
}
.post-filter__mobile-button {
  background-color: transparent;
  border: 1px solid #0170bc;
  border-radius: 0;
  color: #0170bc;
  display: flex;
  justify-content: center;
  width: 100%;
}
.post-filter__mobile-button:active {
  box-shadow: none !important;
}
.post-filter__mobile-button:focus {
  box-shadow: none;
}
.post-filter__header {
  align-items: center;
  display: flex;
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .post-filter__body {
    display: flex;
    flex-wrap: wrap;
    margin-right: -16px;
    margin-left: -16px;
  }
}
@media (min-width: 768px) {
  .post-filter__item {
    position: relative;
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}
.post-filter__item:last-child [class*="--mobile-filter"]:last-child {
  margin-bottom: 6rem;
}
.post-filter__heading {
  margin-bottom: 0;
  margin-right: 1rem;
}
@media (max-width: 767.98px) {
  .post-filter--desktop-only {
    display: none;
  }
}
.post-filter--mobile-only {
  display: flex;
  justify-content: center;
  margin: 1rem 0 3rem;
}
@media (min-width: 576px) {
  .post-filter--mobile-only {
    display: none;
  }
}

.post-horizontal {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
}
.post-horizontal__body {
  border-top: 1px solid #939496;
  display: inline-block;
  padding: 3rem 0;
}

.post-horizontal-image {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
}
.post-horizontal-image__body {
  display: flex;
  margin-bottom: 3rem;
}
@media (max-width: 767.98px) {
  .post-horizontal-image__body {
    flex-direction: column;
  }
}
.post-horizontal-image img {
  border-radius: 0.75rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  max-width: 100px;
  min-width: 100px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .post-horizontal-image img {
    margin-bottom: 0;
    max-width: 200px;
    min-width: 200px;
  }
}
.post-horizontal-image__link {
  margin-bottom: 1rem;
}

.page-header {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 4rem;
  padding-top: 3rem;
}
@media (min-width: 768px) {
  .page-header {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .page-header {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .page-header {
    max-width: 1180px;
  }
}
.page-header--article {
  margin-bottom: 2rem;
  padding-left: 70px;
  padding-right: 70px;
  text-align: center;
}
.page-header--basic {
  margin-bottom: 4rem;
}
.page-header--two-col {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .page-header--two-col {
    flex-direction: row;
  }
}
.page-header--two-col > * + * {
  margin-top: 1rem;
}
@media (min-width: 768px) {
  .page-header--two-col > * + * {
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .page-header--two-col .page-header__col--first {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    padding-right: 10px;
  }
}
@media (min-width: 768px) {
  .page-header--two-col .page-header__col--second {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    padding-left: 10px;
  }
}
@media (min-width: 992px) {
  .page-header--two-col .page-header__col--second {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-left: 8.3333333333%;
  }
}
.page-header__content {
  display: flex;
  flex-direction: column;
}
.page-header__heading {
  margin-bottom: 1rem;
}
.page-header__subheading {
  line-height: 1.2307692308;
  margin-bottom: 0.5rem;
}
@media (min-width: 576px) {
  .page-header__subheading {
    font-size: 2rem;
    line-height: 1.1944444444;
  }
}
.page-header__meta {
  font-family: "Merriweather", serif;
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 400;
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .page-header__meta {
    margin-bottom: 1.5rem;
  }
}
.page-header__meta--article {
  font-family: "HCo Gotham SSm", "Verdana", "Geneva", sans-serif;
  font-style: normal;
}
.page-header__meta--date {
  color: #939496;
}
.page-header__image {
  max-width: 100%;
  height: auto;
  align-self: flex-start;
  border-radius: 0.75rem;
}
@media (min-width: 768px) {
  .page-header__image {
    margin-top: 0;
    max-height: 271px;
    width: 481px;
  }
}
.page-header__list {
  list-style: none;
  padding-left: 0;
}
.page-header__list > * + * {
  padding-top: 1rem;
}
.page-header__list-item {
  align-items: center;
  display: flex;
}
.page-header__checkmark {
  align-self: flex-start;
  flex: 0 0 2rem;
  height: 2rem;
  margin-right: 1rem;
  width: 2rem;
}
@media (min-width: 768px) {
  .page-header__text--article {
    margin: auto;
    width: 100%;
  }
}

.page-header-full-width-image {
  margin-bottom: 4rem;
}
.page-header-full-width-image__wrapper {
  overflow: hidden;
  position: relative;
}
@media (min-width: 768px) {
  .page-header-full-width-image__banner {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }
}
.page-header-full-width-image__card {
  align-items: center;
  display: flex;
  height: auto;
  justify-content: center;
  max-height: 300px;
}
@media (min-width: 768px) {
  .page-header-full-width-image__card {
    max-height: 350px;
  }
}
@media (min-width: 992px) {
  .page-header-full-width-image__card {
    max-height: 400px;
  }
}
@media (min-width: 1200px) {
  .page-header-full-width-image__card {
    max-height: 450px;
  }
}
.page-header-full-width-image__image-holder {
  margin-left: auto;
  margin-right: auto;
}
.page-header-full-width-image__hero-image {
  min-width: 1px;
}
.page-header-full-width-image__background {
  bottom: -3px;
  height: 200px;
  left: 0;
  position: absolute;
  top: auto;
  width: 100%;
}
@media (min-width: 768px) {
  .page-header-full-width-image__background {
    height: auto;
  }
}
.page-header-full-width-image__background--accent-blue {
  background-color: rgba(1, 112, 188, 0.85);
  border-bottom: 2px solid #004986;
}
.page-header-full-width-image__foreground {
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem 1rem;
  position: absolute;
  top: 0;
  width: 100%;
}
@media (min-width: 768px) {
  .page-header-full-width-image__foreground {
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
    margin-right: auto;
    margin-left: auto;
    min-height: 150px;
    position: relative;
  }
}
@media (min-width: 768px) and (min-width: 576px) {
  .page-header-full-width-image__foreground {
    max-width: 544px;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .page-header-full-width-image__foreground {
    max-width: 724px;
  }
}
@media (min-width: 768px) and (min-width: 992px) {
  .page-header-full-width-image__foreground {
    max-width: 964px;
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .page-header-full-width-image__foreground {
    max-width: 1144px;
  }
}
@media (min-width: 768px) {
  .page-header-full-width-image__foreground p {
    font-size: 1rem;
  }
}
@media (min-width: 768px) {
  .page-header-full-width-image__foreground h3 {
    font-size: 1.75rem;
  }
}
.page-header-full-width-image__foreground p {
  font-size: 0.75rem;
  font-weight: normal;
  margin: 0;
}
.page-header-full-width-image__foreground h3 {
  font-size: 1rem;
  font-weight: normal;
}
.page-header-full-width-image__foreground--title-only p {
  display: none;
}
.page-header-full-width-image__foreground--title-only h3 {
  font-size: 1.75rem;
}

.ls-canvas {
  padding-top: 54px;
}
@media (min-width: 576px) {
  .ls-canvas {
    padding-top: 72px;
  }
}
@media (min-width: 992px) {
  .ls-canvas {
    padding-top: 0;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.global-nav {
  background: #fff;
}
@media (max-width: 991.98px) {
  .global-nav {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .global-nav {
    padding: 0;
  }
}
@media (min-width: 992px) {
  .global-nav {
    padding-bottom: 0;
  }
}
.global-nav ul {
  margin: 0;
}
.global-nav ul li {
  padding: 0;
}
.global-nav ul li::before {
  content: none;
}
.global-nav a:not(.header-link) {
  background-image: none;
}
.global-nav a:not(.header-link):not(.btn):focus {
  background-color: transparent;
}
.global-nav .nav .nav-link, .global-nav .navbar-nav .nav-link {
  font-weight: normal;
}
.global-nav .navbar-nav .nav-link {
  background-image: none;
}
.global-nav .input-group {
  background-color: #f8f8f8;
  width: 170px;
}
@media (min-width: 992px) {
  .global-nav .input-group {
    width: auto;
  }
}
.global-nav .input-group-search {
  border-radius: 0.5rem;
  box-shadow: inset 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
}
@media (min-width: 992px) {
  .global-nav .input-group-btn {
    padding-right: 0.5rem;
  }
}
.global-nav .input-group-btn:not(:first-child) > .btn:first-child,
.global-nav .input-group-btn:not(:first-child) > .btn-group:first-child {
  background-color: transparent;
  padding-left: 0.5rem;
  padding-right: 0.25rem;
}
.global-nav input[type=search] {
  background-color: transparent;
  border: 0;
  color: #505050;
  /* Styles added to fix the issue with zoom in on iphone */
}
@media (min-width: 992px) {
  .global-nav input[type=search] {
    padding: 0.5rem 1.5rem;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .global-nav input[type=search] {
    font-size: 16px;
  }
}
.global-nav .accordion ul {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 0;
}
.global-nav .accordion li {
  margin-bottom: 1rem;
}
.global-nav .accordion li:last-child {
  margin-bottom: 2rem;
}
.global-nav .accordion a {
  background-image: none;
  font-weight: normal;
  padding-bottom: 0;
}
.global-nav .accordion .card-title {
  font-family: "HCo Gotham SSm", "Verdana", "Geneva", sans-serif;
  font-weight: 500;
  margin-left: 0.5rem;
}
.global-nav .card-header {
  padding: 1rem 0;
}
.global-nav .card-header::before {
  height: 0.5rem;
  width: 0.5rem;
  content: "";
  display: block;
  float: left;
}
.global-nav .card-header.second-level {
  border-bottom: 0;
}
.global-nav .card-header.second-level::before {
  transform: rotate(90deg) translateY(50%);
  transform-origin: center bottom;
  transition: 0.2s transform ease-in-out;
}
.global-nav .card-header.collapsed {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.global-nav .card-header.collapsed::before {
  transform: rotate(0deg) translateY(50%);
  transform-origin: center bottom;
  transition: 0.2s transform ease-in-out;
}
.global-nav .card-header.collapsed::before, .global-nav .card-header.second-level::before {
  height: 0;
  width: 0;
  border-bottom: 0.35rem solid transparent;
  border-left: 0.5rem solid #0170bc;
  border-top: 0.35rem solid transparent;
  content: " ";
  display: inline-block;
}
.global-nav .navbar-toggler {
  font-weight: 400;
}
.global-nav .navbar-brand {
  padding-bottom: 0;
  padding-top: 0;
}
.global-nav .navbar-brand > svg {
  max-height: 38px;
  max-width: 111px;
}
@media (min-width: 576px) {
  .global-nav .navbar-brand > svg {
    max-height: 56px;
    max-width: 163px;
  }
}
@media (min-width: 992px) {
  .global-nav .navbar-brand > svg {
    max-height: 66px;
    max-width: 192px;
  }
}
@media (max-width: 1199.98px) {
  .global-nav .navbar-collapse {
    background-image: linear-gradient(-155deg, #fff 3%, #efefef 100%);
    bottom: 0;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 2;
  }
}
@media (max-width: 1199.98px) {
  .global-nav .navbar-collapse.collapsing {
    height: auto;
    right: -100%;
    transition: right 0.3s;
    width: 320px;
  }
}
@media (max-width: 1199.98px) {
  .global-nav .navbar-collapse.show {
    overflow-y: scroll;
    right: 0;
    transition: right 0.3s;
    width: 320px;
  }
}
.global-nav .navbar-collapse .btn-icon {
  margin-right: 0.3rem;
}
.global-nav .card-block a:visited {
  color: #0170bc;
}

@media (min-width: 992px) {
  .universal-navigation {
    border-bottom: 1px solid #e4e4e4;
    /*margin-bottom: 20px;*/
  }
}
.universal-navigation .nav-item:not(:last-child) {
  margin-bottom: 1rem;
}
.universal-navigation .nav-item .nav-link {
  padding-bottom: 0;
  padding-top: 0;
  /* stylelint-disable-next-line selector-max-compound-selectors */
}
@media (min-width: 992px) {
  .universal-navigation .nav-item .nav-link {
    align-items: center;
    display: flex;
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;
  }
}
.universal-navigation .nav-item .nav-link .nav-link-icon {
  fill: #0170bc;
  height: 2rem;
  margin-right: 0.25rem;
  pointer-events: none;
  vertical-align: inherit;
  width: 2rem;
}
.universal-navigation .nav-link:visited {
  color: #0170bc;
}

.universal-navigation-buttons .nav-item:not(:last-child) {
  padding-right: 1.5rem;
}
.universal-navigation-buttons .nav-item:not(:last-child) {
  padding-right: 1.5rem;
}
@media (min-width: 992px) {
  .universal-navigation-buttons .nav-item:not(:last-child) {
    margin-bottom: 0;
  }
}
.universal-navigation-buttons .nav-item .nav-link {
  padding-left: 0;
  padding-right: 0;
}
.universal-navigation-buttons .nav-item .nav-link:hover {
  background-image: linear-gradient(#333, #333);
}
.universal-navigation-buttons.list-inline-item:not(:last-child) {
  padding-right: 0.5rem;
}

.utility-buttons > li {
  text-indent: 0 !important;
}

@media (max-width: 1199.98px) {
  .global-nav .nav-menu {
    background-image: linear-gradient(-155deg, #fff 3%, #efefef 100%);
    bottom: 0;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    display: block;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    position: fixed;
    right: 0;
    top: 0;
    transform: translateX(320px);
    transition: transform 0.2s ease-in-out;
    width: 320px;
    z-index: 2;
  }
}
@media (max-width: 1199.98px) {
  .global-nav .nav-menu.open {
    height: auto;
    overflow-y: scroll;
    transform: translateX(0);
    transition: transform 0.2s 0.1s ease-in-out;
  }
}

.navbar-collapse-shade {
  background: black;
  bottom: 0;
  display: block;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.2s ease-in-out;
  width: 0;
}
.navbar-collapse-shade.open {
  opacity: 0.1;
  transition: opacity 0.2s 0.1s ease-in-out;
  width: 100vw;
  z-index: 1;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .container {
    max-width: none;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.header-meganav {
  /* stylelint-disable-next-line selector-max-compound-selectors */
}
.header-meganav .universal-navigation {
  padding: 0;
}
.header-meganav .universal-navigation-buttons {
  margin-left: auto;
}
.header-meganav .main-navigation {
  border-bottom: 1px solid #e4e4e4;
  border-top: 1px solid #e4e4e4;
}
.header-meganav .main-navigation .nav-link {
  font-family: "HCo Gotham SSm", "Verdana", "Geneva", sans-serif;
  font-weight: 500;
  display: block;
  font-size: 1.125rem;
  height: 100%;
}
.header-meganav .level-1 {
  flex-wrap: nowrap;
}
.header-meganav .level-1 .nav-link {
  border-bottom: 0.25rem solid transparent;
  margin: 0 1rem;
  padding: 1rem 0;
  padding-bottom: 0.75rem;
  text-align: center;
}
.header-meganav .dropdown {
  padding: 0;
  position: static;
}
.header-meganav .dropdown.active > .nav-link {
  border-bottom: 0.25rem solid #f68621;
}
.header-meganav .dropdown:hover > .nav-link {
  border-bottom: 0.25rem solid #939496;
}
.header-meganav .dropdown-menu {
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;
}
.header-meganav .dropmenu {
  background-color: #fff;
  border: 1px solid rgba(147, 148, 150, 0.75);
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  margin: 0.5rem 0;
}
.header-meganav .dropmenu::before {
  display: none;
}
.header-meganav .dropmenu-item {
  margin: 1rem;
}
.header-meganav .dropmenu-item > * + * {
  margin-top: 0.5rem;
}
.header-meganav .dropmenu-link {
  white-space: normal;
}
.header-meganav .inner-menu {
  background-color: #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  min-width: 992px;
  padding: 0;
}
@media (min-width: 1200px) {
  .header-meganav .inner-menu {
    min-width: 1150px;
  }
}
.header-meganav .inner-menu .menu-header {
  font-family: "HCo Gotham SSm", "Verdana", "Geneva", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 1rem;
  white-space: normal;
  /* stylelint-disable-next-line selector-max-compound-selectors */
}
.header-meganav .inner-menu .menu-header a {
  background-image: none;
  background-position: 0% 89%;
  background-repeat: repeat-x;
  background-size: 1px 1px;
  color: #0170bc;
  cursor: pointer;
  font-weight: inherit;
  padding-bottom: 4px;
}
.header-meganav .inner-menu .menu-header a:visited {
  color: #800080;
}
.header-meganav .inner-menu .menu-header a:hover {
  background-image: linear-gradient(#004986, #004986);
  color: #004986;
}
.header-meganav .inner-menu .menu-item {
  margin-bottom: 1rem;
  white-space: normal;
  /* stylelint-disable-next-line selector-max-compound-selectors */
}
.header-meganav .inner-menu .menu-item .btn {
  margin-bottom: 0.5rem;
}
.header-meganav .inner-menu-row {
  width: 100%;
}
.header-meganav .tabs-left {
  background-color: #e7f1f8;
  padding: 1rem 0;
}
.header-meganav .tabs-right {
  padding: 2rem;
}
.header-meganav .nav-tabs {
  border: 0;
  display: block;
  /* stylelint-disable-next-line selector-max-compound-selectors */
}
.header-meganav .nav-tabs .nav-item {
  border-bottom: 2px solid #e4e4e4;
  /* stylelint-disable-next-line selector-max-compound-selectors */
}
.header-meganav .nav-tabs .nav-item:first-child {
  border-top: 2px solid #e4e4e4;
}
.header-meganav .nav-tabs .nav-item .btn-link {
  background: none;
}
.header-meganav .nav-tabs .nav-link {
  font-family: "HCo Gotham SSm", "Verdana", "Geneva", sans-serif;
  font-weight: 500;
  border: 0;
  padding: 1rem;
  text-align: left;
  width: 100%;
}
.header-meganav .nav-tabs .nav-link:focus {
  background-color: initial;
}
.header-meganav .nav-tabs .nav-link.active,
.header-meganav .nav-tabs .nav-item.show .nav-link {
  background-color: #fff;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
.header-meganav .link-lists li {
  font-size: 16px;
  margin-bottom: 0.5rem;
}
.header-meganav .btn-close {
  height: 100%;
  margin-left: auto;
  vertical-align: top;
}
.header-meganav .menu-header-area {
  display: flex;
}
@media (max-width: 991.98px) {
  .header-meganav .form-inline {
    display: block;
    margin-top: 1rem;
  }
  .header-meganav .form-inline .input-group-search {
    width: 100%;
  }
}
.header-meganav .form-group {
  margin-bottom: 0;
}
.header-meganav .home-utility-buttons {
  padding: 0.5rem 1rem;
}
.header-meganav .search {
  margin-bottom: 2rem;
}

.header-image-teaser {
  margin-bottom: 2rem;
}
.header-image-teaser .teaser-img {
  margin-bottom: 1rem;
}
.header-image-teaser .teaser-img img {
  border-radius: 0.5rem;
}
.header-image-teaser .teaser-title {
  margin-bottom: 0.5rem;
}
.header-image-teaser .teaser-content > :last-child {
  margin-bottom: 0;
}

.header__basic {
  background: #fff;
}
.header__basic a:not(.header-link) {
  background-image: none;
}
.header__basic a:not(.header-link):not(.btn):focus {
  background-color: transparent;
}
.header__basic .navbar-brand {
  padding-bottom: 0;
  padding-top: 0;
}
.header__basic .navbar-brand > svg {
  max-height: 38px;
  max-width: 111px;
}
@media (min-width: 576px) {
  .header__basic .navbar-brand > svg {
    max-height: 56px;
    max-width: 163px;
  }
}
@media (min-width: 992px) {
  .header__basic .navbar-brand > svg {
    max-height: 66px;
    max-width: 192px;
  }
}

.modal {
  display: none;
  height: 100%;
  left: 0;
  outline: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1072;
}
.modal__dialog {
  margin: 0.5rem;
  pointer-events: none;
  position: relative;
  width: auto;
}
@media (min-width: 576px) {
  .modal__dialog {
    margin: 1.75rem auto;
    max-width: 500px;
  }
}
@media (min-width: 768px) {
  .modal__dialog {
    max-width: 750px;
  }
}
.modal__dialog--full-width {
  height: 100%;
  margin: 0;
  max-width: 100%;
}
.modal__content {
  background-clip: padding-box;
  background-color: #fff;
  border: 0;
  border-radius: 0.5rem;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  outline: 0;
  overflow: hidden;
  pointer-events: auto;
  position: relative;
  width: 100%;
}
.modal__content--full-width {
  border-radius: 0;
  height: 100%;
  overflow: hidden;
  padding: 1rem 0 3rem;
}
@media (min-width: 576px) {
  .modal__content--full-width {
    padding: 4rem 0;
  }
}
@media (min-width: 992px) {
  .modal__content--full-width {
    padding: 4.5rem 0;
  }
}
.modal__content--mobile-filter {
  padding: 0;
}
.modal__full-w-modal-content {
  flex: 0 0 100%;
  margin: 0 auto;
  max-width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 1rem;
  position: relative;
  width: 100%;
}
@media (min-width: 576px) {
  .modal__full-w-modal-content {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
}
@media (min-width: 768px) {
  .modal__full-w-modal-content {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
}
.modal__full-w-modal-content--mobile-filter {
  padding: 0;
}
.modal__header {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  align-items: flex-start;
  border-bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 3rem 2rem 0;
}
.modal__header--full-width {
  border-bottom: 0;
  margin-bottom: 1.5rem;
  padding: 0;
}
.modal__header--mobile-filter {
  align-items: center;
  background: #0170bc;
  border-radius: 0;
  color: #fff;
  display: flex;
  min-height: 60px;
  padding: 0 1.5rem;
  position: relative;
}
.modal__body {
  flex: 1 1 auto;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 2rem 2rem 3rem;
  position: relative;
}
.modal__body--full-width {
  max-height: inherit;
  padding: 0;
}
.modal__body:last-child {
  margin-bottom: 0;
}
.modal__footer {
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
  align-items: center;
  border-top: 1px solid #e4e4e4;
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}
.modal__footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal__footer > :not(:last-child) {
  margin-right: 0.25rem;
}
.modal__close {
  background-color: transparent;
  border: 0;
  height: 38px;
  margin: 0;
  opacity: 1;
  padding: 0;
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 48px;
  z-index: 10;
}
.modal__close--full-width {
  right: 0.5rem;
  top: 0.5rem;
}
@media (min-width: 576px) {
  .modal__close--full-width {
    right: 2rem;
    top: 2rem;
  }
}
@media (min-width: 992px) {
  .modal__close--full-width {
    right: 3rem;
    top: 3rem;
  }
}
.modal--fade {
  transform: translate(0, -50px);
  transition: opacity 0.15s linear;
}
.modal--fade:not(.show) {
  opacity: 0;
}
.modal.show {
  transform: none;
}
.modal .backdrop {
  background-color: #000;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1040;
}
.modal .backdrop.fade {
  opacity: 0;
}
.modal .backdrop.show {
  opacity: 0.1;
}
.modal__button--mobile-filter {
  bottom: 1rem;
  height: 55px;
  left: 50%;
  margin-left: -140px;
  max-width: 280px;
  position: fixed;
  width: 100%;
  z-index: 1073;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}