//
// Cigna Digital Color Palette
//
$digital-black: #333 !default;
$gray-60: #505050 !default;
$gray-40: #939496 !default;
$gray-05: #e4e4e4 !default;
$gray-15: $gray-05 !default;
$gray-01: #f8f8f8 !default;
$white: #fff; // defined by bootstrap.
$digital-blue: #0170bc !default;
$digital-blue-10: #e7f1f8 !default;
$cigna-blue-dark: #004986 !default;
$cigna-blue-light: rgb(0, 169, 224) !default;
$digital-green: #118503 !default;
$digital-green-10: #e7f2e5 !default;
$cigna-orange-logo: #f68621 !default;
$cigna-orange-dark: #e35205 !default;
$cigna-orange-light-20: #fcf0d6 !default;
$digital-red: #c00 !default;
$digital-red-10: #ffe3e2 !default;
$digital-purple: #800080 !default;

$cigna-colors: () !default;
$cigna-colors: map-merge((
  'digital-black': $digital-black,
  'gray-60': $gray-60,
  'gray-40': $gray-40,
  'gray-15': $gray-05,
  'gray-05': $gray-05,
  'gray-01': $gray-01,
  'white': $white,
  'digital-blue': $digital-blue,
  'digital-blue-10': $digital-blue-10,
  'cigna-blue-dark': $cigna-blue-dark,
  'cigna-blue-light': $cigna-blue-light,
  'digital-green': $digital-green,
  'digital-green-10': $digital-green-10,
  'cigna-orange-logo': $cigna-orange-logo,
  'cigna-orange-dark': $cigna-orange-dark,
  'cigna-orange-light-20': $cigna-orange-light-20,
  'digital-red': $digital-red,
  'digital-red-10': $digital-red-10,
  'digital-purple': $digital-purple
), $cigna-colors);

$primary: $digital-blue;

$theme-colors: ();
$theme-colors: map-merge($theme-colors, $cigna-colors);

//
// Social Brand Colors
//

$social-brand-colors: (
  facebook: #3b5998,
  twitter: #55acee,
  linkedin: #0077b5,
  youtube: #f00
);

//
// Spacing System
//

$spacing-xs: 0.25rem !default; // 4px
$spacing-s: 0.5rem !default; // 8px
$spacing-m: 1rem !default; // 16px (base)
$spacing-ml: 1.5rem !default; // 24px
$spacing-l: 2rem !default; // 32px
$spacing-xl: 3rem !default; // 48px
$spacing-xxl: 4rem !default; // 64px
$spacing-xxxl: 4.5rem !default; // 72px


//
// Border radius
//

$radius-default: 0rem !default; // 0px
$radius-xs: 0.125rem !default; // 2px
$radius-s: 0.25rem !default; // 4px
$radius-m: 0.5rem !default; // 8px
$radius-l: 0.75rem !default; // 12px

$border-radiuses: (
  xs: $radius-xs,
  s: $radius-s,
  m: $radius-m,
  l: $radius-l
);

// Borders

$border-color: $gray-05;
$border-width: 1px;

//
// Fonts
//

// Paths

$gotham-font-path: '../../src/fonts/gotham/' !default;
$open-sans-font-path: '../../src/fonts/open-sans/' !default;
$merriweather-font-path: '../../src/fonts/merriweather/' !default;

// Sizes

$font-size-base: 1rem;
$small-font-size: $font-size-base * 0.875;
$micro-font-size: $font-size-base * 0.75; // non-bs

$small-text: $small-font-size;

// Weights

$font-weight-semibold: 600;
$font-weight-extrabold: 800;

//
// Button defaults
//

$button-font-size: 16px !default;
$button-line-height: 16px !default;
$button-cursor: pointer !default;

//
// Icon sizing
//

// Hero Icon Sizes
$hero-icn-size: 144px !default;
$hero-icnimg-size: 112px !default;

// Block Icon Sizes
$block-icn-size: 88px !default;
$block-icnimg-size: 72px !default;

// Featured Topics Size
$featured-topics-icon-size: 80px !default;
$featured-topics-iconimg-size: 64px !default;

// Teaser Icon Sizes
$teaser-icn-size: 64px !default;
$teaser-icnimg-size: 64px !default;

// 44x44 Icon Sizes
$xl-icn-size: 44px !default;

// 24x24 Icon Sizes
$ml-icn-size: 24px !default;

// 16x16 Icon Sizes
$m-icn-size: 16px !default;

// 14x14 Icon Sizes
$xs-icn-size: 14px !default;

//
// Drop shadows
//

$box-shadow-length-low: 1px 1px 4px 0;
$box-shadow-length-default: 1px 1px 4px;
$box-shadow-length-high: 0 2px 8px 0;
$box-shadow-length-floating: 0 12px 40px 0;
$box-shadow-length-btn: 0 0 4px 1px;

$shadow-10: rgba(0, 0, 0, 0.1);
$shadow-20: rgba(0, 0, 0, 0.2);
$shadow-30: rgba(0, 0, 0, 0.3);
$shadow-40: rgba(0, 0, 0, 0.4);
$btn-shadow-color: rgba(0, 169, 224, 1);

$shadow-default: $box-shadow-length-default $shadow-40; // other sizes??? TODO: Convert to rems?

$shadow-low-20: $box-shadow-length-low $shadow-20; // use this for btn-secondary
$shadow-low-30: $box-shadow-length-low $shadow-30; // use this for Cards, tooltips, typeaheads, pickers, sliders
$shadow-low-40: $box-shadow-length-low $shadow-40; // use this for btn-primary

$shadow-high-10: $box-shadow-length-high $shadow-10; // use this for Navigation bars
$shadow-high-20: $box-shadow-length-high $shadow-20; // use this for Navigation menus, modals

$shadow-inset-20: inset $box-shadow-length-low $shadow-20; // use this for form fields
$shadow-floating-30: $box-shadow-length-floating $shadow-30; //f or Emphasized images, media

// For Primary, Secondary, Tertiary, and Flat Secondary buttons: Active & Focus states
$btn-box-shadow: $box-shadow-length-btn $btn-shadow-color;

//
// Load more buttons
//

$load-more-breakpoint: (
  'max': 686px
) !default;

//
// Share Buttons
//

$sharebreakpoints: () !default;
$sharebreakpoints: map-merge((
  'min': 1200px,
  'mid': 1299px,
  'max': 1300px
), $sharebreakpoints);

// Links

$link-color: $digital-blue;
$link-decoration: none !default;
$link-font-weight: 600 !default;
$link-hover-color: $cigna-blue-dark !default;
$link-hover-decoration: none !default;
$link-hover-underline-color: $cigna-blue-dark;

//
// Fonts
//

$accents-font-family: 'Merriweather', serif !default;
$accents-font-weight: 400;

//
// Loading indicators
//

$loading-spinner-duration: 1.7s !default;
$loading-spinner-color: rgba($cigna-orange-dark, 0.7) !default;
$loading-spinner-bg-color: $gray-01 !default;
$loading-spinner-size: $spacing-xxl !default;

//
// Quotations
//

$quotation-attribution-text-color: $gray-60 !default;
$quotation-accent-bar-color: $cigna-orange-logo !default;
$quotation-accent-bar-thickness: 6px !default; // block/side quote bar width or height
$quotation-accent-bar-width: 56px !default; // Side quote horizontal accent bar width


//
// Callouts
//

$callout-accent-bar-color: $cigna-orange-logo !default;
$callout-accent-bar-thickness: 4px !default; // block/side quote bar width or height

// Hero ".hero-white-left-box"
//

$hero-white-left-box-max-widths: (
  xs: 100%,
  sm: 100%,
  md: 362px,
  lg: 470px,
  xl: 470px
);

//
// Dropdowns/Dropmenus
//

$dropmenu-border-color: rgba($gray-40, 0.75);
$dropmenu-border-radius: $radius-m;
$dropmenu-border-width: $border-width;
$dropmenu-box-shadow: $shadow-default;
