// =============================================================================
// Reset Link Styles
// =============================================================================

@mixin reset-link-styles($visited: inherit) {
  background-image: none;
  background-position: inherit;
  background-repeat: inherit;
  background-size: inherit;
  font-weight: inherit;

  &:active,
  &:hover {
    background-image: none;
    color: $link-hover-color;
    transition: none;
  }

  &:focus {
    background-color: transparent;
  }

  &:visited {
    color: $visited;
  }
}

@mixin reset-link-text() {
  background-image: none;
  background-position: inherit;
  background-repeat: inherit;
  background-size: inherit;
}

// =============================================================================
// Create custom link styling
// =============================================================================

@mixin link-variant($text-color: $digital-blue, $underline-color: $digital-black, $hover-color: $link-hover-color, $hover-underline-color: $link-hover-underline-color, $visited: inherit, $font-weight: inherit) {
  @if $underline-color == transparent {
    background-image: none;
  } @else {
    background-image: linear-gradient(rgba($underline-color, 0.3), rgba($underline-color, 0.3));
  }
  background-position: 0% 89%;
  background-repeat: repeat-x;
  background-size: 1px 1px;
  color: $text-color;
  cursor: pointer;
  font-weight: $font-weight;
  padding-bottom: 4px;

  &:visited {
    color: $visited;
  }

  &:hover {
    @if $hover-underline-color == transparent {
      background-image: none;
    } @else {
      background-image: linear-gradient($hover-underline-color, $hover-underline-color);
    }
    color: $hover-color;
  }
}
