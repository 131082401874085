//
// Base Form Field Style / Text Input
//

.input-holder,
.form-drop,
.input-holder-r {
  margin-bottom: $spacing-l;
  position: relative;
}

.main-input {
  position: relative;
}

.basic-input {
  background: $white;
  border: 1px solid $gray-05;
  border-radius: $radius-m;
  box-shadow: $shadow-inset-20;
  padding: $input-padding-y $input-padding-x;

  &:focus {
    border: 1px solid $cigna-blue-light;
    box-shadow: inset 1px 1px 4px 0 rgba(0, 169, 224, 0.6);
  }

  &::placeholder {
    color: $gray-05;
  }
}

// Select

.select {
  appearance: none;
  background: $white url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1792 1792'%3E%3Cpath fill='%230170bc' d='M1395 736q0 13-10 23l-466 466q-10 10-23 10t-23-10L407 759q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l393 393 393-393q10-10 23-10t23 10l50 50q10 10 10 23z'/%3E%3C/svg%3E") no-repeat right 1rem center;
  background-size: 20px 20px;
  border: 1px solid $gray-05;
  border-radius: $spacing-s;
  color: $gray-05;
  display: inline-block;
  line-height: 1.5;
  padding: $spacing-s $spacing-l $spacing-s $spacing-m;
  vertical-align: middle;
  width: 100%;

  &:focus {
    background-color: $white;
    outline: 0;
  }

  &:invalid {
    color: $gray-15;
  }
  
  &--lg {
    padding: 0.75rem;
  }

  option {
    color: $digital-black;

    &[disabled] {
      color: $gray-05;
      display: none;
    }
  }
}

.custom-select,
.select-input {
  // stylelint-disable-next-line property-no-vendor-prefix
  -moz-appearance: none;
  // stylelint-disable-next-line property-no-vendor-prefix
  -webkit-appearance: none;
  appearance: none;
  background: $custom-select-bg $custom-select-indicator no-repeat right $custom-select-indicator-padding center;
  background-size: $custom-select-bg-size;
  border: $custom-select-border-width solid $custom-select-border-color;
  box-shadow: $shadow-inset-20;
  color: $custom-select-color;
  display: inline-block;
  height: $custom-select-height;
  line-height: $custom-select-line-height;
  padding: $custom-select-padding-y ($custom-select-padding-x + $custom-select-indicator-padding) $custom-select-padding-y $custom-select-padding-x;
  vertical-align: middle;
  width: 100%;
  @if $enable-rounded {
    border-radius: $custom-select-border-radius;
  } @else {
    border-radius: 0;
  }

  &:focus {
    border-color: $custom-select-focus-border-color;
    box-shadow: $custom-select-focus-box-shadow;

    &::-ms-value {
      // For visual consistency with other platforms/browsers,
      // suppress the default white text on blue background highlight given to
      // the selected option text when the (still closed) <select> receives focus
      // in IE and (under certain conditions) Edge.
      // See https://github.com/twbs/bootstrap/issues/19398.
      background-color: $input-bg;
      color: $input-color;
    }
  }

  &[multiple],
  &[size]:not([size='1']) {
    background-image: none;
    height: auto;
    padding-right: $custom-select-padding-x;
  }

  &:disabled {
    background-color: $custom-select-disabled-bg;
    color: $custom-select-disabled-color;
  }

  // Hides the default caret in IE11
  &::-ms-expand {
    opacity: 0;
  }
}

// Input

.input {
  &__text {
    background-clip: padding-box;
    background-color: color('white');
    border: 1px solid color('gray-05');
    border-radius: $spacing-s;
    color: color('digital-black');
    display: block;
    font-size: 1rem;
    line-height: 1.5;
    padding: $spacing-s $spacing-m;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 100%;

    &:focus {
      background-color: color('white');
      border-color: color('digital-blue-light');
      box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
      color: color('digital-black');
      outline: 0;
    }
  }

  &--lg {
    border-radius: .3rem;
    font-size: 1.25rem;
    line-height: 1.5;
    padding: $spacing-m $spacing-ml;
  }

  &--gutter {
    margin-bottom: $spacing-ml;
  }

  &__inline {
    align-items: center;
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
  }

  &__col {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;

    &--three {
      @include make-col(3);
    }
  }
}

//
// Input-Error
//

.input-holder.input-error,
.input-holder-r.input-error {
  .basic-input,
  .custom-select {
    border: 2px solid $digital-red;
    box-shadow: inset 1px 1px 4px 0 rgba(204, 0, 0, 0.6);
  }

  .error-feedback {
    align-items: center;
    color: $digital-red;
    display: flex;
    margin-top: $spacing-s;
  }

  .feedback-icn {
    align-self: flex-start;
    fill: $digital-red;
    flex: 0 0 1rem;
    height: 1.5rem;
  }

  .error-message {
    margin-bottom: 0;
    margin-left: $spacing-s;
  }
}

//
// Input-Success
//

.input-holder.input-success,
.input-holder-r.input-success {
  .validation-icn {
    display: block;
    fill: $digital-green;
    position: absolute;
    right: $spacing-s;
    top: 50%;
    transform: translateY(-50%);
  }

  &.form-drop {
    .validation-icn {
      display: none;
    }
  }
}

.form-drop {
  .dropdown-toggle::after {
    display: none;
  }

  .drop-arrow {
    pointer-events: none;
    position: absolute;
    right: $spacing-s;
    top: 50%;
    transform: translateY(-50%);
  }

  .dropdown-menu, option {
    border: 1px solid $gray-05;
    border-radius: $radius-m;
    padding: $spacing-s;
    width: 100%;

    .dropdown-item {
      background-image: none;
      color: $digital-black;
    }

    .dropdown-item:focus, .dropdown-item:hover {
      background-color: $digital-blue-10;
    }
  }
}

//
// "Important" Input
//

.input-holder-r {
  .important-label {
    color: $gray-60;
    margin-bottom: 0;
    margin-left: $spacing-s;
  }
}

.input-optional .optional-label {
  color: $gray-60;
  margin-bottom: 0;
  margin-left: $spacing-s;
}

//
// Default Settings
//

.error-feedback,
.validation-icn {
  display: none;
}

.input-label,
.error-feedback {
  font-weight: 600;
}

.input-label {
  display: inline-flex;
}

//
// Custom Checkbox / Radio
//

.checkbox-holder, .radio-holder {
  margin-bottom: $spacing-m;
}

.checkbox-label, .radio-label {
  display: block;
  margin-bottom: 0;
  padding-left: 26px;
  position: relative;
  user-select: none;
}

.checkbox-label input, .radio-label input {
  opacity: 0;
  position: absolute;
}

.custom-checkbox {
  background-color: $gray-01;
  border: 1px solid $gray-05;
  border-radius: $radius-s;
  cursor: pointer;
  height: 16px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
}

.checkbox-label input:checked ~ .custom-checkbox {
  background-color: $digital-blue;
  border: 1px solid $digital-blue;
}

.custom-checkbox::after, .custom-radio::after {
  content: '';
  display: none;
  position: absolute;
}

.checkbox-label input:checked ~ .custom-checkbox::after, .radio-label input:checked ~ .custom-radio::after {
  display: block;
}

.checkbox-label .custom-checkbox::after {
  // stylelint-disable-next-line string-quotes
  content: str-replace(url("data:image/svg+xml;charset=UTF-8, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1792 1792' fill='#fff'%3E%3Cpath d='M1550 232q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136L28 662Q0 634 0 594t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z'/%3E%3C/svg%3E"), "#", "%23");
  height: 12px;
  left: 2px;
  position: absolute;
  top: -3px;
  width: 12px;
}

.radio-label .custom-radio::after {
  background: $digital-blue;
  border-radius: 50%;
  height: 10px;
  left: 50%;
  margin-left: -5px;
  margin-top: -5px;
  position: absolute;
  top: 50%;
  width: 10px;
}

.custom-radio {
  background-color: $gray-01;
  border: 1px solid $gray-05;
  border-radius: 100%;
  cursor: pointer;
  height: 1rem;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1rem;
}
