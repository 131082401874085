//
// for focus-visible polyfill. When an element recieves focus through a keyboard,
// the .focus-visible class is applied. Browsers are beginning to adopt the :focus-visible pseudoclass.
//
// For all elements that do not have focus-visible (i.e. focused on by mouse input),
// we want to remove the blue outline
//

//
// This will hide the focus indicator if the element receives focus via the mouse,
// but it will still show up on keyboard focus.
//

body :focus:not(.focus-visible) {
  outline: 0;
}
