// =============================================================================
// Customized version of button-variant BS4 Button Variant Mixin
// =============================================================================
/* stylelint-disable */
@mixin btn-variant($background, $border, $color, $shadow: $shadow-low-40, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $hover-color: false, $active-background: darken($background, 10%), $active-border: darken($border, 12.5%), $focus-shadow: $btn-box-shadow, $disabled-background: $gray-05) {
  color: $color;
  @include gradient-bg($background);
  border-color: $border;
  box-shadow: $shadow;

  @include hover {
    @if $hover-color {
      color: $hover-color;

      .btn-icon {
        fill: $hover-color;
      }
    } @else {
      color: $color;

      .btn-icon {
        fill: $hover-color;
      }
    }
    @include gradient-bg($hover-background);
    border-color: $hover-border;
  }

  // For docs purposes
  &.hover {
    @if $hover-color {
      color: $hover-color;
    } @else {
      color: $color;
    }
    @include gradient-bg($hover-background);
    border-color: $hover-border;
  }

  &:focus,
  &.focus {
    // Avoid using mixin so we can pass custom focus shadow properly
    background-color: $background;
    @if $enable-shadows {
      box-shadow: $btn-active-box-shadow, 0 0 0 $input-btn-focus-width rgba($border, .5);
    } @else {
      box-shadow: $btn-box-shadow;
    }
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    background-color: $disabled-background;
    box-shadow: none;
    border-color: $border;
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active,
  .show > &.dropdown-toggle {
    // color: color-yiq($active-background);
    color: $color;
    background-color: $active-background;
    @if $enable-gradients {
      background-image: none; // Remove the gradient for the pressed/active state
    }
    border-color: $active-border;

    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow: $btn-active-box-shadow, 0 0 0 $input-btn-focus-width rgba($border, .5);
    } @else {
      box-shadow: $btn-box-shadow;
    }
  }

  .btn-icon {
    fill: $color;
  }
}
/* stylelint-enable */
