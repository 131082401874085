$header-meganav-border-thickness: 0.25rem;

.header-meganav {
  .universal-navigation {
    padding: 0;
  }

  .universal-navigation-buttons {
    margin-left: auto;
  }

  .main-navigation {
    border-bottom: 1px solid $gray-05;
    border-top: 1px solid $gray-05;

    .nav-link {
      @include heading-font;
      display: block;
      font-size: 1.125rem;
      height: 100%;
    }
  }

  .level-1 {
    flex-wrap: nowrap;

    .nav-link {
      border-bottom: 0.25rem solid transparent;
      margin: 0 $spacing-m;
      padding: $spacing-m 0;
      padding-bottom: $spacing-m - $header-meganav-border-thickness;
      text-align: center;
    }
  }

  .dropdown {
    padding: 0;
    position: static;

    &.active > .nav-link {
      border-bottom: $header-meganav-border-thickness solid $cigna-orange-logo;
    }

    &:hover > .nav-link {
      border-bottom: $header-meganav-border-thickness solid $gray-40;
    }
  }

  .dropdown-menu {
    background: none;
    border: 0;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .dropmenu {
    background-color: $white;
    border: 1px solid rgba(147, 148, 150, .75);
    border-radius: $spacing-s;
    box-shadow: 0 $spacing-s $spacing-m rgba(0, 0, 0, .15);
    margin: $spacing-s 0;

    &::before {
      display: none;
    }
  }

  .dropmenu-item {
    margin: $spacing-m;
  }

  /* stylelint-disable-next-line selector-max-compound-selectors */
  .dropmenu-item>*+* {
    margin-top: $spacing-s;
  }

  .dropmenu-link {
    white-space: normal;
  }

  .inner-menu {
    background-color: $white;
    box-shadow: $shadow-high-20;
    margin: 0 auto;
    min-width: 992px;
    padding: 0;
    @include media-breakpoint-up(xl) {
      min-width: 1150px;
    }

    .menu-header {
      @include heading-font;
      font-size: $font-size-base;
      margin-bottom: $spacing-m;
      white-space: normal;
      /* stylelint-disable-next-line selector-max-compound-selectors */
      a {
        @include link-variant($underline-color: transparent, $visited: $digital-purple);
      }
    }

    .menu-item {
      margin-bottom: $spacing-m;
      white-space: normal;

      /* stylelint-disable-next-line selector-max-compound-selectors */
      .btn {
        margin-bottom: $spacing-s;
      }
    }
  }

  .inner-menu-row {
    width: 100%;
  }

  .tabs-left {
    background-color: $digital-blue-10;
    padding: $spacing-m 0;
  }

  .tabs-right {
    padding: $spacing-l;
  }

  .nav-tabs {
    border: 0;
    display: block;

    .nav-item {
      border-bottom: 2px solid $gray-05;

      &:first-child {
        border-top: 2px solid $gray-05;
      }

      /* stylelint-disable-next-line selector-max-compound-selectors */
      .btn-link {
        background: none;
      }
    }

    .nav-link {
      @include heading-font;
      border: 0;
      padding: $spacing-m;
      text-align: left;
      width: 100%;

      &:focus {
        background-color: initial;
      }
    }

    /* stylelint-disable-next-line selector-max-compound-selectors */
    .nav-link.active,
    .nav-item.show .nav-link {
      background-color: $white;
      border: 0;
      border-radius: 0;
      box-shadow: none;
    }
  }

  .link-lists li {
    font-size: 16px;
    margin-bottom: $spacing-s;
  }

  .btn-close {
    height: 100%;
    margin-left: auto;
    vertical-align: top;
  }

  .menu-header-area {
    display: flex;
  }

  .form-inline {
    @include media-breakpoint-down(md) {
      display: block;
      margin-top: $spacing-m;

      .input-group-search {
        width: 100%;
      }
    }
  }

  .form-group {
    margin-bottom: 0;
  }

  .home-utility-buttons {
    padding: $spacing-s $spacing-m;
  }

  .search {
    margin-bottom: $spacing-l;
  }
}

// Image Teaser inside navigation

.header-image-teaser {
  margin-bottom: $spacing-l;

  .teaser-img {
    margin-bottom: $spacing-m;

    img {
      border-radius: $radius-m;
    }
  }

  .teaser-title {
    margin-bottom: $spacing-s;
  }

  .teaser-content > :last-child {
    margin-bottom: 0;
  }
}
.header__basic {
  background: $white;

  a:not(.header-link) {
    background-image: none;

    &:not(.btn):focus {
      background-color: transparent;
    }
  }

  .navbar-brand {
    padding-bottom: 0;
    padding-top: 0;

    > svg {
      max-height: 38px;
      max-width: 111px;
      @include media-breakpoint-up(sm) {
        max-height: 56px;
        max-width: 163px;
      }
      @include media-breakpoint-up(lg) {
        max-height: 66px;
        max-width: 192px;
      }
    }
  }
}