//
// Base button definition
//

.btn {
  @include button-size($spacing-m, $spacing-ml, $font-size-base, $button-line-height, $radius-m);
  background-image: none;
  cursor: $button-cursor;
  display: inline-block;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  white-space: normal;

  &:link:hover {
    background-image: none;
  }

  .external {
    height: 1rem;
    margin-bottom: 0.125rem;
    width: 1rem;
  }

  // Language picker
  // Included in header organism
  &--language {
    @include btn-variant(transparent, transparent, transparent, none, $digital-blue, $cigna-blue-light, $white, $cigna-blue-dark, transparent);
    @include button-size($spacing-xs, $spacing-s, $font-size-base, $btn-line-height, 0);

    font-family: $font-family-sans-serif;
    font-weight: 600;

    &:focus, &:active {
      box-shadow: none !important;
    }
  }

  &--btm-language-menu {
    align-items: center;
    background: $white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: $spacing-s;
    color: $digital-black;
    display: flex;
    height: 58px;
    justify-content: space-between;
    line-height: 1.5;
    padding: $spacing-s $spacing-ml $spacing-s $spacing-m;
    width: 100%;
  }
}


//
// Small button variation
//

.btn-sm {
  @include button-size($spacing-s, $spacing-m, 14px, 14px, 0.5rem);
}


//
// Primary Button
//

.btn-primary {
  @include btn-variant($digital-blue, transparent, $white, $shadow-low-40, $cigna-blue-dark, $cigna-blue-light, $white, $cigna-blue-dark, transparent);

  &:link,
  &:visited {
    color: $white;
  }

  &.disabled,
  &:disabled {
    box-shadow: none;
    color: $gray-60;
  }
}


//
// Secondary Button
//

.btn-secondary,
.btn-load-more {
  @include btn-variant($gray-01, transparent, $digital-blue, $shadow-low-20, $digital-blue-10, transparent, $digital-blue, $digital-blue-10, transparent);

  &:link,
  &:visited {
    color: $digital-blue;
  }

  &.disabled,
  &:disabled {
    color: $gray-60;
  }
}


//
// Tertiary Button
// Close to .btn-link with some modifications
//

.btn-tertiary {
  background-color: transparent;
  color: $link-color;

  @include hover {
    background-color: transparent;
    border-color: transparent;
    color: $cigna-blue-dark;
    text-decoration: underline;
  }

  &:link,
  &:visited {
    color: $digital-blue;
  }

  // For docs only.
  &.hover {
    background-color: transparent;
    border-color: transparent;
    color: $cigna-blue-dark;
    text-decoration: underline;
  }

  &:focus,
  &.focus {
    background-color: transparent;
    border-color: transparent;
    box-shadow: $btn-box-shadow;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
  }

  // TODO: Add spacing between text and underline.
  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    box-shadow: $btn-box-shadow;
    text-decoration: underline;
  }
}

//
// Link Button
//

.btn-link {
  @include btn-variant(transparent, transparent, $digital-blue, none, transparent, transparent, $link-hover-color, transparent, transparent, none, transparent);
  background-image: linear-gradient(rgba($digital-black, 0.3), rgba($digital-black, 0.3));
  background-position: 0% 100%;
  background-repeat: repeat-x;
  background-size: 1px 1px;
  border: 0;
  border-radius: $radius-default;
  font-family: $font-family-sans-serif;
  font-weight: $link-font-weight;
  line-height: $line-height-base;
  padding: 0;

  &:active,
  &:hover,
  &:focus {
    background-image: linear-gradient(rgba($cigna-blue-dark, 0.3), rgba($cigna-blue-dark, 0.3));
    box-shadow: none;
    transition: color 0.2s;

    &:not([disabled]):not(.disabled) {
      box-shadow: none;
    }
  }

  &:focus {
    background-color: $cigna-orange-light-20;
  }

  &:not(.btn):visited {
    color: $digital-purple;
  }
}


//
// Flat Secondary Button, close button
//


.btn-flat-secondary {
  @include btn-variant($gray-01, transparent, $digital-blue, none, $digital-blue, transparent, $white, $digital-blue, transparent);

  @include hover {
    background-color: $digital-blue;
    color: $white;
  }

  &.disabled,
  &:disabled {
    color: $gray-60;
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $white;
  }
}

//
// Quote Button
//
.btn-quote {
  @include btn-variant($cigna-orange-logo, transparent, $white, $shadow-low-40, $cigna-orange-dark, $cigna-orange-light-20, $white, $cigna-orange-dark, transparent);

  @include hover {
    background-color: $cigna-orange-dark;
    color: $white;
  }

  &.disabled,
  &:disabled {
    box-shadow: none;
    color: $gray-60;
  }
}

//
// Load More Button
//

.btn-load-more {
  margin-bottom: $spacing-xxl;
  text-align: center;
  width: 100%;

  @include media-breakpoint-up('max', $load-more-breakpoint) {
    width: 686px;
  }
}

//
// Button with Icon
//

.btn-icon {
  @include box(1.25em); /* Using ems here to allow .btn-small to lower width and height. */
  margin-right: $spacing-s;
  pointer-events: none;
  vertical-align: inherit;

  .btn-close & {
    @include box(1.5em);
    margin-right: $spacing-xs;
  }

  &--left {
    margin-right: $spacing-xs;
  }

  &--right {
    margin-left: $spacing-xs;
  }
}

//
// Close button
// controls closing or hiding visible components
//

.btn-close {
  @include button-size(0, 0, 1.125rem, 1.3333, 0);
  align-items: center;
  background-color: transparent;
  border-bottom-color: 1px solid transparent;
  color: $link-color;
  display: flex;
  margin: 0 $spacing-xs;
  z-index: 100;


  @include hover {
    background-color: inherit;
    border-bottom-color: $cigna-blue-dark;
    color: $cigna-blue-dark;
    text-decoration: none;
  }

  &:focus,
  &.focus {
    background-color: transparent;
    border-color: transparent;
  }

  // .btn-close-x => The times character next to 'Close'.
  &-x {
    font-family: inherit;
    font-size: 1.625rem; // 26px actual height of button.
    font-weight: inherit;
    line-height: 0.9231; // 24px inner height of button without borders.
    padding-left: $spacing-s;
  }

  .btn-icon {
    @include box(1.5em);
    margin-right: $spacing-xs;
  }
}
