%link-underline {
  background-image: linear-gradient(rgba(51,51,51,.3),rgba(51,51,51,.3));
  background-position: 0 88%;
  background-repeat: repeat-x;
  background-size: 1px 1px;
  padding-bottom: 4px;

  &:hover {
    text-decoration: none;
  }
}
