.article {
  font-family: $headings-font-family;

  &__author {
    color: $gray-40;
    font-family: $headings-font-family;
    font-size: $small-font-size;
    margin-bottom: $spacing-ml;
  }

  &__row {
    @include make-row();
  }

  &__body {
    @include make-container();
    @include make-container-max-widths();

    margin-bottom: $spacing-xl;
    position: relative;

    @include media-breakpoint-up(md) {
      max-width: calc(991px + 70px);
      padding: 0 70px;
    }
  }

  &__posts {
    @include make-container-max-widths();

    margin-bottom: $spacing-ml;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(md) {
      max-width: calc(991px + 70px);
      padding: 0 70px;
    }

    &-title {
      font-family: $headings-font-family;
      font-size: $h3-font-size;
      margin-bottom: $spacing-m;
      padding-left: $spacing-l;
      padding-right: $spacing-l;

      @include media-breakpoint-up(sm) {
        padding-left: $spacing-m;
        padding-right: $spacing-m;
      }
    }
  }

  &__tags {
    @include make-container();
    @include make-container-max-widths();

    margin-bottom: $spacing-l;

    @include media-breakpoint-up(md) {
      max-width: calc(991px + 70px);
      padding: 0 70px;
    }

    div {
      align-items: center;
      background-color: $gray-01;
      border-color: transparent;
      border-radius: $spacing-s;
      color: $gray-60;
      display: inline-flex;
      font-size: $micro-font-size;
      /*font-weight: $font-weight-regular;*/
      justify-content: center;
      line-height: $line-height-base;
      margin-bottom: $spacing-s;
      padding: $spacing-s $spacing-m;
      text-align: center;
    }
  }

  &__tags div {
    align-items: center;
    background-color: $gray-01;
    border-color: transparent;
    border-radius: $spacing-s;
    color: $gray-60;
    display: inline-flex;
    font-size: $micro-font-size;
    justify-content: center;
    line-height: $line-height-base;
    margin-bottom: $spacing-s;
    padding: $spacing-s $spacing-m;
    text-align: center;
  }

  &__share {
    @include make-container();
    @include make-container-max-widths();

    margin-bottom: $spacing-l;

    @include media-breakpoint-up(md) {
      max-width: calc(991px + 70px);
      padding: 0 70px;
    }
  }

  &__guide-scroll {
    align-items: center;
    color: $gray-15;
    display: flex;
    flex-direction: column;
    font-family: $headings-font-family;
    font-size: $small-font-size;
    margin-bottom: $spacing-l;
  }

  &__sources {
    @include make-container();
    @include make-container-max-widths();

    margin-bottom: $spacing-xxl;
    overflow-wrap: break-word;

    @include media-breakpoint-up(md) {
      max-width: calc(991px + 70px);
      padding: 0 70px;
    }

    :first-child {
      margin-top: $spacing-m;
    }

    header {
      font-weight: 600;
      margin-bottom: $spacing-m;
      text-transform: uppercase;
    }

    p {
      margin-bottom: $spacing-s;
    }

    a {
      background-image: none;
    }
  }

  &__footer {
    @include make-container();
    @include make-container-max-widths();

    margin-bottom: $spacing-xxl;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(md) {
      max-width: calc(991px + 70px);
      padding: 0 70px;
    }

    &-body {
      border-top: 1px solid $gray-15;
      color: $gray-15;

      p:first-child {
        margin-top: $spacing-m;
      }

      p:last-child {
        margin-bottom: $spacing-l;
      }
    }
  }
}

.post {
  @include make-container();
  @include make-container-max-widths();

  font-family: $headings-font-family;

  &__row {
    @include make-row();
  }

  &__body {
    margin-bottom: $spacing-xxl;
  }

  &__category {
    color: color('true-black');
    text-transform: uppercase;
  }

  &__date {
    color: color('gray-40');
    margin-right: 5px;
    text-transform: uppercase;
  }

  &__heading {
    color: color('digital-blue');
    margin-bottom: $spacing-ml;
  }

  &__sub {
    font-size: $small-font-size;
    font-weight: 500;
    margin-bottom: $spacing-s;
  }

  &__image {
    border-radius: $radius-l;
    margin-bottom: $spacing-ml;
    overflow: hidden;
  }

  &__link {
    float: right;
  }

  &--col2 {
    @include make-col-ready();
    @include media-breakpoint-up(md) {
      @include make-col(6);
    }
  }

  &--col3 {
    @include make-col-ready();
    @include media-breakpoint-up(md) {
      @include make-col(4);
    }
  }

  img {
    width: 100%;
  }
}

.post-filter {
  @include make-container();
  @include make-container-max-widths();

  font-family: $headings-font-family;
  margin-bottom: $spacing-xl;

  &__reset-button {
    background-image: none;
    color: $digital-blue;

    &:hover {
      background-image: none;
    }

    &:focus {
      background: none;
    }

    &--mobile-filter {
      padding-top: 0;
      text-align: end;
      width: 100%;
    }
  }

  &__mobile-button {
    background-color: transparent;
    border: 1px solid $digital-blue;
    border-radius: 0;
    color: $digital-blue;
    display: flex;
    justify-content: center;
    width: 100%;

    &:active {
      box-shadow: none !important;
    }

    &:focus {
      box-shadow: none;
    }
  }

  &__header {
    align-items: center;
    display: flex;
    margin-bottom: $spacing-ml;
  }

  &__body {
    margin-bottom: 6rem;
    @include media-breakpoint-up(md) {
      @include make-row();
      margin-bottom: 0;
    }
  }

  &__item {
    @include media-breakpoint-up(md) {
      @include make-col-ready();
      @include make-col(4);
    }

    .checkbox-label--mobile {
      border-bottom: 1px solid $gray-40;
      padding: $spacing-s $spacing-ml $spacing-ml;
      position: relative;

      .custom-checkbox {
        left: inherit;
        right: $spacing-ml;
        top: 1.2rem;
      }
    }
  }

  &__heading {
    margin-bottom: 0;
    margin-right: $spacing-m;
  }

  &--desktop-only {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &--mobile-only {
    display: flex;
    justify-content: center;
    margin: $spacing-m 0 $spacing-xl;
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}

.post-horizontal {
  @include make-container();

  &__body {
    border-top: 1px solid $gray-40;
    display: inline-block;
    padding: $spacing-xl 0;
  }
}

.post-horizontal-image {
  @include make-container();

  &__body {
    display: flex;
    margin-bottom: $spacing-xl;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  img {
    border-radius: $radius-l;
    margin-bottom: $spacing-m;
    margin-right: $spacing-m;
    max-width: 100px;
    min-width: 100px;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      max-width: 200px;
      min-width: 200px;
    }
  }

  &__link {
    margin-bottom: $spacing-m;
  }
}

.filter-flag {
  align-items: center;
  background-color: $gray-01;
  border-color: transparent;
  border-radius: $spacing-s;
  color: $gray-60;
  display: inline-flex;
  font-size: $micro-font-size;
  /*font-weight: $font-weight-regular;*/
  justify-content: center;
  line-height: $line-height-base;
  margin-bottom: $spacing-s;
  padding: $spacing-s $spacing-m;
  text-align: center;

  &__icon {
    cursor: pointer;
    fill: currentColor;
    height: $spacing-m;
    margin-left: $spacing-m;
    pointer-events: visible;
    width: $spacing-m;
  }

  &:last-child {
    margin-bottom: $spacing-xl;
  }
}
.article .compat-object-fit {
  background-position: center center;
  background-size: cover;
  width: 100%;
}

.article__hero-basic {
  margin-bottom: $spacing-xxl;
  position: relative;

  img {
    height: 600px;
    object-fit: cover;
    width: 100%;
  }

  &--rounded {
    border-radius: $radius-l;
  }

  &--variation-small {
    @include make-container();
    @include media-breakpoint-up(md) {
      max-width: 768px;
    }

    img, .compat-object-fit {
      height: 385px;
    }
  }

  &--variation-medium {
    @include make-container();
    @include media-breakpoint-up(md) {
      max-width: calc(991px + 70px);
      padding: 0 70px;
    }

    img, .compat-object-fit {
      height: 440px;
    }
  }
}

//
// Post
//
.post {
  @include make-container();
  @include make-container-max-widths();

  font-family: $headings-font-family;

  &__row {
    @include make-row();
  }

  &__body {
    margin-bottom: $spacing-xxl;
  }

  &__category {
    color: color('true-black');
    text-transform: uppercase;
  }

  &__date {
    color: $gray-40;
    margin-right: 5px;
    text-transform: uppercase;
  }

  &__heading {
    color: $digital-black;
    margin-bottom: $spacing-ml;
  }

  &__sub {
    font-size: $small-font-size;
    font-style: italic;
    font-weight: 500;
    margin-bottom: $spacing-s;
  }

  &__image {
    border-radius: $radius-l;
    margin-bottom: $spacing-ml;
    overflow: hidden;
  }

  &--col2 {
    @include make-col-ready();
    @include media-breakpoint-up(md) {
      @include make-col(6);
    }
  }

  &--col3 {
    @include make-col-ready();
    @include media-breakpoint-up(md) {
      @include make-col(4);
    }
  }

  img {
    width: 100%;
  }
}

.post-filter {
  @include make-container();
  @include make-container-max-widths();

  /*font-family: $font-family-sans-serif-2;*/
  margin-bottom: $spacing-xl;

  &__reset-button {
    color: $digital-blue;

    &:active {
      box-shadow: none !important;
    }

    &:focus {
      box-shadow: none;
    }

    &:hover {
      color: $cigna-blue-dark;
    }

    &--mobile-filter {
      padding-top: 0;
      text-align: end;
      width: 100%;
    }
  }

  &__mobile-button {
    background-color: transparent;
    border: 1px solid $digital-blue;
    border-radius: 0;
    color: $digital-blue;
    display: flex;
    justify-content: center;
    width: 100%;

    &:active {
      box-shadow: none !important;
    }

    &:focus {
      box-shadow: none;
    }
  }

  &__header {
    align-items: center;
    display: flex;
    margin-bottom: $spacing-m;
  }

  &__body {
    @include media-breakpoint-up(md) {
      @include make-row();
    }
  }

  &__item {
    @include media-breakpoint-up(md) {
      @include make-col-ready();
      @include make-col(4);
    }

    &:last-child {
      [class*="--mobile-filter"]:last-child {
        margin-bottom: 6rem;
      }
    }
  }

  &__heading {
    margin-bottom: 0;
    margin-right: $spacing-m;
  }

  &--desktop-only {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &--mobile-only {
    display: flex;
    justify-content: center;
    margin: $spacing-m 0 $spacing-xl;
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}

.post-horizontal {
  @include make-container();

  &__body {
    border-top: 1px solid $gray-40;
    display: inline-block;
    padding: $spacing-xl 0;
  }
}

.post-horizontal-image {
  @include make-container();

  &__body {
    display: flex;
    margin-bottom: $spacing-xl;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  img {
    border-radius: $radius-l;
    margin-bottom: $spacing-m;
    margin-right: $spacing-m;
    max-width: 100px;
    min-width: 100px;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      max-width: 200px;
      min-width: 200px;
    }
  }

  &__link {
    margin-bottom: $spacing-m;
  }
}