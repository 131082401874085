//
// Promos
//
// Variants:
// Gray Block Text Promo with Button
// Gray Block Photo Promo
// .tier-3-proposition
//

//
// Text Promo
//

.text-promo {
  background-color: $gray-01;
}

.full-promo {
  margin-bottom: $spacing-xl;

  @include media-breakpoint-up(lg) {
    margin-bottom: $spacing-xxl;
  }

  .body-text {
    margin-bottom: $spacing-ml;
  }

  .btn {
    margin-bottom: $spacing-ml;
    margin-right: $spacing-xs;

    @include media-breakpoint-up(md) {
      margin-bottom: $spacing-s;
      margin-right: $spacing-m;
    }
  }

  .promo-col {
    padding: $spacing-l;

    @include media-breakpoint-up(sm) {
      padding: $spacing-xl 0 $spacing-xl 0;
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: $spacing-xxxl;
      padding-top: $spacing-xxxl;
    }
  }
}

.promo-content-container,
.promo-content-container-w,
.promo-content-container-g {
  .promo-content {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}


//
// Gray Block Promo
//

.promo-gray {
  background-color: $gray-01;
}

.promo-img-container-g {
  margin: 0 $spacing-l $spacing-l;
  padding: 0;

  @include media-breakpoint-up(sm) {
    margin: 0 8.33% $spacing-l 8.33%;
  }
  @include media-breakpoint-up(md) {
    margin: 0 0 0 8.33%;
  }
}

.promo-content-container-g {
  margin: 0 $spacing-l 0 $spacing-l;
  padding: 0;

  @include media-breakpoint-up(sm) {
    margin: 0 8.33% $spacing-l 8.33%;
  }

  @include media-breakpoint-up(md) {
    margin: 0 8.33% 0 0;
    padding-left: $spacing-l;
  }
}

//
// New Promo
//

.promo {
  border-radius: $radius-l;
  margin-bottom: $spacing-xl;
}

.promo-img-container {
  .promo-bg {
    background-position: center;
    background-size: cover;
    display: block;

    @include media-breakpoint-between(xs, md) {
      border-radius: 0.75rem;
      height: 300px;
    }

    @include media-breakpoint-up(lg) {
      border-radius: 0.75rem;
      height: 100%;
      margin: 0;
      padding: 0;
    }
  }
}

.promo-content-container {
  @include media-breakpoint-between(xs, md) {
    background-color: $white;
    border: 2px solid $gray-05;
    border-radius: $radius-l;
    margin: -$spacing-xl 8.33% $spacing-l 8.33%;
    padding: $spacing-m;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: $spacing-xl;
    margin-top: $spacing-xl;
  }

  .promo-subhead {
    @extend %subhead-1;
    margin-bottom: $spacing-ml;

    @include media-breakpoint-up(lg) {
      margin-bottom: $spacing-l;
    }

    @include media-breakpoint-down(lg) {
      font-size: 1.75rem;
      line-height: 1.242857142857143;
    }

    @include media-breakpoint-down(xs) {
      font-size: 1.375rem;
      line-height: 1.272727272727273;
    }
  }

  .body-text {
    margin-bottom: $spacing-ml;

    @include media-breakpoint-up(lg) {
      margin-bottom: $spacing-l;
    }
  }

  .link-action {
    margin-bottom: $spacing-m;

    @include media-breakpoint-up(lg) {
      margin-bottom: $spacing-xl;
    }
  }
}

.promo-cta {
  align-items: center;

  @include media-breakpoint-up(lg) {
    display: flex;
  }

  .promo-cta-btn {
    margin-bottom: $spacing-m;
    white-space: pre;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  .promo-cta-support-copy {
    margin-bottom: 0;
  }
}

.testimonial {
  @include make-container();
  @include make-container-max-widths();
  margin-bottom: $spacing-xxl;

  @include media-breakpoint-up(md) {
    justify-content: center;
  }

  &__header {
    margin-bottom: $spacing-l;
    text-align: center;
  }

  &__list {
    @include media-breakpoint-up(md) {
      background: linear-gradient(180deg, #FFF 0%, #F8F8F8 100%);
      display: flex;
      overflow-x: auto;
    }

    &--center {
      @include media-breakpoint-up(md) {
        justify-content: center;
      }
    }
  }

  &__body {
    margin-bottom: $spacing-xl;
    min-width: 100%;
    padding: 0 $spacing-m;

    @include media-breakpoint-up(md) {
      @include make-col(6);

      margin-bottom: 0;
      min-width: auto;
      padding: 0 $spacing-l;
    }

    &--col-3 {
      @include make-col(4);
    }
  }

  &__author {
    display: flex;
    flex-wrap: wrap;
    font-family: $headings-font-family;
  }

  &__author-heading {
    margin-bottom: 0;
  }

  &__author-subheading {
    color: $digital-black;
    text-transform: uppercase;
  }

  &__avatar {
    margin-right: $spacing-m;
  }

  &__avatar img {
    border-radius: 50%;
    height: 80px;
    width: 80px;

    @include media-breakpoint-up(md) {
      height: 80px;
      width: 80px;
    }
  }

  &__content {
    @include media-breakpoint-up(md) {
      padding-left: 85px;
    }
  }

  &__quote {
    border-top: 1px solid #ccc;
    font-family: $accents-font-family;
    font-size: 1.25rem;
    font-style: italic;
    padding-top: $spacing-ml;
  }
}

.tier-3-proposition {
  @include make-row();
  @include make-container();
  @include make-container-max-widths();

  background-color: color('white');
  margin-bottom: $spacing-xxl;
  padding: $spacing-m $spacing-l;

  @include media-breakpoint-up(md) {
    border-radius: 12px;
    box-shadow: $shadow-default;
    padding: $spacing-xl $spacing-l;
  }

  &__bkgd {
    display: none;

    @include media-breakpoint-up(md) {
      background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(248,248,248,1) 50%, rgba(248,248,248,1) 100%);
      bottom: 40px;
      display: block;
      min-height: 200px;
      position: absolute;
      width: 100%;
      z-index: -1;
    }
  }

  &__container {
    position: relative;
  }

  &__heading {
    @include make-container();
    @include make-container-max-widths();

    margin-bottom: $spacing-m;
    text-align: center;
  }

  &__icon {
    margin-bottom: $spacing-m;
  }

  &__item {
    margin-bottom: $spacing-l;
    text-align: center;

    @include media-breakpoint-up(md) {
      @include make-col(4);

      padding: 0 $spacing-ml;
    }
  }

  &__link {
    text-align: center;
    width: 100%;

    p {
      margin-bottom: 0;
    }
  }
}
