.tooltip {
  border-radius: $radius-l;
  filter: alpha(opacity=100) !important;
  opacity: 1 !important;
}

.tooltip-item {
  border-bottom: 2px dotted $link-color;
  color: $link-color;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;

  @include media-breakpoint-up(md) {
    cursor: auto;
  }

  &:hover {
    border-bottom-style: solid;
  }
}

.tooltip-inner {
  background-color: rgba(255, 255, 255, 1);
  border-radius: $radius-l;
  box-shadow: 0 2px 10px -3px rgba(0, 0, 0, 1);
  color: $digital-black;
  filter: alpha(opacity=100) !important;
  min-width: 260px;
  opacity: 1 !important;
  padding: $spacing-m;
  text-align: left;

}

//bootstrap override
.tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before, .tooltip.bs-tooltip-top .arrow::before {
  border-top-color: $white;
}

.tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::after, .tooltip.bs-tooltip-top .arrow::after {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.2);
  content: '';
  height: 10px;
  left: 2px;
  position: absolute;
  top: -5px;
  transform: rotate(45deg); /* Prefixes... */
  width: 11px;
}

.tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .tooltip.bs-tooltip-bottom .arrow::before {
  border-top-color: $white;
}

.tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::after, .tooltip.bs-tooltip-bottom .arrow::after {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: -1px -1px 1px 0 rgba(0, 0, 0, 0.3);
  content: '';
  height: 10px;
  left: 2px;
  position: absolute;
  top: 0px;
  transform: rotate(45deg); /* Prefixes... */
  width: 11px;
}

.tooltip.show {
  z-index: 1073;
}
