//
// Action Link Blurb + Light CTA
//

.action-link-blurb-body,
.light-cta-body {
  p:last-child {
    margin-bottom: 0;
  }
}
