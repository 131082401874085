//
// Bootstrap overrides
//

$body-color: $digital-black;

$link-color: $digital-blue;
$link-decoration: none;
$link-hover-color: $cigna-blue-dark;
$link-hover-decoration: none;

$container-max-widths: (
  sm: 544px,
  md: 724px,
  lg: 964px,
  xl: 1144px
);

$grid-gutter-width: 32px;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-base: $font-family-sans-serif;

$headings-font-family: 'HCo Gotham SSm', 'Verdana', 'Geneva', sans-serif;
$headings-font-weight: 500;

$link-font-weight: 600;


//
// Paragraphs
//

$paragraph-margin-bottom: 1.5rem !default;


//
// Horizontal rules
//
// NOTE: also used for vertical rules

$hr-border-color: $gray-05 !default;
$hr-border-width: 2px !default;


//
// Spacers - override default
//

$spacers: (
  0: 0,
  xs: $spacing-xs,
  s: $spacing-s,
  m: $spacing-m,
  ml: $spacing-ml,
  l: $spacing-l,
  xl: $spacing-xl,
  xxl: $spacing-xxl,
  xxxl: $spacing-xxxl
);
