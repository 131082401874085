.icon {
  &__list {
    list-style: none;
    padding-left: 0;

    > * + * {
      padding-top: $spacing-m;
    }
  }

  &__list-item {
    align-items: center;
    display: flex;

    &::before {
      content: '';
    }
  }

  &__checkmark {
    align-self: flex-start;
    fill: $digital-blue;
    flex: 0 0 2rem;
    height: 2rem;
    margin-right: $spacing-m;
    width: 2rem;
  }
}