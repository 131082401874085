.callout-block {
  @include media-breakpoint-down(lg) {
    margin-top: $spacing-xl;
  }
}

.callout-side {
  margin-bottom: $spacing-ml;

  &-heading {
    display: inline-block;
  }
}

.callout-side, .callout-block {
  &-heading {
    @extend %subhead-3;
    border-bottom: $callout-accent-bar-thickness solid $callout-accent-bar-color;
    margin-bottom: $spacing-m;
    width: fit-content;
  }
}

.callout p:last-child {
  margin-bottom: 0;
}
