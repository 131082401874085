@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// loading-spinner has been deprecated and will be removed entirely in a future release.
.loading-spinner {
  @include box($loading-spinner-size);

  animation: spin $loading-spinner-duration infinite linear;
  border: $spacing-xs solid $loading-spinner-color;
  border-left-color: $loading-spinner-bg-color;
  border-radius: 50%;
}

.spinner-border.loading {
  @include box($loading-spinner-size);

  color: $loading-spinner-color;
}
