.pagination {
  align-items: center;
  display: inline-flex;
  list-style: none;
  padding-left: 0;

  &__container {
    @include make-container();
    @include make-container-max-widths();

    text-align: center;
  }

  &__item {
    margin-right: $spacing-m;

    &::before {
      content: ''
    }

    &:last-child {
      margin-right: 0;
    }

    svg {
      fill: $digital-blue;
      height: 16px;
      pointer-events: none;
      width: 16px;
    }
  }
}
