
.crumbs {
  align-items: center;
  margin: 0;
  padding: $spacing-m 0;
  position: relative;
  top: 0;
  z-index: 1;

  .crumb-nav {
    align-items: center;
    display: inline-flex;

    .crumb-link {
      background-image: none;
      padding: 0;
      white-space: nowrap;
    }

    svg {
      padding: 0 $spacing-xs;
    }

    &:last-child {
      cursor: default;
      white-space: normal;
    }
  }
}
