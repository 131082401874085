.product__plans {
  @include make-container();
  @include make-container-max-widths();
  box-shadow: $shadow-default;
  margin-bottom: $spacing-xxl;

  &--top {
    padding: $spacing-ml;
  }

  &--bottom {
    background-color: $digital-blue-10;
    padding: $spacing-ml;
  }

  &--card {
    background-color: color('white');
    border-radius: 8px;
    box-shadow: $shadow-default;
    margin-bottom: $spacing-ml;
    padding: $spacing-ml;
  }

  &--subheading {
    color: color('gray-60');
    text-transform: uppercase;
  }

  &--icons {
    display: flex;
    flex-wrap: wrap;

    span {
      padding-right: $spacing-l;
      text-align: center;

      @include media-breakpoint-up(sm) {
        padding-right: $spacing-l;
      }
    }

    span:first-child {
      padding-left: 0;
    }

    svg {
      @include media-breakpoint-down(xs) {
        height: 2rem !important;
        width: 2rem !important;
      }
    }
  }

  &--buttons {
    @include media-breakpoint-up(md) {
      display: flex;
    }

    a, button {
      @include media-breakpoint-up(md) {
        @include make-col(3);
      }
    }

    a {
      align-items: center;
      display: flex;
      justify-content: center;
      text-align: center;
    }

    a:not(:last-child),
    button:not(:last-child) {
      margin-bottom: $spacing-l;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
        margin-right: $spacing-m;
      }
    }
  }
}

.plan-cards {
  &__body {
    padding: $spacing-xl $spacing-ml;

    &--top {
      p {
        margin-bottom: 0;
      }
    }
  }

  &__collapse-body {
    height: 0;
  }

  [data-toggle="collapse-custom"] {
    padding: $spacing-m 0;
  }

  .btn-link {
    background-image: none;

    &:focus {
      background-color: transparent;
    }
  }
}
