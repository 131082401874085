//
// Accordions
//

.accordion-title,
.accordion-title-single,
.expand-control-text {
  text-align: left;
}

.accordion-title,
.accordion-title-single {
  align-items: center;
  width: 100%;
}

.accordion-content {
  p:last-child {
    margin-bottom: 0;
  }
}

//
// Light Accordions
//

.light-accordion-holder {
  border-bottom: $hr-border-width solid $hr-border-color;
  margin-bottom: $spacing-xl;

  &--side {
    border-bottom: 0;
    margin-bottom: 0;

    svg {
      height: $spacing-ml;
      width: $spacing-ml;
    }
  }

  .accordion-title,
  .accordion-title-single {
    @include accordion-title-type(transparent, 0, $digital-blue, 0, $spacing-m 0);
    border: 0;
    border-top: $hr-border-width solid $hr-border-color;
    display: flex;

    .panel-header {
      @extend %subhead-4;
      color: $digital-blue;
      display: inline-block;
      margin-bottom: 0;
      margin-top: 0;
    }

    &.active {
      color: $cigna-blue-dark;
    }
  }

  .accordion-content {
    padding: $spacing-m 0 $spacing-l $spacing-ml;
  }

  .individual-accordion {
    button:focus {
      outline: none;
    }
  }
}

//
// Inline Expand
//

.individual-inline-accordion {
  margin-bottom: $spacing-xl;

  .leadin-inline > :last-child {
    margin-bottom: 0;
  }

  .accordion-content {
    margin: 0 0 $spacing-l $spacing-ml;
  }
}

.accordion-control-inline {
  align-items: center;
  display: flex;
  padding: $spacing-m 0;

  .control-arrow {
    cursor: pointer;
    fill: $digital-blue;
  }
}


//
// Arrow Classes
//

.control-arrow {
  flex-shrink: 0;
  height: 1rem;
  margin-right: $spacing-xs;
  width: 1rem;

  &.down,
  &.right,
  &.up {
    transition: 0.2s transform ease-in-out;
  }

  &.right {
    transform: rotate(0deg);
  }

  &.down {
    transform: rotate(90deg);
  }

  &.up {
    transform: rotate(-90deg);
  }

  .light-accordion-holder & {
    fill: $digital-blue;
  }
}

.accordion-block {
  .light-accordion-holder {
    margin-bottom: 0;
  }
}

.accordion-controls {
  padding-bottom: $spacing-m;

  .expand-all,
  .collapse-all {
    margin-right: $spacing-m;
  }
}

.accordion-fade {
  .accordion-control-inline {
    align-items: center;
    display: flex;
    padding: $spacing-m 0;
  }

  .back-to-top {
    background-image: none;
    display: block;
    float: right;
    font-family: $headings-font-family;
    font-weight: $font-weight-normal;
    margin-bottom: $spacing-m;

    @include media-breakpoint-up(md) {
      display: inline-block;
      margin-bottom: 0;
    }
  }

  &__body {
    margin-bottom: $spacing-l;
  }

  &__heading {
    display: inline-block;
    margin-bottom: $spacing-ml;
  }

  button {
    background-image: none;
  }
}

.collapse-custom {
  height: 70px;
  overflow: hidden;
  position: relative;
  transition: max-height 0.2s ease-in-out;

  &--fade {
    &::after {
      background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%);
      bottom: 0;
      content: "";
      display: block;
      height: 20px;
      position: absolute;
      width: 100%;
    }
  }

  &.show {
    overflow: auto;
  }
}

svg {
  &.angle-down {
    transform: rotate(0deg);
  }

  &.angle-up {
    transform: rotate(-180deg);
  }
}
