//
// Global typography styling
//

.display-desktop,
.display-mobile,
.subhead-1,
.subhead-2,
.subhead-3,
.subhead-4,
h1,
h2,
h3,
h4 {
  @include heading-font;
}

h1,
h2,
h3,
h4 {
  > a {
    @include link-variant($underline-color: transparent, $visited: $digital-blue);
  }
}

// Custom/Smart
%display,
.display,
%display-desktop,
%display-mobile,
.display-desktop,
.display-mobile {
  color: $digital-green;
  font-size: 2.25rem;
  letter-spacing: -0.25px;
  line-height: 1.25;
}

%display-desktop,
.display-desktop {
  font-size: 2.25rem;
}

%display-mobile,
.display-mobile {
  font-size: 2.25rem;
}

%subhead-1,
.subhead-1,
h1,
.h1 {
  font-size: 2.25rem;
  line-height: 1.25;
}

%subhead-2,
.subhead-2,
h2,
.h2 {
  font-size: 1.75rem;
  line-height: 1.242857142857143;
}

%subhead-3,
.subhead-3,
h3,
.h3 {
  font-size: 1.375rem;
  line-height: 1.272727272727273;
}

%subhead-4,
.subhead-4,
h4,
.h4 {
  font-size: 1.125rem;
  line-height: 1.333333333333333;
}

%subhead-5,
.subhead-5,
%body-text,
.body-text,
h5,
.h5 {
  @include body-text;
}

%subhead-5,
.subhead-5,
h5,
.h5 {
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-bold;
}

%lead-paragraph,
.lead-paragraph {
  @include lead-paragraph;
}

%small-text,
.small-text,
small,
.small {
  @include small-text;
}

%micro-text,
.micro-text,
.footnotes {
  @include micro-text;

  @include media-breakpoint-down(sm) {
    a {
      word-wrap: break-word;
    }
  }
}


//
// Accents
//

%accent-small,
%accent-medium,
%accent-large,
%accent-xlarge,
.accent-small,
.accent-medium,
.accent-large,
.accent-xlarge {
  color: $digital-black;
  font-family: $accents-font-family;
  font-style: italic;
  font-weight: $accents-font-weight;
}

%accent-small,
.accent-small {
  color: $gray-60;
  font-size: 0.875rem;
  line-height: 1.242857142857143;
}

%accent-medium,
.accent-medium {
  font-size: $font-size-base;
  line-height: $line-height-base;
}

%accent-large,
.accent-large {
  font-size: 1.25rem;
  line-height: 1.6;
}

%accent-xlarge,
.accent-xlarge {
  font-size: 1.75rem;
  line-height: 1.428571428571429;
}


//
// Home page specific
//

.homepage-h1 {
  color: $digital-black;
  font-size: 2rem;
  margin-bottom: $spacing-l;

  @include media-breakpoint-down('sm') {
    font-size: 1.75rem;
  }
}

.homepage-h2 {
  color: $digital-green;
  font-size: 2.25rem;

  @include media-breakpoint-down('sm') {
    font-size: 2rem;
  }
}


//
// Links
//

a,
a:not([href])[data-toggle='modal'] {
  color: $link-color;
  cursor: pointer;

  &:focus {
    background-color: $cigna-orange-light-20;
  }
}

a:not([href])[data-toggle='modal'] {
  &:active,
  &:hover {
    background-image: linear-gradient(rgba($cigna-blue-dark, 0.3), rgba($cigna-blue-dark, 0.3));
    color: $link-hover-color;
    transition: color 0.2s;
  }
}

a {
  @extend %link-underline;

  font-weight: $font-weight-semibold;

  &:visited {
    color: $digital-purple;
  }

  &:hover {
    background-image: linear-gradient(rgba($cigna-blue-dark, 0.3), rgba($cigna-blue-dark, 0.3));
    color: $link-hover-color;
    transition: color 0.2s;
  }
}

.link-action,
.link-external,
.link-secure {
  .icon {
    @include box(1.125rem);
    fill: currentColor;
    margin-bottom: $spacing-xs;
    margin-left: $spacing-xs;

    &.action {
      margin-left: $spacing-s;
    }

    small &,
    .small-text &,
    .small & {
      @include box(1rem);
    }
  }
}

.link-action {
  background-image: none;
  background-position: inherit;
  background-repeat: inherit;
  background-size: inherit;
  color: $digital-blue;

  .icon.action {
    small &,
    .small-text &,
    .small & {
      margin-left: 6px;
    }
  }

  &:active,
  &:hover {
    background-image: linear-gradient(rgba($cigna-blue-dark, 0.3), rgba($cigna-blue-dark, 0.3));
    background-position: 0% 88%;
    background-repeat: repeat-x;
    background-size: 1px 1px;
    color: $link-hover-color;
    padding-bottom: 4px;
    transition: color 0.2s;
  }
}

.strong {
  font-weight: $font-weight-bold;
}

.break-word {
  word-break: break-word;
}

//
// Address
//

address {
  margin-bottom: $spacing-ml;
}
