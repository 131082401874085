//
// Block
//

.quotation.blockquote {
  border-left: $quotation-accent-bar-thickness solid;
  margin: $spacing-l 0 $spacing-xl;
  padding-left: $spacing-l;

  &--border-orange {
    border-left-color: $quotation-accent-bar-color;

    @include media-breakpoint-up(md) {
      margin: $spacing-l 100px;
    }
  }

  &--border-blue {
    border-left-color: $digital-blue;

    @include media-breakpoint-up(md) {
      margin: $spacing-l 100px;
    }
  }

  .quote-text {
    @extend %accent-xlarge;
  }
}

//
// Side
//

.quotation.side {
  margin-bottom: $spacing-ml;

  .quote-text {
    @extend %accent-large;

    &::after {
      @include box($quotation-accent-bar-width, $quotation-accent-bar-thickness);
      background-color: $quotation-accent-bar-color;
      content: ' ';
      display: block;
      margin-top: $spacing-m;
    }
  }
}

//
// Common
//

.quotation {
  .quote-text {
    margin-bottom: $spacing-m;

    p:last-child {
      margin-bottom: 0;
    }
  }

  .quote-attribution {
    color: $quotation-attribution-text-color;
  }
}
