// Normal
@include font-face('Open Sans', $open-sans-font-path + 'open-sans-v15-latin-regular', 400, normal, woff2 woff);
@include font-face('Open Sans', $open-sans-font-path + 'open-sans-v15-latin-italic', 400, italic, woff2 woff);

// SemiBold - mentioned in the styleguide but not actually used
@include font-face('Open Sans', $open-sans-font-path + 'open-sans-v15-latin-600', 600, normal, woff2 woff);
@include font-face('Open Sans', $open-sans-font-path + 'open-sans-v15-latin-600italic', 600, italic, woff2 woff);

// Bold
@include font-face('Open Sans', $open-sans-font-path + 'open-sans-v15-latin-700', 700, normal, woff2 woff);
@include font-face('Open Sans', $open-sans-font-path + 'open-sans-v15-latin-700italic', 700, italic, woff2 woff);
