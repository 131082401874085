//
// Generate utility classes for rounded edges.
//
// Rounded corners on all sides with specific size:
//   small(s), medium(m), large(l): .rounded-s, .rounded-m, .rounded-l
//
// Rounded corners on specific side and size:
//   .rounded-top-m, .rounded-right-m, .rounded-bottom-m, .rounded-left-m
//

@each $size, $value in $border-radiuses {
  .rounded {
    &-#{$size} {
      border-radius: $value !important;
    }

    &-top-#{$size} {
      border-top-left-radius: $value !important;
      border-top-right-radius: $value !important;
    }

    &-right-#{$size} {
      border-bottom-right-radius: $value !important;
      border-top-right-radius: $value !important;
    }

    &-bottom-#{$size} {
      border-bottom-left-radius: $value !important;
      border-bottom-right-radius: $value !important;
    }

    &-left-#{$size} {
      border-bottom-left-radius: $value !important;
      border-top-left-radius: $value !important;
    }
  }
}
