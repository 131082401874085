.quicklinks-block {
  margin-bottom: $spacing-l;

  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }

  .content {
    background: $digital-blue-10;
    border-radius: $spacing-s;
    box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.3);
    color: $digital-black;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    max-width: 100%;
    padding: $spacing-ml $spacing-m;
    width: 100%;

    header, p {
      margin-bottom: $spacing-s;
    }
  }
}
