.hero {
  position: relative;
  z-index: 1;

  p {
    margin-bottom: 0.75rem;
  }

  .lead-paragraph {
    @include body-text;

    line-height: 2rem;
    text-align: left;

    @include media-breakpoint-up(lg) {
      @include lead-paragraph;
    }
  }

  h1 {
    color: $digital-black;
    margin-bottom: $spacing-m;
    word-break: break-word;
  }

  h2 {
    margin-bottom: $spacing-m;
    word-break: break-word;
  }

  h1, h2 {
    @include media-breakpoint-down(md) {
      font-size: 32px;
    }
  }

  .crumbs {
    padding: $spacing-ml 0 0 0;
  }
}

.hero-basic {
  padding-bottom: $spacing-xl;

  p.subhead-3 {
    @include lead-paragraph;
  }
}

.hero-padding {
  padding-top: $spacing-l;

  @include media-breakpoint-up(sm) {
    padding-top: $spacing-xl;
  }
}

.hero-gradient {
  @include media-breakpoint-up(md) {
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 0%, rgb(230, 241, 248) 100%, rgb(230, 241, 248) 100%);
  }
}

.hero-gradient-all-sizes {
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 0%, rgb(230, 241, 248) 100%, rgb(230, 241, 248) 100%);
}

.hero-long-intro {
  margin-bottom: $spacing-xl;
  min-height: 384px;

  @include media-breakpoint-up(md) {
    padding-bottom: $spacing-xl;
  }

  .lead-paragraph {
    margin-bottom: 0;
    padding: 0 16px;
  }

  .long-intro-image {
    margin-top: $spacing-ml;

    @include media-breakpoint-up(sm) {
      margin-top: $spacing-l;
    }

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }

    .hero-image {
      border: 2px solid rgba(217, 217, 217, 1);
    }
  }
}

.hero-floating-photo,
.hero-floating-photo-cta {
  margin-bottom: $spacing-xl;
  padding-bottom: $spacing-xl;

  @include media-breakpoint-up(lg) {
    margin-bottom: $spacing-xxxl;
  }

  .lead-paragraph {
    margin-bottom: $spacing-ml;
  }

  .hero-cta {
    margin-top: 0;
  }

  .link-action {
    margin-bottom: 0;
  }

  &.hero-gradient {
    @include media-breakpoint-down(sm) {
      background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 0%, rgb(230, 241, 248) 100%, rgb(230, 241, 248) 100%);
      background-repeat: no-repeat;
    }
  }
}

.hero-floating-photo-row {
  @include make-row;

  @include media-breakpoint-up(md) {
    @include clearfix;
    display: block;
  }
}

.hero-floating-photo-image-wrapper {
  @include make-col-ready;
  margin-top: $spacing-l;
  order: 2;

  @include media-breakpoint-up(md) {
    @include make-col(5);
    float: right;
    margin-top: 0;
    order: 1;
  }

  @include media-breakpoint-up(lg) {
    bottom: -40px;
    position: absolute;
    right: 0;
  }
}

.hero-floating-photo-image {
  @include img-fluid;
  border: 2px solid rgba(217, 217, 217, 1);
  border-radius: $radius-l;
  box-shadow: 0 20px 36px -23px rgba(0, 0, 0, 0.65);
}

.hero-floating-photo-optional-content,
.hero-floating-photo-main-content {
  @include make-col-ready;

  @include media-breakpoint-up(md) {
    @include make-col(7);
    float: left;
  }
}

.hero-floating-photo-meta-copy,
.hero-floating-photo-legal-copy {
  @extend %accent-small;
  @include small-text;
}

.hero-floating-photo-content-lead-in {
  @include media-breakpoint-up(xl) {
    width: percentage($number: 10/12);
  }
}

.hero-floating-photo-cta-support-copy {
  display: block;
  font-weight: $font-weight-bold;
  margin-top: $spacing-m;

  @include media-breakpoint-up(sm) {
    display: inline-block;
    margin-left: $spacing-s;
  }
}


.hero-full-width-photo {
  .lead-paragraph {
    @include media-breakpoint-up(md) {
      margin-bottom: $spacing-m;
    }

    @include media-breakpoint-only(md) {
      font-size: $font-size-base;
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: $spacing-ml;
    }
  }

  .full-width-container {
    @include media-breakpoint-up(lg) {
      position: absolute;
    }
  }

  .full-width-row {
    margin: 0 auto;
    width: 100%;
  }

  .hero-full-w-content {
    max-width: 100%;

    @include media-breakpoint-up(md) {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.hero-bg-holder {
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 0%, rgba(239, 239, 239, 1) 100%, rgba(239, 239, 239, 1) 100%);
  display: flex;
  justify-content: center;
  padding: 0;
}

.hero-cta {
  @include media-breakpoint-up(sm) {
    margin-top: $spacing-m;
  }

  button, .call-link {
    display: block;
    line-height: 16px;
    margin-top: $spacing-m;

    @include media-breakpoint-up(sm) {
      display: inline-block;
      margin-top: 0;
    }
  }

  .call-link {
    @include media-breakpoint-up(sm) {
      margin-left: $spacing-s;
    }
  }
}

.call-us-cta {
  margin-top: $spacing-m;

  @include media-breakpoint-up(md) {
    margin-top: $spacing-s;
  }

  a {
    background: none;
    color: $digital-black;
    font-weight: 700;
    text-decoration: none;
  }
}

.hero-white-left-box {
  @include make-container;
  @include make-container-max-widths($hero-white-left-box-max-widths);
  background: $white;
  display: flex;
  flex-direction: column;
  margin-top: $spacing-ml;

  @include media-breakpoint-up(md) {
    border: 2px solid rgba(217, 217, 217, 1);
    border-radius: $radius-l;
    box-shadow: 0 20px 36px -23px rgba(0, 0, 0, 0.65);
    margin-top: 0;
    min-height: 248px;
    padding: $spacing-ml;
    position: absolute;
    top: $spacing-l;
    z-index: 2;
  }

  @include media-breakpoint-up(lg) {
    min-height: 284px;
    padding: $spacing-l $spacing-ml $spacing-l $spacing-l;
  }

  @include media-breakpoint-up(xl) {
    min-height: 328px;
    top: $spacing-xl;
  }

  .hero-header {
    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }

  .full-width-border {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}

.hero-actions {
  align-items: flex-start;
  display: flex;
  flex-flow: column wrap;
  margin-bottom: 0;
  width: 100%;

  @include media-breakpoint-down(sm) {
    margin-bottom: $spacing-xl;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  > button,
  > a {
    margin-bottom: $spacing-m;
    margin-right: $spacing-m;

    &:only-child {
      margin-bottom: 0;
      margin-right: 0;
    }
  }

  > :last-child {
    margin-bottom: 0;
  }
}

.hero-background-image {
  height: 144px;
  max-width: 576px;
  width: 100%;

  @include media-breakpoint-up(sm) {
    height: 192px;
    max-width: 768px;
  }

  @include media-breakpoint-up(md) {
    height: calc(212px + 2rem);
    max-width: 992px;
  }

  @include media-breakpoint-up(lg) {
    height: calc(272px + 2rem);
    max-width: 1200px;
  }

  @include media-breakpoint-up(xl) {
    height: calc(312px + 3rem);
    margin: 0 auto;
    max-width: 1440px;
  }
}

.hero-bg {
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 1440px;
  width: 100%;
}

.hero-image {
  border-radius: $radius-l;
  box-shadow: 0 20px 36px -23px rgba(0, 0, 0, 0.65);
  width: 100%;
}

.hero-plain {
  margin-bottom: $spacing-l;
  padding-bottom: $spacing-xl;

  @include media-breakpoint-up(sm) {
    margin-bottom: $spacing-xl;
  }

  p.subhead-3 {
    @include body-text;
    @include media-breakpoint-up(lg) {
      @include lead-paragraph;
    }
  }
}

.hero-tel,
.tel-hidden {
  background: none;
  color: $digital-blue;
  text-decoration: none;

  @include media-breakpoint-up(md) {
    color: $digital-black;
    cursor: default;
    pointer-events: none;
  }
}

.hero-icon {
  margin-bottom: $spacing-xl;
  padding-bottom: $spacing-xl;

  .hero-icon-sub-content {
    display: flex;
  }

  .hero-icn {
    display: none;

    @include media-breakpoint-up(md) {
      display: inline-flex;
      margin: 0 $spacing-xl 0 0;
      min-width: 144px;
    }
  }

  .block-icn {
    display: none;

    @include media-breakpoint-up(sm) {
      display: inline-flex;
      margin: 0 $spacing-l 0 0;
      min-width: 88px;
    }

    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}
