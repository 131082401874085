.bio-block-container {
  background: $gray-01;
  border-radius: $radius-l;
  padding: $spacing-l $spacing-ml;

  @include media-breakpoint-up(sm) {
    padding: $spacing-l 0;
  }

  @include media-breakpoint-up(md) {
    padding: $spacing-xxl 0;
  }

  @include media-breakpoint-up(lg) {
    padding: $spacing-xxxl 0;
  }

  .bio-block-image {
    margin-bottom: $spacing-ml;

    @include media-breakpoint-up(md) {
      display: table-cell;
      width: 128px;
    }

    img {
      border-radius: $radius-m;
      height: 128px;
      width: 128px;
    }
  }

  .bio-block-content {
    @include media-breakpoint-up(md) {
      display: table-cell;
      padding-left: $spacing-l;
      vertical-align: middle;
    }

    @include media-breakpoint-up(lg) {
      padding-left: $spacing-xxl;
    }
  }
}

.headshot-img {
  height: 92px;
  margin: 0 25px 0 0;
  width: 92px;

  @include media-breakpoint-up(sm) {
    margin: 9px 25px 9px 8px;
  }
}

.content-block-section {
  > :last-child {
    margin-bottom: $spacing-ml;
  }

  + .spacer {
    @include media-breakpoint-down(sm) {
      margin-top: $spacing-l + $spacing-s;
    }
  }
}

.five-six-content-block {
  .spacer {
    border-top: $hr-border-width solid $hr-border-color;
    margin: 0 auto $spacing-xl;

    @include media-breakpoint-down(lg) {
      max-width: calc(100% - 30px);
    }

    @include media-breakpoint-up(lg) {
      border-right: $hr-border-width solid $hr-border-color;
      border-top: 0;
      // Keeps the divider in the middle with even space between each column
      // One column = 8.33% so I cut it in half and added the other half as margin
      margin: 0 percentage(1/24) $spacing-xxl 0;
      max-width: percentage(1/24);
      padding: 0;
    }
  }
}

.content-block-section-image {
  z-index: 100;
}

.content-block-section-image-shadow {
  background-color: transparent;
  bottom: 0;
  box-shadow: 0 0.75rem 2.5rem 0 rgba(0, 0, 0, 0.3);
  height: 4rem;
  left: $spacing-l;
  opacity: 0;
  right: $spacing-l;
  z-index: 0;
}

.sticky-retrieve-quote {
  background-color: $digital-green-10;
  padding: $spacing-m 0;
  width: 100%;
  &__container {
    @include make-container();
    @include make-container-max-widths();
  }

  &__body {
    align-items: center;
    display: flex;
    justify-content: space-around;
  }
}

.card {
  &--blue-10 {
    background-color: $digital-blue-10;
  }
  &--border-top-blue {
    border-top: $spacing-s solid $digital-blue;
  }
  &--radius-s {
    border-radius: $spacing-s;
  }
  &--shadow {
    box-shadow: $shadow-low-30;
  }
}

.block-selection {
  &__body {
    background-color: $digital-blue-10;
    border-radius: 12px;
    margin-bottom: $spacing-xxl;
    padding: $spacing-ml;

    @include media-breakpoint-up(lg) {
      margin-bottom: $spacing-xxxl;
    }
  }
  &__content {
    display: none;
    margin-bottom: $spacing-xxl;
  }
  &__item {
    background-color: $white;
    background-image: none;
    border-radius: 8px;
    box-shadow: $shadow-default;
    color: $digital-black;
    display: inline-block;
    font-family: $headings-font-family;
    font-weight: normal;
    margin-bottom: $spacing-l;
    min-height: 190px;
    padding: $spacing-m;
    text-align: center;
    transition: all .2s ease-in-out;
    width: 100%;
    word-break: break-word;

    &:focus {
      background-color: $white;
    }

    &:hover {
      background-image: none;
      box-shadow: $box-shadow-length-high $shadow-40;
      margin-top: -10px;
      min-height: 200px;
      padding-top: calc(1rem + 10px);
      transition: all .2s ease-in-out;
    }

    &--active {
      border-bottom: 8px solid $cigna-orange-logo;
      box-shadow: $box-shadow-length-high $shadow-40;
      margin-top: -10px;
      min-height: 200px;
      padding-top: calc(1rem + 10px);
    }
  }
  &__icons {
    margin-bottom: $spacing-s;
    svg {
      margin: 0 -10px;
    }
  }

  .checkbox-holder {
    margin-bottom: 0;
  }
}