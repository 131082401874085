//
// Icon Block 2X
//

.icon-block-container {
  .icon-block-2 {
    margin-bottom: $spacing-xl;
  }

  .icon-block-img-2 {
    margin-bottom: $spacing-m;

    @include media-breakpoint-up(sm) {
      display: table-cell;
      margin-bottom: 0;
      vertical-align: top;
    }
  }

  .icon-block-content-2 {
    @include media-breakpoint-up(sm) {
      display: table-cell;
      padding-left: $spacing-l;
      vertical-align: middle;
    }

    header {
      margin-bottom: $spacing-m;
    }
  }
}

//
// Icon Block 3X
//

.icon-block-container {
  .icon-block-3 {
    margin-bottom: $spacing-l;

    @include media-breakpoint-up(md) {
      margin-bottom: $spacing-m;
    }
  }

  .icon-block-img-3 {
    margin-bottom: $spacing-m;

    @include media-breakpoint-up(sm) {
      display: table-cell;
    }

    @include media-breakpoint-up(md) {
      display: block;
      margin-bottom: $spacing-m;
    }
  }

  .icon-block-content-3 {

    @include media-breakpoint-up(sm) {
      display: table-cell;
      padding-left: $spacing-ml;
      vertical-align: middle;
    }

    @include media-breakpoint-up(md) {
      display: block;
      padding-left: 0;
      padding-right: $spacing-ml;
    }

    header {
      margin-bottom: $spacing-s;
    }
  }
}

//
// Icon Block 4X
//

.icon-block-container {
  .icon-block-4 {
    margin-bottom: $spacing-xl;
    margin-right: 8.33%;

    @include media-breakpoint-up(md) {
      margin-bottom: $spacing-l;
    }

    @include media-breakpoint-up(lg) {
      margin-right: 0;
    }
  }

  .icon-block-img-4 {
    margin-bottom: $spacing-m;

    @include media-breakpoint-up(md) {
      display: block;
      margin-bottom: $spacing-m;
    }
  }

  .icon-block-content-4 {

    @include media-breakpoint-up(sm) {
      vertical-align: middle;
    }

    @include media-breakpoint-up(md) {
      display: block;
      padding-left: 0;
    }

    header {
      margin-bottom: $spacing-s;
    }
  }
}

//
// Global
//

.icn.icn-b.block-icn {
  margin: 0;
}
