//
// Alert Messages
//

.alert {
  border: 0; // Overriding default Bootstrap
  border-radius: $radius-l;
  color: $digital-black;
  margin-bottom: $spacing-xl;
  padding: 0; // Overriding default Bootstrap

  .a-message {
    display: inline-block;
    padding: $spacing-m $spacing-ml $spacing-ml $spacing-ml;

    .a-header {
      margin-bottom: $spacing-s;
    }

    p:last-child {
      margin-bottom: 0;
    }

    /* stylelint-disable */
    @include media-breakpoint-up(sm) {
      display: table;
      padding: $spacing-ml $spacing-xl $spacing-ml $spacing-ml;

      .a-header {
        margin-bottom: 0;
      }
    }
    /* stylelint-enable */
  }

  .alert-icon {
    display: inline-block;
    float: left;
    height: 44px;
    margin-left: $spacing-ml;
    margin-top: $spacing-ml;
    width: 44px;

    @include media-breakpoint-up(sm) {
      display: table;
    }
  }

  .close {
    color: $digital-black;
    cursor: pointer;
    height: 44px;
    opacity: 1; // Overriding default Bootstrap
    padding: 9px;
    text-align: center;
    width: 44px;
  }
}

.alert-warning {
  background-color: $cigna-orange-light-20;

  .alert-icon {
    fill: $cigna-orange-dark;
  }
}

.alert-info {
  background-color: $digital-blue-10;

  .alert-icon {
    fill: $cigna-blue-dark;
  }
}

.alert-success {
  background-color: $digital-green-10;

  .alert-icon {
    fill: $digital-green;
  }
}

.alert-danger {
  background-color: $digital-red-10;

  .alert-icon {
    fill: $digital-red;
  }
}

//
// Alert Banner for Homepage
//

.alert-banner {
  background-color: $digital-blue-10;
  margin-bottom: 0;
  padding: $spacing-ml $spacing-m;

  @include media-breakpoint-up(md) {
    padding-left: 0;
    padding-right: 0;
  }
}

.alert-banner-body {
  font-size: 0.875rem;
  width: 100%;

  @include media-breakpoint-up(sm) {
    font-size: $font-size-base;
  }

  @include media-breakpoint-up(md) {
    text-align: left;
  }

  // Added to accomodate TinyMCE, multiple <p> elements, etc.
  > :last-child {
    margin-bottom: 0;
  }
}
