//
// Dropdown menus
//

.dropdown-menu {
  display: none;

  &.show {
    display: block;
  }
}

//
// Showing/hiding done in _dropdowns.scss
//

.dropmenu {
  background-color: $white;
  border: $dropmenu-border-width solid $dropmenu-border-color;
  border-radius: $dropmenu-border-radius;
  box-shadow: $dropmenu-box-shadow;
  margin: $spacing-s 0;
  max-width: 375px;
  padding: $spacing-xs 0 0;
  z-index: $zindex-dropdown;

  &::before {
    background-color: $white;
    border-color: $dropmenu-border-color transparent transparent $dropmenu-border-color;
    border-style: solid;
    border-width: $dropmenu-border-width;
    content: '';
    height: 1rem;
    left: calc(50% - 0.5rem);
    padding: 0;
    position: absolute;
    top: -0.5rem;
    transform: rotate(45deg);
    transform-origin: center;
    width: 1rem;
    z-index: $zindex-dropdown;
  }

  &__item {
    margin: $spacing-m $spacing-ml;

    > * + * {
      margin-top: $spacing-s;
    }
  }

  &__link {
    white-space: nowrap;
  }

  &__description {
    font-size: $small-font-size;
    line-height: (20 / 14);
  }
}
