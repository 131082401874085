//
// Typography
//

@mixin heading-font() {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
}

@mixin micro-text() {
  color: $gray-60;
  font-size: 0.75rem;
  line-height: 1.416;
}

@mixin small-text() {
  font-size: 0.875rem;
  line-height: 1.428571428571429;
}

@mixin lead-paragraph() {
  font-size: 1.125rem;
  line-height: 1.777777777777778;
}

@mixin body-text() {
  font-size: $font-size-base;
  line-height: $line-height-base;
}


// 
// Callout accent header
// 

@mixin accent-bar($color: $cigna-orange-logo) {
  border-bottom: $spacing-xs solid $color;
  width: fit-content;
}
