//
// Global image teaser styling
//

.image-teaser {
  margin-bottom: $spacing-l;

  &__body {
    margin-bottom: $spacing-m;
  }

  &__heading-right-link {
    background-image: none;
    display: inline-flex;
    float: right;
    font-weight: $font-weight-semibold;
    margin-bottom: $spacing-m;

    @include media-breakpoint-only(md) {
      margin-bottom: 0;
    }

    &:visited {
      color: $digital-blue;
    }
  }
}

.teaser-img {
  margin-bottom: $spacing-m;

  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }

  img {
    border-radius: $radius-m;
  }
}

.teaser-meta {
  font-family: $headings-font-family;
  font-size: $font-size-sm;
  font-style: italic;
  font-weight: 500;
  margin-bottom: $spacing-m;

  &__date {
    color: $gray-40;
  }
}

.teaser-title {
  margin-bottom: $spacing-s;

  > a,
  > a:not([href])[data-toggle='modal'] {
    @include link-variant($underline-color: transparent, $visited: $digital-blue);
  }
}

.teaser-content {
  font-family: $headings-font-family;
}

.teaser-content > :last-child {
  margin-bottom: 0;
}

//
// Image teaser variations (small, medium, large)
//

// Small image teasers
.image-teaser-small {
  min-height: 100px;

  @include media-breakpoint-up(sm) {
    margin-bottom: 0;
  }

  .teaser-img {
    @include media-breakpoint-up(sm) {
      flex: 0 0 120px;
      margin-bottom: 0;
      max-width: 120px;
    }
  }

  .teaser-content {
    @include media-breakpoint-up(sm) {
      // IE11 Bug - flex shorthand with calc
      // https://github.com/philipwalton/flexbugs#flexbug-8
      flex-basis: calc(100% - 120px);
      flex-grow: 0;
      flex-shrink: 0;
      max-width: calc(100% - 120px);
      padding-left: $spacing-ml;
    }

    .teaser-title {
      @include media-breakpoint-up(sm) {
        margin-bottom: 0;
      }
    }
  }
}

.image-teaser-medium,
.image-teaser-large {
  &:last-child {
    margin-bottom: $spacing-xl;
  }
}

// Medium image teasers
.image-teaser-medium {
  // Fake 2 column, this will be in a 7 col block
  @include media-breakpoint-up(md) {
    .teaser-img {
      flex: 0 0 200px;
      max-width: 200px;
    }
  }
  @include media-breakpoint-up(lg) {
    /*
    .teaser-img {
      flex: 0 0 percentage(2/7);
      max-width: percentage(2/7);
    }
    */

    .teaser-content {
      flex: 0 0 percentage(5/7);
      max-width: percentage(5/7);
    }
  }
}

// Large image teasers
.image-teaser-large {
  &-2x {
    @include media-breakpoint-only(md) {
      margin-bottom: $spacing-xl;
    }

    .teaser-img {
      @include media-breakpoint-only(md) {
        margin-bottom: $spacing-m;
      }
    }
  }

  &-3x {
    @include media-breakpoint-up(lg) {
      margin-bottom: $spacing-xl;
    }

    .teaser-img {
      @include media-breakpoint-up(lg) {
        margin-bottom: $spacing-m;
      }
    }
  }
}

.image-teaser-vertical {
  .teaser-img {
    margin-bottom: $spacing-l;
  }
}