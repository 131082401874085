// Generate utility classes for colors in $colors map in variables partial.

@each $name, $color in $cigna-colors {
  .bg-#{$name} {
    background-color: $color !important;
  }

  .fill-#{$name} {
    fill: $color !important;
  }

  .text-#{$name} {
    color: $color !important;
  }
}
