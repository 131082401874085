.currency-switcher {
  display: none;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);

  &__toggle {
    background-color: $white;
    border: 1px solid $digital-blue-10;
    display: block;
    padding: $spacing-m $spacing-m;
    text-align: center;
    width: 100%;

    &:hover {
      background-color: $digital-blue-10;
    }

    &:focus {
      outline: none;
    }
  }

  &__active {
    background-color: $cigna-blue-dark;
    border: 1px solid $cigna-blue-dark;
    color: color('white');

    &:hover {
      background-color: $cigna-blue-dark;
    }
  }
}
