/* Icons classes */

.icn {
  align-content: center;
  align-items: center;
  display: inline-flex;
}

.icn svg {
  margin: 0 auto;
}

.hero-icn {
  border-radius: ($hero-icn-size / 2);
  height: $hero-icn-size;
  margin: $spacing-ml;
  width: $hero-icn-size;
}

.hero-icn svg {
  height: $hero-icnimg-size;
  width: $hero-icnimg-size;
}

.block-icn {
  border-radius: ($block-icn-size / 2);
  height: $block-icn-size;
  margin: $spacing-m;
  width: $block-icn-size;
}

.block-icn svg {
  height: $block-icnimg-size;
  width: $block-icnimg-size;
}

.teaser-icn {
  height: $teaser-icn-size;
  width: $teaser-icn-size;
}

.teaser-icn svg {
  height: $teaser-icnimg-size;
  width: $teaser-icnimg-size;
}

.xl-icn, .xl-icn svg {
  height: $xl-icn-size;
  width: $xl-icn-size;
}

.ml-icn, .ml-icn svg {
  height: $ml-icn-size;
  width: $ml-icn-size;
}

.m-icn, .m-icn svg {
  height: $m-icn-size;
  width: $m-icn-size;
}

.xs-icn, .xs-icn svg {
  height: $xs-icn-size;
  width: $xs-icn-size;
}

// Logo colors
.white-icn svg { fill: $white; }

// // Orange
// .logoorange-icn svg { fill: $cigna-orange-logo; }
// .darkorange-icn svg { fill: $cigna-orange-dark; }
// .lightorange-icn svg { fill: $cigna-orange-light-20; }

// Blue
.digital-blue-icn svg { fill: $digital-blue; }
// .darkblue-icn svg { fill: $cigna-blue-dark; }
// .lightblue-icn svg { fill: $cigna-blue-light; }

// // Green
// .logogreen-icn svg { fill: $digital-green; }
// .lightgreen-icn svg { fill: $digital-green-10; }
