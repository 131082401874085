.table th,
.table td {
  padding: inherit;
}

.table {
  tr {
    padding: $spacing-m 0 $spacing-m $spacing-s;
  }

  thead {
    background-color: $digital-blue-10;

    th:first-child {
      border-radius: 0.3rem 0 0;
    }

    th:last-child {
      border-radius: 0 0.3rem 0 0;
    }

    th {
      border-top: inherit;
    }
  }

  tbody {
    th {
      background-color: $gray-01;
    }

    /* stylelint-disable-next-line selector-max-compound-selectors */
    td p:last-child {
      margin-bottom: 0;
    }
  }
}

//
// Responsive tables
//

.table-responsive,
.table-responsive-sm,
.table-responsive-md,
.table-responsive-lg,
.table-responsive-xl {
  th {
    border-top-color: $border-color;
  }

  thead {
    background-color: $digital-blue-10;

    th {
      border-top-color: $digital-blue-10;
    }
  }

  tbody {
    th {
      background-color: $gray-01;
    }
  }
}
