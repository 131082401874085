//
// Share Buttons
//
[data-action="share"] {
  position: relative;
}

.share-buttons {
  display: inline-flex;
  flex-direction: row;
  will-change: left, opacity, position, top, transform;
  @include media-breakpoint-between('min', 'mid', $sharebreakpoints) {
    position: relative;
  }
  &--horizontal {
    .share-buttons-heading {
      margin-bottom: 0;
    }
  }
  &--vertical {
    @include media-breakpoint-up('max', $sharebreakpoints) {
      flex-direction: column;
      margin-left: -70px;
      position: absolute;
      top: 0;
    }

    .btn-share {
      @include media-breakpoint-up('max', $sharebreakpoints) {
        margin-right: 0;
      }
    }
  }
}

.share-buttons-fixed {
  position: fixed;
}

.share-buttons-set {
  opacity: 1;
}

.share-buttons-heading {
  @extend %subhead-4;
  font-weight: $headings-font-weight;
  margin-right: $spacing-m;
  @include media-breakpoint-up('max', $sharebreakpoints) {
    @include micro-text;
    font-weight: $font-weight-bold;
    margin-bottom: $spacing-m;
    /*margin-right: 0;*/
  }
}

.btn-share {
  @include button-size(0.625rem, 0.625rem, 14px, 14px, $radius-s);
  margin-right: $spacing-s;

  @include hover {
    background-image: none;
  }

  &:focus {
    .btn-icon {
      fill: $digital-blue;
    }
  }

  &:active {
    .btn-icon {
      fill: $white;
    }
  }

  .btn-icon {
    @include box(1.5em);
  }
}