.ls-canvas {
  padding-top: 54px;
  @include media-breakpoint-up(sm) {
    padding-top: 72px;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 0;
  }
}

// Prevents double scroll-bars when navigation is open in mobile.
.overflow-hidden {
  overflow: hidden;
}

.global-nav {
  background: $white;
  @include media-breakpoint-down(md) {
    box-shadow: $shadow-high-20;
  }
  @include media-breakpoint-only(lg) {
    padding: 0;
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: 0;
  }

  ul {
    margin: 0;

    li {
      padding: 0;
    }
  }

  ul li::before {
    content: none;
  }

  a:not(.header-link) {
    background-image: none;

    &:not(.btn):focus {
      background-color: transparent;
    }
  }

  .nav, .navbar-nav {
    .nav-link {
      font-weight: normal;
    }
  }

  .navbar-nav {
    .nav-link {
      background-image: none;
    }
  }

  .input-group {
    background-color: $gray-01;
    width: 170px;

    @include media-breakpoint-up(lg) {
      width: auto;
    }
  }

  .input-group-search {
    border-radius: $radius-m;
    box-shadow: inset 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
  }

  .input-group-btn {
    @include media-breakpoint-up(lg) {
      padding-right: $spacing-s;
    }
  }

  .input-group-btn:not(:first-child) > .btn:first-child,
  .input-group-btn:not(:first-child) > .btn-group:first-child {
    background-color: transparent;
    padding-left: $spacing-s;
    padding-right: $spacing-xs;
  }

  input[type='search'] {
    background-color: transparent;
    border: 0;
    color: $gray-60;

    @include media-breakpoint-up(lg) {
      padding: $spacing-s $spacing-ml;
    }

    /* Styles added to fix the issue with zoom in on iphone */
    // stylelint-disable-next-line media-feature-name-no-vendor-prefix
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      font-size: 16px;
    }
  }

  .accordion {
    ul {
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      margin-bottom: 0;
    }

    li {
      margin-bottom: $spacing-m;

      &:last-child {
        margin-bottom: $spacing-l;
      }
    }

    a {
      background-image: none;
      font-weight: normal;
      padding-bottom: 0;
    }

    .card-title {
      font-family: $headings-font-family;
      font-weight: $headings-font-weight;
      margin-left: $spacing-s;
    }
  }

  .card-header {
    padding: $spacing-m 0;

    &::before {
      @include box(0.5rem);
      content: '';
      display: block;
      float: left;
    }

    &.second-level {
      border-bottom: 0;

      &::before {
        transform: rotate(90deg) translateY(50%);
        transform-origin: center bottom;
        transition: 0.2s transform ease-in-out;
      }
    }

    &.collapsed {
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);

      &::before {
        transform: rotate(0deg) translateY(50%);
        transform-origin: center bottom;
        transition: 0.2s transform ease-in-out;
      }
    }

    &.collapsed,
    &.second-level {
      &::before {
        @include box(0);
        border-bottom: 0.35rem solid transparent;
        border-left: 0.5rem solid $digital-blue;
        border-top: 0.35rem solid transparent;
        content: ' ';
        display: inline-block;
      }
    }
  }

  .navbar-toggler {
    font-weight: 400;
  }

  .navbar-brand {
    padding-bottom: 0;
    padding-top: 0;

    > svg {
      max-height: 38px;
      max-width: 111px;
      @include media-breakpoint-up(sm) {
        max-height: 56px;
        max-width: 163px;
      }
      @include media-breakpoint-up(lg) {
        max-height: 66px;
        max-width: 192px;
      }
    }
  }

  .navbar-collapse {
    @include media-breakpoint-down(lg) {
      background-image: linear-gradient(-155deg, #fff 3%, #efefef 100%);
      bottom: 0;
      box-shadow: $shadow-high-20;
      padding-bottom: $spacing-l;
      padding-left: $spacing-m;
      padding-right: $spacing-m;
      padding-top: $spacing-m;
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;
      z-index: 2;
    }

    &.collapsing {
      @include media-breakpoint-down(lg) {
        height: auto;
        right: -100%;
        transition: right 0.3s;
        width: 320px;
      }
    }

    &.show {
      @include media-breakpoint-down(lg) {
        overflow-y: scroll;
        right: 0;
        transition: right 0.3s;
        width: 320px;
      }
    }

    .btn-icon {
      margin-right: 0.3rem;
    }
  }

  .card-block a:visited { color: $digital-blue; }
}

// Updates to nesting starts

.universal-navigation {
  @include media-breakpoint-up(lg) {
    border-bottom: 1px solid $gray-05;
    /*margin-bottom: 20px;*/
  }

  .nav-item {
    &:not(:last-child) {
      margin-bottom: $spacing-m;
    }

    .nav-link {
      padding-bottom: 0;
      padding-top: 0;
      @include media-breakpoint-up(lg) {
        align-items: center;
        display: flex;
        padding-bottom: $spacing-xs;
        padding-top: $spacing-xs;
      }

      /* stylelint-disable-next-line selector-max-compound-selectors */
      .nav-link-icon {
        fill: $digital-blue;
        height: $spacing-l;
        margin-right: $spacing-xs;
        pointer-events: none;
        vertical-align: inherit;
        width: $spacing-l;
      }
    }
  }

  .nav-link {
    &:visited {
      color: $digital-blue;
    }
  }
}

.universal-navigation-buttons {
  .nav-item:not(:last-child) {
    padding-right: $spacing-ml;
  }

  .nav-item {
    &:not(:last-child) {
      padding-right: $spacing-ml;
      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }

    .nav-link {
      padding-left: 0;
      padding-right: 0;
      @include hover {
        background-image: linear-gradient($digital-black, $digital-black);
        color: color('digital-black');
      }
    }
  }

  &.list-inline-item:not(:last-child) {
    padding-right: $spacing-s;
  }
}

.utility-buttons {
  > li {
    text-indent: 0 !important;
  }
}

.global-nav {
  .nav-menu {
    @include media-breakpoint-down(lg) {
      background-image: linear-gradient(-155deg, #fff 3%, #efefef 100%);
      bottom: 0;
      box-shadow: $shadow-high-20;
      display: block;
      padding-bottom: $spacing-l;
      padding-left: $spacing-m;
      padding-right: $spacing-m;
      padding-top: $spacing-m;
      position: fixed;
      right: 0;
      top: 0;
      transform: translateX(320px);
      transition: transform 0.2s ease-in-out;
      width: 320px;
      z-index: 2;
    }

    &.open {
      @include media-breakpoint-down(lg) {
        height: auto;
        overflow-y: scroll;
        transform: translateX(0);
        transition: transform 0.2s 0.1s ease-in-out;
      }
    }
  }
}

.navbar-collapse-shade {
  background: rgb(0, 0, 0);
  bottom: 0;
  display: block;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.2s ease-in-out;
  width: 0;

  &.open {
    opacity: 0.1;
    transition: opacity 0.2s 0.1s ease-in-out;
    width: 100vw;
    z-index: 1;
  }
}

.container {
  @include media-breakpoint-only(lg) {
    max-width: none;
    padding-left: $spacing-l;
    padding-right: $spacing-l;
  }
}
