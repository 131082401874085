//
// List styling (<ol> and <ul> mostly)
//

ol,
ul {
  counter-reset: item;
  list-style: none;
  margin-bottom: $spacing-ml;
  padding-left: $spacing-l;

  li {
    position: relative;
  }
}

ul {
  li {
    table {
      margin-top: $spacing-m;
    }
  }

  > li::before {
    content: '•';
    counter-increment: item;
    font-weight: $font-weight-bold;
    left: -1.375rem;
    position: absolute;
    text-align: left;
  }
}

ol > li::before {
  content: counter(item) '.';
  counter-increment: item;
  font-weight: $font-weight-bold;
  left: -$spacing-ml;
  position: absolute;
  text-align: right;
}

// Default Cascade marker structure for ol
ol ol > li::before {
  content: counter(item, lower-alpha) '.';
}

// stylelint-disable-next-line selector-max-compound-selectors
ol ol ol > li::before {
  content: counter(item, lower-roman) '.';
}

// Overide classes for markers
.lower-alpha > li::before {
  content: counter(item, lower-alpha) '.';
}

.upper-alpha > li::before {
  content: counter(item, upper-alpha) '.';
}

.lower-alpha > li::before {
  content: counter(item, lower-alpha) '.';
}

.lower-roman > li::before {
  content: counter(item, lower-roman) '.';
}

.upper-roman > li::before {
  content: counter(item, upper-roman) '.';
}

.decimal > li::before {
  content: counter(item, decimal) '.';
}

.decimal-zero > li::before {
  content: counter(item, decimal-leading-zero) '.';
  left: -$spacing-l;
}

.lower-greek > li::before {
  content: counter(item, lower-greek) '.';
}

.list-no-indent,
.list-style-none,
.list-unstyled {
  list-style: none;
  padding-left: 0;

  // Remove bullets
  > li::before {
    content: '';
    display: none;
  }
}

.list-inline-item::before {
  content: '';
  display: none;
}
